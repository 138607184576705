/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { createCategory } from "../../../services/categoryService";
import { PARAMS_GET_MAX } from "../../../utils/constant";
import { useDebounce } from "../../../utils/helperFuncs";

export const useCreateCategory = ({ refetch, parentID }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paramsCategory, setParamsCategory] = useState(PARAMS_GET_MAX);
  const [valueSearchCategory, setValueSearchCategory] = useState("");

  const debouncedQueryCategory = useDebounce(valueSearchCategory);

  const handleSearchCategory = (value) => {
    setValueSearchCategory(value?.trim());
  };

  const handleCreateCategory = async () => {
    await form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const values = form.getFieldsValue();
        const createData = {
          name: values?.name,
          parent_id: Number(parentID),
        };
        await createCategory(createData)
          .then((res) => {
            form.resetFields();
            setVisible(false);
            refetch();
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error?.toString());
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    setParamsCategory({ ...paramsCategory, query: valueSearchCategory });
  }, [debouncedQueryCategory]);

  return {
    form,
    visible,
    loading: loading,
    setVisible,
    handleSearchCategory,
    handleCreateCategory,
  };
};
