// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-manager {
  position: fixed;
  bottom: 12px;
  left: 6px;
  background-color: rgba(67, 97, 238, 0.1254901961);
  color: #4361ee;
  font-size: 11px;
  padding: 4px 10px;
  border-radius: 16px;
  z-index: 99999;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/layouts/version-manager/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,SAAA;EACA,iDAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AACF","sourcesContent":[".version-manager {\n  position: fixed;\n  bottom: 12px;\n  left: 6px;\n  background-color: #4361ee20;\n  color: #4361ee;\n  font-size: 11px;\n  padding: 4px 10px;\n  border-radius: 16px;\n  z-index: 99999;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
