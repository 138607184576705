import { Checkbox, Space } from "antd";

export const CustomCheckbox = ({ options, valueDisabled = [], ...rest }) => {
  return (
    <Checkbox.Group {...rest}>
      <Space className="flex flex-col items-start gap-3 w-full">
        {options?.map((item) => {
          return (
            <Checkbox key={item?.value} value={item?.value} disabled={valueDisabled?.includes(item?.value)}>
              {item?.label}
            </Checkbox>
          );
        })}
      </Space>
    </Checkbox.Group>
  );
};
