import { Space, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";
import { PLATFORM_COLORS } from "../../../utils/constant";
import { getHostName } from "../../../utils/helperFuncs";

export const useTable = ({ handleDeleteSalesChannel }) => {
  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Domain</Typography.Text>,
      key: "domain",
      render: (_, record) => {
        return <Typography.Text>{record?.domain ? getHostName(record?.domain) : "- -"}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Assign Users</Typography.Text>,
      key: "platform",
      align: "center",
      render: (_, record) => {
        return record?.assign_users?.length > 0
          ? record?.assign_users?.map((item) => <Tag className="my-[2px] tag-default">{item?.name}</Tag>)
          : "- -";
      },
    },
    {
      title: <Typography.Text>Type</Typography.Text>,
      key: "platform",
      align: "center",
      render: (_, record) => {
        return <Tag color={PLATFORM_COLORS[record?.platform]}>{record?.platform?.toUpperCase()}</Tag>;
      },
    },

    {
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/sales-channel/${record?.id}`}>
            <SvgIcon.IconEdit />
          </Link>
          <CustomPopconfirm
            title="Delete"
            component={<SvgIcon.IconDelete />}
            description="Are you sure to delete this?"
            onConfirm={() => handleDeleteSalesChannel(record?.id)}
          />
        </Space>
      ),
    },
  ];
  return {
    columns,
  };
};
