/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useState } from "react";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { createTagSyncTask } from "../../../services/syncTaskService";

export const useCreateTag = ({ refetchTag, salesChannelId }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateTag = async () => {
    await form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const values = form.getFieldsValue();
        const createData = {
          name: values?.name,
          sales_channel_id: Number(salesChannelId),
        };
        await createTagSyncTask(createData)
          .then((res) => {
            form.resetFields();
            setVisible(false);
            refetchTag();
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error?.toString());
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  return {
    form,
    visible,
    loading: loading,
    setVisible,
    handleCreateTag,
  };
};
