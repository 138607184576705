import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomCountSelected } from "../../../components/custom-count-selected/CustomCountSelected";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomPagination } from "../../../components/custom-pagination/CustomPagination";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../components/custom-table/CustomTable";
import { useIsWidthMobile } from "../../../hooks/apps/useIsWidthMobile";
import { useList } from "../../../hooks/categories/list-category/useList";
import { useTable } from "../../../hooks/categories/list-category/useTable";
import { CustomListPage } from "../../../layouts/custom-list-page/CustomListPage";
import { optionsOrderby } from "../../../utils/options";
import { CreateCategory } from "./widget/CreateCategory";

export const ListCategorySection = () => {
  const {
    categories,
    loading,
    paginationData,
    paramsCategory,
    selectedRowKeys,
    valuesSearch,
    refetch,
    handleDeleteCategory,
    onSelectChange,
    handleSearchCategory,
    handleSortOrder,
    handleChangePerPage,
    handleDeleteSelectedCategories,
    handleChangePage,
  } = useList();

  const { columns } = useTable({ handleDeleteCategory });
  const isWidthMobile = useIsWidthMobile();

  return (
    <CustomListPage
      title="Categories"
      headerCard={
        <div className="flex-column">
          <div className="flex-center-between gap-2 flex-wrap">
            <CreateCategory refetch={refetch} />
            <CustomButton
              title="Delete Selected"
              type="cancel"
              onClick={() => handleDeleteSelectedCategories()}
              icon={<SvgIcon.IconDelete className="w-[18px] text-[#e7515a]" />}
            />
          </div>
          <Col span={24}>
            <Row className="flex-center-between">
              <Col span={isWidthMobile ? 24 : 16} className={`${isWidthMobile ? "mb-2" : ""}`}>
                <CustomInput
                  placeholder="Search categories"
                  value={valuesSearch}
                  prefix={<SearchOutlined />}
                  onChange={(value) => handleSearchCategory(value)}
                />
              </Col>
              <Col span={isWidthMobile ? 24 : 4}>
                <CustomSelect
                  options={optionsOrderby}
                  defaultValue={paramsCategory.sort_order}
                  width="100%"
                  onChange={(value) => handleSortOrder(value)}
                />
              </Col>
            </Row>
          </Col>
          {selectedRowKeys?.length > 0 && <CustomCountSelected count={selectedRowKeys?.length} />}
        </div>
      }
      contentCard={
        <>
          <CustomTable
            columns={columns}
            data={categories}
            rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }}
            loading={loading}
            rowKey={(record) => record.id}
          />
          {categories?.length > 0 && (
            <CustomPagination
              total={paginationData?.total ?? 0}
              limit={paginationData?.limit ?? 0}
              page={paginationData?.page ?? 0}
              handleChangePage={(pageNumber) => handleChangePage(pageNumber)}
              onChangePerPage={(pageSize) => handleChangePerPage(pageSize)}
            />
          )}
        </>
      }
    />
  );
};
