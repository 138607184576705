import { Form } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess, notifyWarning } from "../../../components/custom-notify/CustomNotify";
import {
  createCategory,
  getChildrenCategories,
  getDetailCategory,
  updateCategory,
} from "../../../services/categoryService";
import { optionUseQuery } from "../../../utils/options";

export const useDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [optionSubCategory, setOptionSubCategory] = useState([]);
  const [valueInputAdd, setValueInputAdd] = useState([]);

  const { data: categoryDetail } = useQuery(
    ["getDetailCategory", { id }],
    ({ queryKey }) => getDetailCategory(queryKey[1].id),
    optionUseQuery
  );

  const { data: childrenCategories, refetch: refetchChildren } = useQuery(
    ["getChildrenCategories", { id }],
    ({ queryKey }) => getChildrenCategories(queryKey[1].id),
    optionUseQuery
  );

  const optionsChildrenCategory = childrenCategories?.children?.map((item) => ({
    value: item?.id,
    label: item?.name,
    item: item,
  }));

  const handleChangeInputAdd = (e) => {
    setValueInputAdd(e?.target?.value);
  };

  const handleAddSubCate = () => {
    const newOption = { value: { value: valueInputAdd, isNew: true }, label: valueInputAdd, isNew: true };
    const isLabelExists =
      optionSubCategory?.length > 0 ? optionSubCategory?.some((option) => option.label === valueInputAdd) : false;
    if (!isLabelExists) {
      if (valueInputAdd?.length > 0) {
        setValueInputAdd("");
        setOptionSubCategory([...(optionSubCategory ?? []), newOption]);
      } else {
        notifyWarning("Subcategories already exist");
      }
    } else {
      notifyWarning("Subcategories already exist");
    }
  };

  const handleUpdateCategory = async () => {
    await form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue();
        const listSubAddNew = values?.subcategories
          ?.filter((item) => typeof item === "object" && item.isNew)
          ?.map((item) => item.value);

        if (listSubAddNew?.length > 0) {
          const createPromises = listSubAddNew.map((item) => createCategory({ name: item, parent_id: Number(id) }));
          await Promise.all(createPromises);
        }
        await updateCategory(id, {
          id: Number(id),
          children_ids: values?.subcategories?.filter((item) => typeof item !== "object"),
        })
          .then((res) => {
            navigate("/categories");
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error.toString());
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (categoryDetail) {
      form.setFieldsValue({
        name: categoryDetail.name,
        subcategories: categoryDetail?.children?.map((item) => item?.id),
      });
    }
  }, [categoryDetail, form]);

  return {
    form,
    id,
    categoryDetail,
    valueInputAdd,
    optionsChildrenCategory,
    refetchChildren,
    handleChangeInputAdd,
    handleAddSubCate,
    handleUpdateCategory,
  };
};
