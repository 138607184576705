import { Row, Typography } from "antd";
import { CustomCollapse } from "../../../../../components/custom-collapse/CustomCollapse";
import { CustomPagination } from "../../../../../components/custom-pagination/CustomPagination";
import { CustomTable } from "../../../../../components/custom-table/CustomTable";
import { useTableRenameRule } from "../../../../../hooks/sync-task-rule/useTableRenameRule";
import { AddRenameRule } from "./AddRenameRule";

export const RenameRuleWidget = ({ syncTaskID }) => {
  const { data, columns, isFetching, refetch, handleChangePerPage, handleChangePage } = useTableRenameRule({
    syncTaskID,
  });

  const items = [
    {
      key: "1",
      label: (
        <Row className="flex-center-between">
          <Typography.Text className="font-[500] text-[14px] block">Rename Rule</Typography.Text>
          <div onClick={(e) => e.stopPropagation()}>
            <AddRenameRule refetch={refetch} />
          </div>
        </Row>
      ),
      children: (
        <>
          <CustomTable columns={columns} data={data?.syncTaskRules} loading={isFetching} />
          {data?.syncTaskRules?.length > 0 && (
            <CustomPagination
              total={data?.paginationData?.total ?? 0}
              limit={data?.paginationData?.limit ?? 0}
              page={data?.paginationData?.page ?? 0}
              handleChangePage={(pageNumber) => handleChangePage(pageNumber)}
              onChangePerPage={(pageSize) => handleChangePerPage(pageSize)}
            />
          )}
        </>
      ),
    },
  ];
  return <CustomCollapse items={items} />;
};
