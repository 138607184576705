import "./index.scss";

export const BlockCard = ({ title, content }) => {
  return (
    <div className="block-card">
      {title && <div className="block-card-title">{title}</div>}
        <div className="block-card-content">{content}</div>
    </div>
  );
};
