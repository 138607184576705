import { Form, Row, Spin } from "antd";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../../components/custom-input/CustomInput";
import CustomModal from "../../../../components/custom-modal/CustomModal";
import { CustomSelect } from "../../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useCreateCategory } from "../../../../hooks/categories/list-category/useCreateCategory";
import { PARAMS_GET_MAX } from "../../../../utils/constant";
import { checkRulesNotNull } from "../../../../utils/validator";

export const CreateCategory = ({ refetch }) => {
  const {
    form,
    visible,
    optionsParentCategory,
    loading,
    loadingCategory,
    setParamsCategory,
    setVisible,
    handleSearchCategory,
    handleCreateCategory,
  } = useCreateCategory({
    refetch,
  });
  return (
    <Form form={form}>
      <CustomModal
        component={
          <CustomButton
            title="Add Category"
            type="primary"
            icon={<SvgIcon.IconImportFile />}
            onClick={() => setVisible(true)}
          />
        }
        loading={loading}
        title="Add Category"
        width="550px"
        open={visible}
        onClose={() => {
          setVisible(false);
          form.resetFields();
        }}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => handleCreateCategory()}
        labelConfirm="Create"
        content={
          <Spin spinning={loading}>
            <div className="flex-column">
              <Row>
                <CustomNameFormItem title="Name category" required />
                <Form.Item name="name" className="w-full" rules={[checkRulesNotNull()]}>
                  <CustomInput placeholder="Enter name category" />
                </Form.Item>
              </Row>
              <Row>
                <CustomNameFormItem title="Parent category" />
                <Form.Item name="parent_id" className="w-full">
                  <CustomSelect
                    placeholder="Select parent category"
                    width="100%"
                    options={optionsParentCategory}
                    showSearch
                    loading={loadingCategory}
                    onSearch={(value) => {
                      handleSearchCategory(value);
                    }}
                    onDropdownVisibleChange={(open) => {
                      if (!open) {
                        setParamsCategory(PARAMS_GET_MAX);
                      }
                    }}
                    filterOption={false}
                  />
                </Form.Item>
              </Row>
            </div>
          </Spin>
        }
      />
    </Form>
  );
};
