import { useIsWidthMobile } from "../hooks/apps/useIsWidthMobile";
import { LoginMobileSection } from "../sections/login/LoginMobileSection";
import { LoginSection } from "../sections/login/LoginSection";

export const LoginPage = ({ setIsAuthenticated }) => {
  const isWidthMobile = useIsWidthMobile();

  return !isWidthMobile ? (
    <LoginSection setIsAuthenticated={setIsAuthenticated} />
  ) : (
    <LoginMobileSection setIsAuthenticated={setIsAuthenticated} />
  );
};
