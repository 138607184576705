/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { notifyError, notifySuccess, notifyWarning } from "../../../components/custom-notify/CustomNotify";
import { deleteBulkSalesChannel, deleteSalesChannel, getSalesChannels } from "../../../services/salesChannelService";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { buildQueryString, getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useList = () => {
  const GET_QUERY = getQuery();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [valuesSearch, setValueSearch] = useState(GET_QUERY?.query ?? "");
  const [paramsSalesChannel, setParamsSalesChannel] = useState({
    ...PARAMS_DEFAULT,
    page: GET_QUERY.page ?? PARAMS_DEFAULT.page,
    per_page: GET_QUERY.per_page ?? PARAMS_DEFAULT.per_page,
    sort_order: GET_QUERY.sort_order ?? PARAMS_DEFAULT.sort_order,
    sort_by: GET_QUERY.sort_by ?? PARAMS_DEFAULT.sort_by,
    query: GET_QUERY?.query,
  });

  const debouncedQuery = useDebounce(valuesSearch);

  const {
    data: dataSalesChannels,
    isFetching,
    refetch,
  } = useQuery(
    ["getSalesChannels", paramsSalesChannel],
    ({ queryKey }) => getSalesChannels(queryKey[1]),
    optionUseQuery
  );

  const _deleteSalesChannel = useMutation(deleteSalesChannel, {});

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleDeleteSalesChannel = async (id) => {
    setLoadingDelete(true);
    try {
      await _deleteSalesChannel.mutateAsync(id).then((res) => {
        refetch();
        setSelectedRowKeys();
        notifySuccess(res?.message);
      });
    } catch (error) {
      notifyError(error?.toString());
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleDeleteSelectedSalesChannel = async () => {
    if (selectedRowKeys?.length > 0) {
      setLoadingDelete(true);
      await deleteBulkSalesChannel({ sales_channel_ids: selectedRowKeys })
        .then((res) => {
          refetch();
          setSelectedRowKeys();
          notifySuccess(res?.message);
        })
        .catch((error) => {
          notifyError(error?.toString());
        })
        .finally(() => {
          setLoadingDelete(false);
        });
    } else {
      notifyWarning("Please select at least one sales channel.");
    }
  };

  const handleSearchSalesChannel = (event) => {
    setValueSearch(event?.target?.value.trim());
  };

  const handleSortOrder = (value) => {
    setParamsSalesChannel({ ...paramsSalesChannel, sort_order: value, page: PARAMS_DEFAULT.page });
  };

  const handleChangePerPage = (pageSize) => {
    setParamsSalesChannel({ ...paramsSalesChannel, per_page: pageSize, page: PARAMS_DEFAULT.page });
  };

  const handleChangePage = (pageNumber) => {
    setSelectedRowKeys([]);
    setParamsSalesChannel({ ...paramsSalesChannel, page: pageNumber });
  };

  useEffect(() => {
    setParamsSalesChannel({ ...paramsSalesChannel, query: valuesSearch, page: PARAMS_DEFAULT.page });
  }, [debouncedQuery]);

  useEffect(() => {
    buildQueryString({
      params: paramsSalesChannel,
    });
  }, [paramsSalesChannel]);

  return {
    salesChannels: dataSalesChannels?.salesChannels,
    paginationData: dataSalesChannels?.paginationData,
    loading: loadingDelete || isFetching,
    paramsSalesChannel,
    selectedRowKeys,
    valuesSearch,
    handleDeleteSalesChannel,
    handleDeleteSelectedSalesChannel,
    onSelectChange,
    handleSearchSalesChannel,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
  };
};
