import { Form } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { createUsers } from "../../../services/usersService";
import { ROLE_USERS } from "../../../utils/constant";

export const useCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const formData = form.getFieldsValue();

  const handleCreateUsers = async () => {
    await form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue();
        const createData = {
          name: values.name,
          username: values.username,
          password: values?.password,
          email: values.email,
          is_active: values.is_active,
          role: values.role,
        };
        await createUsers(createData)
          .then((res) => {
            navigate("/users");
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error.toString());
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    form.setFieldsValue({
      ...formData,
      is_active: true,
      role: ROLE_USERS.STAFF,
    });
  }, [form, formData]);

  return { form, handleCreateUsers };
};
