import { Form } from "antd";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { getDetailUsers, updateUsers } from "../../../services/usersService";
import { optionUseQuery } from "../../../utils/options";

export const useDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: usersDetail } = useQuery(
    ["getDetailUsers", { id }],
    ({ queryKey }) => getDetailUsers(queryKey[1].id),
    optionUseQuery
  );

  const handleUpdateUsers = async () => {
    await form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue();
        const updateData = {
          id: Number(id),
          name: values.name,
          username: values.username,
          email: values.email,
          is_active: values.is_active,
          role: values.role,
        };
        await updateUsers(id, updateData)
          .then((res) => {
            navigate("/users");
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error.toString());
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (usersDetail) {
      form.setFieldsValue({
        name: usersDetail.name,
        username: usersDetail.username,
        email: usersDetail.email,
        is_active: usersDetail.is_active,
        role: usersDetail.role,
      });
    }
  }, [usersDetail, form]);

  return { form, handleUpdateUsers };
};
