import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomCountSelected } from "../../../components/custom-count-selected/CustomCountSelected";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomPagination } from "../../../components/custom-pagination/CustomPagination";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../components/custom-table/CustomTable";
import { useIsWidthMobile } from "../../../hooks/apps/useIsWidthMobile";
import { useList } from "../../../hooks/sync-task/list-sync-task/useList";
import { useTable } from "../../../hooks/sync-task/list-sync-task/useTable";
import { CustomListPage } from "../../../layouts/custom-list-page/CustomListPage";
import { optionsOrderby } from "../../../utils/options";
import { FilterWidget } from "./widget/FilterWidget";

export const ListSyncTaskSection = () => {
  const {
    syncTasks,
    paginationData,
    loading,
    paramsSyncTasks,
    selectedRowKeys,
    valuesSearch,
    setParamsSyncTasks,
    handleDeleteSyncTask,
    handleDeleteSelectedSyncTask,
    onSelectChange,
    handleSearchSyncTask,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
  } = useList();

  const { columns } = useTable({ handleDeleteSyncTask });
  const isWidthMobile = useIsWidthMobile();

  return (
    <CustomListPage
      title="Sync Task"
      headerCard={
        <div className="flex-column">
          <div className="flex justify-end gap-2">
            <CustomButton
              title="Delete Selected"
              type="cancel"
              onClick={() => handleDeleteSelectedSyncTask()}
              icon={<SvgIcon.IconDelete className="w-[18px] text-[#e7515a]" />}
            />
          </div>
          <Row className="flex-center-between">
            <Col span={isWidthMobile ? 24 : 16} className={`${isWidthMobile ? "mb-2" : ""}`}>
              <CustomInput
                placeholder="Search sync task"
                prefix={<SearchOutlined />}
                value={valuesSearch}
                onChange={(value) => handleSearchSyncTask(value)}
              />
            </Col>
            <Col span={isWidthMobile ? 24 : 4} className="flex justify-end gap-2">
              <CustomSelect
                options={optionsOrderby}
                defaultValue={paramsSyncTasks.sort_order}
                width="100%"
                onChange={(value) => handleSortOrder(value)}
              />
              <FilterWidget paramsSyncTasks={paramsSyncTasks} setParamsSyncTasks={setParamsSyncTasks} />
            </Col>
          </Row>
          {selectedRowKeys?.length > 0 && <CustomCountSelected count={selectedRowKeys?.length} />}
        </div>
      }
      contentCard={
        <>
          <CustomTable
            columns={columns}
            data={syncTasks}
            rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }}
            loading={loading}
            rowKey={(record) => record?.id}
          />
          {syncTasks?.length > 0 && (
            <CustomPagination
              total={paginationData?.total ?? 0}
              limit={paginationData?.limit ?? 0}
              page={paginationData?.page ?? 0}
              handleChangePage={(pageNumber) => handleChangePage(pageNumber)}
              onChangePerPage={(pageSize) => handleChangePerPage(pageSize)}
            />
          )}
        </>
      }
    />
  );
};
