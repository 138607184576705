import axios from "axios";
import api from "./api";
import { headersRequest } from "./authService";

export const getSyncTasks = async (paramsSyncTasks) => {
  try {
    const response = await api.get("/sales-channel-sync-tasks", {
      params: paramsSyncTasks,
      headers: headersRequest(),
    });
    const data = response?.data?.data;
    return {
      syncTasks: data?.items,
      paginationData: { total: data?.count, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching sales channel sync tasks");
  }
};

// fetch detail sync task
export const getDetailSyncTask = async (id) => {
  try {
    const response = await api.get(`/sales-channel-sync-tasks/${id}`, {
      headers: headersRequest(),
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

// create sync task
export const createSyncTask = async (createData) => {
  try {
    const response = await api.post(`/sales-channel-sync-tasks`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating category");
  }
};

// delete sync task
export const deleteSyncTask = async (syncTaskID) => {
  try {
    const response = await api.delete(`/sales-channel-sync-tasks/${syncTaskID}`, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete sync task");
  }
};

// update sync task
export const updateSyncTask = async (id, updateData) => {
  try {
    const response = await api.put(`/sales-channel-sync-tasks/${id}`, updateData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating sync task");
  }
};

// fetch detail sync task
export const getScProductSyncTask = async (request) => {
  try {
    const response = await api.get(`/sales-channel-sync-tasks/${request?.id}/sc_product_sync_tasks`, {
      params: request?.params,
      headers: headersRequest(),
    });
    const data = response?.data?.data;
    return {
      salesChannelProductSyncTasks: data?.salesChannelProductSyncTasks,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

// update quantity sc product
export const updateQuantityScProduct = async (id, updateData) => {
  try {
    const response = await api.put(`/sales-channel-sync-tasks/${id}/sc-products`, updateData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

// create category sync task
export const createCategorySyncTask = async (createData) => {
  try {
    const response = await api.post(`/sales-channel-sync-tasks/category`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

// create tag sync task
export const createTagSyncTask = async (createData) => {
  try {
    const response = await api.post(`/sales-channel-sync-tasks/tag`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

// get url for water mark
export const getUrlForWatermark = async (createData) => {
  try {
    const response = await api.put(`/sales-channel-sync-tasks/get-url`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

// post file sync task
export const postFileSyncTask = async (requestPostFile) => {
  try {
    const response = await axios.put(requestPostFile?.baseURL, requestPostFile?.binaryFiles[0], {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error post file sync task");
  }
};

// delete bulk sync task
export const deleteBulkSyncTask = async (requestDelete) => {
  try {
    const response = await api.delete(`/sales-channel-sync-tasks/bulk-delete`, {
      headers: headersRequest(),
      data: requestDelete,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete bulk sync task");
  }
};

// update status sync task
export const updateStatusSyncTask = async (id, updateData) => {
  try {
    const response = await api.put(`/sales-channel-sync-tasks/${id}/update-status`, updateData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating sync task");
  }
};

// update status sync task
export const getCountScProductFailed = async (id) => {
  try {
    const response = await api.get(`/sales-channel-sync-tasks/${id}/count-failed`, {
      headers: headersRequest(),
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating sync task");
  }
};

// retry sc products failed
export const retryScProductsFailed = async (updateData) => {
  try {
    const response = await api.put(`/sales-channel-sync-tasks/retry`, updateData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating sync task");
  }
};
