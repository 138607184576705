// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-card {
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}
.block-card .block-card-title {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 600;
}
.block-card .block-card-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/themes/block-card/index.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,iDAAA;AACF;AACE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ","sourcesContent":[".block-card {\n  background-color: white;\n  padding: 24px;\n  border-radius: 16px;\n  width: 100%;\n  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;\n\n  .block-card-title {\n    font-size: 16px;\n    margin-bottom: 12px;\n    font-weight: 600;\n  }\n\n  .block-card-content {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
