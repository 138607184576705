import { Form, Row, Tag } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useDetail } from "../../../hooks/categories/detail-category/useDetail";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { checkRulesNotNull } from "../../../utils/validator";
import { CreateCategory } from "./widget/CreateCategory";
import { ProductCategory } from "./widget/ProductCategory";
import { Subcategories } from "./widget/Subcategories";

export const DetailCategorySection = () => {
  const { form, id, categoryDetail, optionsChildrenCategory, refetchChildren, handleUpdateCategory } = useDetail();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Categories Detail"
        isMoreColumn
        actionButton={
          <>
            <Link to={"/categories"}>
              <CustomButton title="Back" type="default" />
            </Link>
            <CustomButton
              title="Save"
              type="primary"
              icon={<SvgIcon.IconImport className="h-5" />}
              onClick={() => handleUpdateCategory()}
            />
          </>
        }
        contentLeft={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Name" required />
                  <Form.Item name="name" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter Name" />
                  </Form.Item>
                </Row>
                <ProductCategory id={id} />
              </>
            }
          />
        }
        contentRight={
          <BlockCard
            content={
              <>
                {categoryDetail?.parent && (
                  <Row>
                    <CustomNameFormItem title="Parent Category" />
                    <div className="w-full text-[#4361ee] font-semibold text-[13px]">
                      <Link to={`/categories/${categoryDetail?.parent?.id}`}>
                        <Tag className="my-[2px] tag-default">{categoryDetail?.parent?.name}</Tag>
                      </Link>
                    </div>
                  </Row>
                )}
                <Row>
                  <Row className="flex-center-between">
                    <CustomNameFormItem title="Subcategories" />
                    <CreateCategory refetch={refetchChildren} parentID={id} />
                  </Row>
                  <div className="w-full mt-2">
                    <Subcategories optionsChildrenCategory={optionsChildrenCategory} />
                  </div>
                </Row>
              </>
            }
          />
        }
      />
    </Form>
  );
};
