/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { notifyError, notifySuccess, notifyWarning } from "../../../components/custom-notify/CustomNotify";
import { deleteBulkDescription, deleteDescription, getDescriptions } from "../../../services/descriptionService";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { buildQueryString, getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useList = () => {
  const GET_QUERY = getQuery();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [valuesSearch, setValueSearch] = useState(GET_QUERY?.query ?? "");
  const [paramsDescription, setParamsDescription] = useState({
    ...PARAMS_DEFAULT,
    page: GET_QUERY.page ?? PARAMS_DEFAULT.page,
    per_page: GET_QUERY.per_page ?? PARAMS_DEFAULT.per_page,
    sort_order: GET_QUERY.sort_order ?? PARAMS_DEFAULT.sort_order,
    sort_by: GET_QUERY.sort_by ?? PARAMS_DEFAULT.sort_by,
    query: GET_QUERY?.query,
    created_at_from: GET_QUERY?.created_at_from,
    created_at_to: GET_QUERY?.created_at_to,
    sales_channel_ids: GET_QUERY?.sales_channel_ids,
  });

  const debouncedQuery = useDebounce(valuesSearch);

  const {
    data: dataDescriptions,
    isFetching,
    refetch,
  } = useQuery(["getDescriptions", paramsDescription], ({ queryKey }) => getDescriptions(queryKey[1]), optionUseQuery);

  const _deleteDescription = useMutation(deleteDescription, {});

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleDeleteDescription = async (id) => {
    setLoadingDelete(true);
    try {
      await _deleteDescription.mutateAsync(id).then((res) => {
        refetch();
        setSelectedRowKeys();
        notifySuccess(res?.message);
      });
    } catch (error) {
      notifyError(error?.toString());
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleDeleteSelectedDescription = async () => {
    if (selectedRowKeys?.length > 0) {
      setLoadingDelete(true);
      await deleteBulkDescription({ description_ids: selectedRowKeys })
        .then((res) => {
          refetch();
          setSelectedRowKeys();
          notifySuccess(res?.message);
        })
        .catch((error) => {
          notifyError(error?.toString());
        })
        .finally(() => {
          setLoadingDelete(false);
        });
    } else {
      notifyWarning("Please select at least one description.");
    }
  };

  const handleSearchDescription = (event) => {
    setValueSearch(event?.target?.value);
  };

  const handleSortOrder = (value) => {
    setParamsDescription({ ...paramsDescription, sort_order: value, page: PARAMS_DEFAULT.page });
  };

  const handleChangePerPage = (pageSize) => {
    setParamsDescription({ ...paramsDescription, per_page: pageSize, page: PARAMS_DEFAULT.page });
  };

  const handleChangePage = (pageNumber) => {
    setSelectedRowKeys([]);
    setParamsDescription({ ...paramsDescription, page: pageNumber });
  };

  useEffect(() => {
    setParamsDescription({ ...paramsDescription, query: valuesSearch, page: PARAMS_DEFAULT.page });
  }, [debouncedQuery]);

  useEffect(() => {
    buildQueryString({
      params: paramsDescription,
    });
  }, [paramsDescription]);

  return {
    descriptions: dataDescriptions?.descriptions,
    paginationData: dataDescriptions?.paginationData,
    loading: loadingDelete || isFetching,
    paramsDescription,
    selectedRowKeys,
    valuesSearch,
    setParamsDescription,
    handleDeleteDescription,
    handleDeleteSelectedDescription,
    onSelectChange,
    handleSearchDescription,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
  };
};
