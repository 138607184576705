import { Link } from "react-router-dom";
import ImageNotFound from "../assets/images/image-404-not-found.jpg";

export const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-[#1C252E] bg-[#fff] px-[24px]">
      <div className="max-w-2xl text-center">
        <div className="text-4xl font-bold mb-4">Oops! Page not found</div>
        <span className="text-[16px] font-medium text-[#637381]">
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
          spelling.
        </span>
        <img src={ImageNotFound} alt="404 Error" className="w-[400px] mx-auto mb-8 rounded-lg" />
        <Link to="/">
          <span className="underline cursor-pointer">Go back to Home</span>
        </Link>
      </div>
    </div>
  );
};
