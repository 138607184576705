import { Col, Form, Row, Spin } from "antd";

import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import CustomModal from "../../../../components/custom-modal/CustomModal";
import { CustomRadio } from "../../../../components/custom-radio/CustomRadio";
import { CustomSelect } from "../../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useSyncTask } from "../../../../hooks/products/list-product/useSyncTask";
import { PARAMS_DEFAULT, TYPE_SELECTION } from "../../../../utils/constant";
import { optionsSelection } from "../../../../utils/options";
import { checkRulesNotNull } from "../../../../utils/validator";

export const SyncProduct = ({ paramsProduct, selectedRowKeys }) => {
  const {
    form,
    loading,
    visible,
    loadingSalesChannel,
    optionsSalesChannel,
    setParamsSalesChannel,
    setVisible,
    handleSearchSalesChannel,
    handleSyncProduct,
  } = useSyncTask({
    paramsProduct,
    selectedRowKeys,
  });
  return (
    <Form form={form}>
      <CustomModal
        component={
          <CustomButton
            type="primary"
            title="Sync Product"
            icon={<SvgIcon.IconSync className="w-4" />}
            onClick={() => setVisible(true)}
            loading={loading}
          />
        }
        loading={loading}
        title="Create Sync Task"
        width={550}
        open={visible}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        buttonOk={<CustomButton type="primary" title="Create" onClick={() => handleSyncProduct()} loading={loading} />}
        labelConfirm="Create"
        content={
          <Spin spinning={loading}>
            <Col span={24} className="flex-column">
              <Row className="flex-center-between">
                <Col span={8}>
                  <CustomNameFormItem title="Apply" required />
                </Col>
                <Col span={16}>
                  <Form.Item name="apply" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomRadio
                      options={optionsSelection}
                      valueDisabled={[selectedRowKeys?.length === 0 && TYPE_SELECTION.SELECTED]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-center-between">
                <Col span={8}>
                  <CustomNameFormItem title="Select Store" required />
                </Col>
                <Col span={16}>
                  <Form.Item name="sales_channel_id" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelect
                      placeholder="Select sales channel"
                      options={optionsSalesChannel}
                      width="100%"
                      showSearch
                      loading={loadingSalesChannel}
                      onSearch={(value) => {
                        handleSearchSalesChannel(value);
                      }}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          setParamsSalesChannel(PARAMS_DEFAULT);
                        }
                      }}
                      filterOption={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Spin>
        }
      />
    </Form>
  );
};
