import { Space, Typography } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { SvgIcon } from "../../assets/icons";
import { notifyError, notifySuccess } from "../../components/custom-notify/CustomNotify";
import { CustomPopconfirm } from "../../components/custom-popconfirm/CustomPopconfirm";
import { deleteSyncTaskRule, getSyncTasksRule } from "../../services/syncTaskRuleService";
import { PARAMS_DEFAULT } from "../../utils/constant";
import { convertRenameConfig } from "../../utils/helperFuncs";
import { optionUseQuery } from "../../utils/options";

export const useTableRenameRule = ({ syncTaskID }) => {
  const [paramsSyncTasksRule, setParamsSyncTasksRule] = useState({ ...PARAMS_DEFAULT, sync_task_ids: syncTaskID });

  const { data, isFetching, refetch } = useQuery(
    ["getSyncTasksRule", paramsSyncTasksRule],
    ({ queryKey }) => getSyncTasksRule(queryKey[1]),
    optionUseQuery
  );

  const handleChangePerPage = (pageSize) => {
    setParamsSyncTasksRule({ ...paramsSyncTasksRule, per_page: pageSize, page: PARAMS_DEFAULT.page });
  };

  const handleChangePage = (pageNumber) => {
    setParamsSyncTasksRule({ ...paramsSyncTasksRule, page: pageNumber });
  };

  const handleDeleteSyncTaskRule = async (id) => {
    await deleteSyncTaskRule(id)
      .then((res) => {
        notifySuccess(res?.message);
        refetch();
      })
      .catch((error) => {
        notifyError(error?.toString());
      });
  };

  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      align: "center",
      width: 75,
      render: (_, record) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Type</Typography.Text>,
      key: "type",
      align: "center",
      width: 100,
      render: (_, record) => {
        return <Typography.Text>{record?.type}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Statement</Typography.Text>,
      key: "statement",
      render: (_, record) => {
        return <Typography.Text>{convertRenameConfig(record?.config)}</Typography.Text>;
      },
    },
    {
      align: "center",
      width: 75,
      render: (_, record) => {
        return (
          <Space size="middle">
            <CustomPopconfirm
              title="Delete"
              component={<SvgIcon.IconDelete />}
              description="Are you sure to delete this?"
              onConfirm={() => handleDeleteSyncTaskRule(record?.id)}
            />
          </Space>
        );
      },
    },
  ];
  return { data, columns, isFetching, refetch, handleChangePerPage, handleChangePage };
};
