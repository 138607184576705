import { SearchOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { CustomInput } from "../../../../components/custom-input/CustomInput";
import { CustomPagination } from "../../../../components/custom-pagination/CustomPagination";
import { CustomTable } from "../../../../components/custom-table/CustomTable";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useTableProductCategory } from "../../../../hooks/categories/detail-category/useTableProductCategory";

export const ProductCategory = ({ id }) => {
  const {
    columns,
    products,
    paginationData,
    loading,
    handleSearchProduct,
    handleChangePageProduct,
    handleChangePerPageProduct,
  } = useTableProductCategory({ id });

  return (
    <>
      <div>
        <Row>
          <CustomNameFormItem title="List Product" />
          <CustomInput
            placeholder="Search product"
            prefix={<SearchOutlined />}
            width="100%"
            loading={loading}
            onChange={(value) => handleSearchProduct(value)}
          />
        </Row>
        <div className="w-[100%] mt-2">
          <CustomTable columns={columns} data={products} loading={loading} />
        </div>
        {products?.length > 0 && (
          <CustomPagination
            total={paginationData?.total ?? 0}
            limit={paginationData?.limit ?? 0}
            page={paginationData?.page ?? 0}
            handleChangePage={(pageNumber) => handleChangePageProduct(pageNumber)}
            onChangePerPage={(pageSize) => handleChangePerPageProduct(pageSize)}
          />
        )}
      </div>
    </>
  );
};
