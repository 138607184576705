/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { notifyError, notifySuccess, notifyWarning } from "../../../components/custom-notify/CustomNotify";
import { deleteBulkProduct, deleteProduct, getProducts, updateTitleProduct } from "../../../services/productService";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { buildQueryString, getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useList = () => {
  const [form] = Form.useForm();
  const GET_QUERY = getQuery();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [valuesSearch, setValueSearch] = useState(GET_QUERY?.query ?? "");
  const [visible, setVisible] = useState(false);
  const [dataById, setDataById] = useState(false);
  const debouncedQuery = useDebounce(valuesSearch);

  const [paramsProduct, setParamsProduct] = useState({
    ...PARAMS_DEFAULT,
    page: GET_QUERY.page ?? PARAMS_DEFAULT.page,
    per_page: GET_QUERY.per_page ?? PARAMS_DEFAULT.per_page,
    sort_order: GET_QUERY.sort_order ?? PARAMS_DEFAULT.sort_order,
    sort_by: GET_QUERY.sort_by ?? PARAMS_DEFAULT.sort_by,
    query: GET_QUERY?.query,
    user_ids: GET_QUERY?.user_ids,
    category_ids: GET_QUERY?.category_ids,
    created_at_to: GET_QUERY?.created_at_to,
    created_at_from: GET_QUERY?.created_at_from,
  });

  const {
    data: dataProducts,
    isFetching,
    refetch,
  } = useQuery(["getProducts", paramsProduct], ({ queryKey }) => getProducts(queryKey[1]), optionUseQuery);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleDeleteProduct = async (id) => {
    setLoadingDelete(true);
    try {
      await deleteProduct(id).then((res) => {
        refetch();
        setSelectedRowKeys();
        notifySuccess(res?.message);
      });
    } catch (error) {
      notifyError(error?.toString());
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleDeleteSelectedProduct = async () => {
    if (selectedRowKeys?.length > 0) {
      setLoadingDelete(true);
      await deleteBulkProduct({ product_ids: selectedRowKeys })
        .then((res) => {
          refetch();
          setSelectedRowKeys([]);
          notifySuccess(res?.message);
        })
        .catch((error) => {
          notifyError(error?.toString());
        })
        .finally(() => {
          setLoadingDelete(false);
        });
    } else {
      notifyWarning("Please select at least one product.");
    }
  };

  const handleSearchProduct = (event) => {
    setValueSearch(event?.target?.value);
  };

  const handleSortOrder = (value) => {
    setParamsProduct({ ...paramsProduct, sort_order: value, page: PARAMS_DEFAULT.page });
  };

  const handleChangePerPage = (pageSize) => {
    setParamsProduct({ ...paramsProduct, per_page: pageSize, page: PARAMS_DEFAULT.page });
  };

  const handleChangePage = (pageNumber) => {
    setSelectedRowKeys([]);
    setParamsProduct({ ...paramsProduct, page: pageNumber });
  };

  const handleOpenPopupGetByID = (record) => {
    setVisible(true);
    setDataById(record);
    form.setFieldsValue({
      name: record?.name,
    });
  };

  const handleEditTitleProduct = async () => {
    form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue();
        const requestUpdate = {
          id: dataById?.id,
          name: values?.name,
          is_active: dataById?.is_active,
          thumb_id: dataById?.thumb_id,
        };
        await updateTitleProduct(requestUpdate)
          .then((res) => {
            refetch();
            setVisible(false);
            form.resetFields();
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error.toString());
          });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setParamsProduct({ ...paramsProduct, query: valuesSearch, page: PARAMS_DEFAULT.page });
  }, [debouncedQuery]);

  useEffect(() => {
    buildQueryString({
      params: paramsProduct,
    });
  }, [paramsProduct]);

  return {
    products: dataProducts?.products,
    paginationData: dataProducts?.paginationData,
    loading: loadingDelete || isFetching,
    paramsProduct,
    selectedRowKeys,
    form,
    visible,
    valuesSearch,
    refetch,
    setVisible,
    setSelectedRowKeys,
    setParamsProduct,
    handleDeleteProduct,
    onSelectChange,
    handleDeleteSelectedProduct,
    handleSearchProduct,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
    handleOpenPopupGetByID,
    handleEditTitleProduct,
  };
};
