import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomCountSelected } from "../../../components/custom-count-selected/CustomCountSelected";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import CustomModal from "../../../components/custom-modal/CustomModal";
import { CustomPagination } from "../../../components/custom-pagination/CustomPagination";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../components/custom-table/CustomTable";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useIsWidthMobile } from "../../../hooks/apps/useIsWidthMobile";
import { useList } from "../../../hooks/products/list-product/useList";
import { useTable } from "../../../hooks/products/list-product/useTable";
import { CustomListPage } from "../../../layouts/custom-list-page/CustomListPage";
import { optionsOrderby } from "../../../utils/options";
import { checkRulesNotNull } from "../../../utils/validator";
import { EditSelected } from "./widget/EditSelected";
import { FilterWidget } from "./widget/FilterWidget";
import { SyncProduct } from "./widget/SyncProduct";

export const ListProductSection = () => {
  const isWidthMobile = useIsWidthMobile();

  const {
    form,
    products,
    loading,
    paramsProduct,
    selectedRowKeys,
    paginationData,
    visible,
    valuesSearch,
    refetch,
    setParamsProduct,
    setSelectedRowKeys,
    setVisible,
    handleDeleteProduct,
    onSelectChange,
    handleDeleteSelectedProduct,
    handleSearchProduct,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
    handleOpenPopupGetByID,
    handleEditTitleProduct,
  } = useList();

  const { columns } = useTable({ handleDeleteProduct, handleOpenPopupGetByID });

  return (
    <>
      <Form form={form}>
        <CustomModal
          title={"Edit Product"}
          width={550}
          open={visible}
          onClose={() => {
            form.resetFields();
            setVisible(false);
          }}
          onCancel={() => {
            form.resetFields();
            setVisible(false);
          }}
          buttonOk={
            <CustomButton
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleEditTitleProduct}
              title="Update"
            />
          }
          content={
            <Col span={24} className="flex-column">
              <Row className="flex-center-between">
                <CustomNameFormItem title="Title" required />
                <Form.Item name="name" className="w-full" rules={[checkRulesNotNull()]}>
                  <CustomInput placeholder="Enter title" width="100%" />
                </Form.Item>
              </Row>
            </Col>
          }
        />
      </Form>
      <CustomListPage
        title="Products"
        headerCard={
          <div className="flex-column">
            <Row className="flex justify-between items-center flex-wrap">
              <div className={`flex gap-2 ${isWidthMobile ? "mb-2" : ""}`}>
                <SyncProduct paramsProduct={paramsProduct} selectedRowKeys={selectedRowKeys} />
                <Link to="/products/upload">
                  <CustomButton type="primary" title="Import" icon={<SvgIcon.IconImport className="h-5" />} />
                </Link>
              </div>
              <div className="flex gap-2 flex-wrap">
                <EditSelected
                  paramsProduct={paramsProduct}
                  selectedRowKeys={selectedRowKeys}
                  refetch={refetch}
                  setSelectedRowKeys={setSelectedRowKeys}
                />
                <CustomButton
                  title="Delete Selected"
                  type="cancel"
                  onClick={() => handleDeleteSelectedProduct()}
                  icon={<SvgIcon.IconDelete className="w-[18px] text-[#e7515a]" />}
                />
              </div>
            </Row>
            <Row className="flex-center-between">
              <Col span={isWidthMobile ? 24 : 16} className={`${isWidthMobile ? "mb-2" : ""}`}>
                <CustomInput
                  placeholder="Search products"
                  prefix={<SearchOutlined />}
                  value={valuesSearch}
                  onChange={(value) => handleSearchProduct(value)}
                />
              </Col>
              <Col span={isWidthMobile ? 24 : 4} className="flex justify-end gap-2">
                <CustomSelect
                  options={optionsOrderby}
                  value={paramsProduct.sort_order}
                  width="100%"
                  onChange={(value) => handleSortOrder(value)}
                />
                <FilterWidget paramsProduct={paramsProduct} setParamsProduct={setParamsProduct} />
              </Col>
            </Row>

            {selectedRowKeys?.length > 0 && <CustomCountSelected count={selectedRowKeys?.length} />}
          </div>
        }
        contentCard={
          <>
            <CustomTable
              columns={columns}
              data={products}
              rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }}
              loading={loading}
              rowKey={(record) => record.id}
            />
            {products?.length > 0 && (
              <CustomPagination
                total={paginationData?.total ?? 0}
                limit={paginationData?.limit ?? 0}
                page={paginationData?.page ?? 0}
                handleChangePage={(pageNumber) => handleChangePage(pageNumber)}
                onChangePerPage={(pageSize) => handleChangePerPage(pageSize)}
              />
            )}
          </>
        }
      />
    </>
  );
};
