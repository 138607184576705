import { Form, Row } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomEditor } from "../../../components/custom-editor/CustomEditor";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useDetail } from "../../../hooks/descriptions/detail-description/useDetail";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { checkRulesNotNull } from "../../../utils/validator";

export const DetailDescriptionSection = () => {
  const { form, salesChannelOptions, handleUpdateDescription } = useDetail();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Description Detail"
        actionButton={
          <>
            <Link to={"/descriptions"}>
              <CustomButton title="Back" type="default" />
            </Link>
            <CustomButton
              title="Save"
              type="primary"
              icon={<SvgIcon.IconImportFile />}
              onClick={() => handleUpdateDescription()}
            />
          </>
        }
        contentCard={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Domain" required />
                  <Form.Item name="salesChannel_id" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelect placeholder="Name something" options={salesChannelOptions} disabled />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Title" required />
                  <Form.Item name="title" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Name something" />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Description" required />
                  <Form.Item name="content" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomEditor />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="SKU Format" required />
                  <Form.Item name="sku_format" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter sku format" />
                  </Form.Item>
                </Row>
                <Row style={{ display: "flex", gap: "2%" }}>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Price" />
                    <Form.Item name="price" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomInput placeholder="Enter price" isCurrency />
                    </Form.Item>
                  </div>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Sales Price" />
                    <Form.Item name="sales_price" className="w-full">
                      <CustomInput placeholder="Enter sales price" isCurrency />
                    </Form.Item>
                  </div>
                </Row>
              </>
            }
          />
        }
      />
    </Form>
  );
};
