import { Form, Row, Spin } from "antd";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../../components/custom-input/CustomInput";
import CustomModal from "../../../../components/custom-modal/CustomModal";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useCreateTag } from "../../../../hooks/sync-task/detail-sync-task/useCreateTag";
import { checkRulesNotNull } from "../../../../utils/validator";

export const CreateTag = ({ refetchTag, salesChannelId }) => {
  const { form, visible, loading, setVisible, handleCreateTag } = useCreateTag({
    refetchTag,
    salesChannelId,
  });

  return (
    <Form form={form}>
      <CustomModal
        component={<CustomButton title="Create" width={60} height={30} onClick={() => setVisible(true)} />}
        loading={loading}
        title="Create Tag"
        width="550px"
        open={visible}
        onClose={() => {
          setVisible(false);
          form.resetFields();
        }}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => handleCreateTag()}
        labelConfirm="Create"
        content={
          <Spin spinning={loading}>
            <div className="flex-column">
              <Row>
                <CustomNameFormItem title="Name Tag" required />
                <Form.Item name="name" className="w-full" rules={[checkRulesNotNull()]}>
                  <CustomInput placeholder="Enter name tag" />
                </Form.Item>
              </Row>
            </div>
          </Spin>
        }
      />
    </Form>
  );
};
