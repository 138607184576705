// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button .ant-btn {
  color: #fff;
  border-radius: 10px;
  font-weight: 600;
}
.custom-button .ant-btn-primary {
  background-color: #4361ee;
}
.custom-button .ant-btn-primary:hover {
  background-color: #4361ee !important;
  color: #fff !important;
}
.custom-button .ant-btn-secondary {
  background-color: #3b3f5c;
}
.custom-button .ant-btn-secondary:hover {
  background-color: #3b3f5c !important;
  color: #fff !important;
}
.custom-button .ant-btn-stop {
  background-color: white;
  border: 1px solid #3b3f5c;
  color: #3b3f5c !important;
}
.custom-button .ant-btn-stop:hover {
  background-color: rgba(59, 63, 92, 0.0823529412) !important;
  color: #3b3f5c !important;
}
.custom-button .ant-btn-cancel {
  background-color: #fff;
  border: 1px solid #e7515a;
  color: #e7515a;
}
.custom-button .ant-btn-cancel:hover {
  background-color: rgba(231, 81, 90, 0.0823529412);
  border: 1px solid #e7515a;
}
.custom-button .ant-btn-default {
  background-color: #fff;
  border: 1px solid #4361ee;
  color: #4361ee;
}
.custom-button .ant-btn-default:hover {
  background-color: rgba(67, 97, 238, 0.1254901961) !important;
  border: 1px solid #4361ee;
  color: #4361ee !important;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-button/index.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;AAEI;EACE,oCAAA;EACA,sBAAA;AAAN;AAIE;EACE,yBAAA;AAFJ;AAGI;EACE,oCAAA;EACA,sBAAA;AADN;AAKE;EACE,uBAAA;EACA,yBAAA;EACA,yBAAA;AAHJ;AAII;EACE,2DAAA;EACA,yBAAA;AAFN;AAME;EACE,sBAAA;EACA,yBAAA;EACA,cAAA;AAJJ;AAMI;EACE,iDAAA;EACA,yBAAA;AAJN;AAQE;EACE,sBAAA;EACA,yBAAA;EACA,cAAA;AANJ;AAOI;EACE,4DAAA;EACA,yBAAA;EACA,yBAAA;AALN","sourcesContent":[".custom-button {\n  .ant-btn {\n    color: #fff;\n    border-radius: 10px;\n    font-weight: 600;\n  }\n\n  .ant-btn-primary {\n    background-color: #4361ee;\n    &:hover {\n      background-color: #4361ee !important;\n      color: #fff !important;\n    }\n  }\n\n  .ant-btn-secondary {\n    background-color: #3b3f5c;\n    &:hover {\n      background-color: #3b3f5c !important;\n      color: #fff !important;\n    }\n  }\n\n  .ant-btn-stop {\n    background-color: white;\n    border: 1px solid #3b3f5c;\n    color: #3b3f5c !important;\n    &:hover {\n      background-color: #3b3f5c15 !important;\n      color: #3b3f5c !important;\n    }\n  }\n\n  .ant-btn-cancel {\n    background-color: #fff;\n    border: 1px solid #e7515a;\n    color: #e7515a;\n\n    &:hover {\n      background-color: #e7515a15;\n      border: 1px solid #e7515a;\n    }\n  }\n\n  .ant-btn-default {\n    background-color: #fff;\n    border: 1px solid #4361ee;\n    color: #4361ee;\n    &:hover {\n      background-color: #4361ee20 !important;\n      border: 1px solid #4361ee;\n      color: #4361ee !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
