import { UploadOutlined } from "@ant-design/icons";
import { Form, Image, Row, Upload } from "antd";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { BlockCard } from "../../../../themes/block-card/BlockCard";
import { ACCEPT_FILE_TYPES_IMAGE } from "../../../../utils/constant";
import { getFileNameWaterMark } from "../../../../utils/helperFuncs";
import { RenameRuleWidget } from "./rename-rule/RenameRule";

export const SyncOptions = ({ syncTaskDetail, id }) => {
  return (
    <BlockCard
      title="Sync Options"
      content={
        <>
          <Row>
            <CustomNameFormItem title="Add Watermark" />
            <div className="w-full">
              <Form.Item name="watermark_url" className="w-full">
                <Upload
                  action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                  accept={ACCEPT_FILE_TYPES_IMAGE}
                  listType="picture"
                  maxCount={1}
                  disabled={syncTaskDetail?.watermark_url}
                >
                  <CustomButton icon={<UploadOutlined />} title="Choose file..." className="w-full" />
                </Upload>
              </Form.Item>
              {syncTaskDetail?.watermark_url && (
                <div className="border py-2 px-3 border-[#4361ee] rounded-[10px] mt-2 bg-[#4361ee10] flex items-center gap-3 text-[#4361ee]">
                  <Image src={syncTaskDetail?.watermark_url} height={70} className="rounded-[10px]"></Image>
                  <span>{getFileNameWaterMark(syncTaskDetail?.watermark_url)}</span>
                </div>
              )}
            </div>
          </Row>
          <RenameRuleWidget syncTaskID={id} />
        </>
      }
    />
  );
};
