/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../../components/custom-notify/CustomNotify";
import { getSalesChannels } from "../../../services/salesChannelService";
import { createSyncTask } from "../../../services/syncTaskService";
import { PARAMS_DEFAULT, TYPE_SELECTION } from "../../../utils/constant";
import { delayTimeout, getHostName, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useSyncTask = ({ paramsProduct, selectedRowKeys }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paramsSalesChannel, setParamsSalesChannel] = useState(PARAMS_DEFAULT);
  const [valueSearchSalesChannel, setValueSearchSalesChannel] = useState("");

  const debouncedQuerySalesChannel = useDebounce(valueSearchSalesChannel);

  const { data: dataSalesChannel, isFetching: loadingSalesChannel } = useQuery(
    ["getSalesChannels", paramsSalesChannel],
    ({ queryKey }) => getSalesChannels(queryKey[1]),
    optionUseQuery
  );

  const handleSearchSalesChannel = (value) => {
    setValueSearchSalesChannel(value);
  };

  const optionsSalesChannel = dataSalesChannel?.salesChannels?.map((item) => ({
    value: item?.id,
    label: getHostName(item?.domain),
  }));

  const handleSyncProduct = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const values = form.getFieldsValue();
        const { sales_channel_id } = values || {};

        const requestPayload =
          values?.apply === TYPE_SELECTION.ALL
            ? { filter: paramsProduct, sales_channel_id }
            : { product_ids: selectedRowKeys, sales_channel_id };

        await createSyncTask(requestPayload)
          .then(async (res) => {
            await delayTimeout(5000);
            setVisible(false);
            form.resetFields();
            navigate(`/sync-task/${res?.data?.id}?sales_channel_id=${sales_channel_id}`);
          })
          .catch((error) => {
            notifyError(error?.toString());
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    setParamsSalesChannel({ ...paramsSalesChannel, query: valueSearchSalesChannel });
  }, [debouncedQuerySalesChannel]);

  useEffect(() => {
    form.setFieldsValue({
      apply: selectedRowKeys?.length === 0 ? TYPE_SELECTION.ALL : TYPE_SELECTION.SELECTED,
    });
  }, [selectedRowKeys]);
  return {
    form,
    loading,
    visible,
    loadingSalesChannel,
    optionsSalesChannel,
    setVisible,
    setParamsSalesChannel,
    handleSearchSalesChannel,
    handleSyncProduct,
  };
};
