import { Form, Row } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useDetail } from "../../../hooks/users/detail-users/useDetail";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { ROLE_USERS } from "../../../utils/constant";
import { optionsActiveUsers, optionsRolesUsers } from "../../../utils/options";
import { checkRulesNotNull, validateEmailFormat } from "../../../utils/validator";

export const DetailUsersSection = () => {
  const { form, handleUpdateUsers } = useDetail();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Users Detail"
        actionButton={
          <>
            <Link to={"/users"}>
              <CustomButton title="Back" type="default" />
            </Link>
            <CustomButton
              title="Save"
              type="primary"
              icon={<SvgIcon.IconImportFile />}
              onClick={() => handleUpdateUsers()}
            />
          </>
        }
        contentCard={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Name" required />
                  <Form.Item name="name" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter name" />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Username" required />
                  <Form.Item name="username" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter username" disabled />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Email" />
                  <Form.Item name="email" className="w-full" rules={[validateEmailFormat()]}>
                    <CustomInput placeholder="Enter email" />
                  </Form.Item>
                </Row>
                <Row style={{ display: "flex", gap: "2%" }}>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Roles" required />
                    <Form.Item name="role" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomSelect
                        placeholder="Select roles"
                        width="100%"
                        options={optionsRolesUsers}
                        defaultValue={ROLE_USERS.STAFF}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Active" required />
                    <Form.Item name="is_active" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomSelect
                        placeholder="Select active"
                        width="100%"
                        options={optionsActiveUsers}
                        defaultValue={true}
                      />
                    </Form.Item>
                  </div>
                </Row>
              </>
            }
          />
        }
      />
    </Form>
  );
};
