import { Form } from "antd";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { getDetailDescription, updateDescription } from "../../../services/descriptionService";
import { getSalesChannels } from "../../../services/salesChannelService";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { optionUseQuery } from "../../../utils/options";

export const useDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: descriptionsDetail } = useQuery(
    ["getDetailDescription", { id }],
    ({ queryKey }) => getDetailDescription(queryKey[1].id),
    optionUseQuery
  );

  const { data: salesChannelsData } = useQuery(
    ["getSalesChannels", PARAMS_DEFAULT],
    ({ queryKey }) => getSalesChannels(queryKey[1]),
    optionUseQuery
  );

  const salesChannelOptions = salesChannelsData?.salesChannels?.map((item) => ({
    value: item?.id,
    label: item?.domain,
  }));

  const handleUpdateDescription = async () => {
    await form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue();
        const updateData = {
          id: Number(id),
          salesChannel_id: values.salesChannel_id,
          title: values.title,
          content: values?.content,
          status: values.status,
          price: Number(values.price),
          sales_price: Number(values.sales_price),
          sku_format: values.sku_format,
        };
        await updateDescription(id, updateData)
          .then((res) => {
            navigate("/descriptions");
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error.toString());
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (descriptionsDetail) {
      form.setFieldsValue({
        salesChannel_id: descriptionsDetail.salesChannel_id,
        title: descriptionsDetail?.title,
        content: descriptionsDetail?.content,
        price: descriptionsDetail?.price,
        status: descriptionsDetail?.status,
        sales_price: descriptionsDetail?.sales_price,
        sku_format: descriptionsDetail?.sku_format,
      });
    }
  }, [descriptionsDetail, form]);

  return { form, descriptionsDetail, salesChannelOptions, handleUpdateDescription };
};
