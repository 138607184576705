import { Modal } from "antd";
import { useState } from "react";
import { CustomButton } from "../custom-button/CustomButton";
import "./index.scss";

const CustomModal = ({
  component,
  title,
  loading,
  content,
  buttonOk,
  buttonCancel,
  labelConfirm = "Apply",
  labelCancel = "Cancel",
  onCancel,
  onOk,
  ...rest
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <span onClick={showModal} className="cursor-pointer">
        {component}
      </span>
      <Modal
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={onCancel ? onCancel : handleCancel}
        footer={[
          buttonCancel ? (
            buttonCancel
          ) : (
            <CustomButton
              type="cancel"
              key="back"
              onClick={onCancel ? onCancel : handleCancel}
              title={labelCancel}
              loading={loading}
            />
          ),
          buttonOk ? (
            buttonOk
          ) : (
            <CustomButton
              key="submit"
              type="primary"
              loading={loading}
              onClick={onOk ? onOk : handleOk}
              title={labelConfirm}
            />
          ),
        ]}
        className="custom-modal"
        {...rest}
      >
        {content}
      </Modal>
    </div>
  );
};
export default CustomModal;
