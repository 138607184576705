import { Row } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { CustomPagination } from "../../../../components/custom-pagination/CustomPagination";

export const Subcategories = ({ optionsChildrenCategory }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = optionsChildrenCategory?.slice(startIndex, endIndex);

  return (
    <>
      {currentItems?.length > 0 && (
        <div className="border p-2 overflow-x-hidden overflow-y-auto rounded-xl">
          {currentItems?.map((item) => (
            <>
              <Link key={item.value} to={`/categories/${item?.value}`}>
                <Row className="cursor-pointer">
                  <div className="p-1">{item?.label}</div>
                </Row>
              </Link>
            </>
          ))}
        </div>
      )}

      <div>
        {optionsChildrenCategory?.length > 25 && (
          <CustomPagination
            total={optionsChildrenCategory?.length}
            limit={itemsPerPage}
            page={currentPage}
            showSizeChanger={false}
            handleChangePage={(pageNumber) => handleChangePage(pageNumber)}
          />
        )}
      </div>
    </>
  );
};
