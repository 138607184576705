import { Avatar, Tag } from "antd";
import { jwtDecode } from "jwt-decode";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { SvgIcon } from "../../assets/icons";
import { CustomDropDown } from "../../components/custom-dropdown/CustomDropDown";
import { useIsWidthMobile } from "../../hooks/apps/useIsWidthMobile";
import { getToken, removeToken } from "../../services/authService";
import { getDetailUsers } from "../../services/usersService";
import { optionUseQuery } from "../../utils/options";
import "./index.scss";

export const NavBar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const token = getToken();
  const decodedToken = jwtDecode(token);
  const id = decodedToken.user_id;
  const isWidthMobile = useIsWidthMobile();

  const { data: usersDetail } = useQuery(
    ["getDetailUsers", { id }],
    ({ queryKey }) => getDetailUsers(queryKey[1].id),
    optionUseQuery
  );

  const handleLogout = () => {
    removeToken();
    navigate("/login");
    localStorage.removeItem("isRemembered");
  };

  const handleClickPersonalInfo = () => {
    navigate(`/personal-info/${decodedToken?.user_id}`);
  };

  const handleChangePassword = () => {
    navigate(`/change-password/${decodedToken?.user_id}`);
  };

  const items = [
    {
      label: "Personal Information",
      key: "1",
      icon: <SvgIcon.IconProfile className="w-[18px] h-[18px]" />,
      onClick: handleClickPersonalInfo,
    },
    {
      label: "Change password",
      key: "2",
      icon: <SvgIcon.IconChangePassword className="w-[18px] h-[18px]" />,
      onClick: handleChangePassword,
    },
    {
      label: <span className="text-[#e7515a]">Log out</span>,
      key: "3",
      icon: <SvgIcon.IconLogout className="w-[18px] h-[18px]" />,
      onClick: handleLogout,
    },
  ];

  return (
    <div className={`navbar ${!collapsed ? "pl-[324px]" : isWidthMobile ? "pl-[108px]" : "pl-[24px]"} pr-[24px]`}>
      {!isWidthMobile ? (
        collapsed ? (
          <SvgIcon.IconCollapseFalse onClick={() => setCollapsed(!collapsed)} className="w-5 cursor-pointer" />
        ) : (
          <SvgIcon.IconCollapseTrue onClick={() => setCollapsed(!collapsed)} className="w-5 cursor-pointer" />
        )
      ) : (
        <div></div>
      )}
      <div>
        <CustomDropDown
          component={
            <div className="flex gap-2">
              <div className="flex flex-col items-end justify-end">
                {usersDetail?.name}
                {usersDetail?.role && (
                  <Tag className="w-fit mr-0" color="geekblue">
                    <span className="text-[11px]">{usersDetail?.role.toUpperCase()}</span>
                  </Tag>
                )}
              </div>
              <Avatar className="bg-[#4362ee] cursor-pointer" size={40}>
                {usersDetail?.name?.length > 0 ? usersDetail?.name?.charAt(0)?.toUpperCase() : "S"}
              </Avatar>
            </div>
          }
          items={items}
        />
      </div>
    </div>
  );
};
