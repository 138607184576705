import { Input, InputNumber } from "antd";

export const CustomInput = ({
  placeholder,
  height = 40,
  isPassword = false,
  isCurrency = false,
  isNumber = false,
  ...rest
}) => {
  const styleInput = {
    height: `${height}px`,
    borderRadius: "10px",
    width: "100%",
  };

  if (isPassword) {
    return <Input.Password placeholder={placeholder} allowClear style={styleInput} {...rest} />;
  } else if (isCurrency) {
    return (
      <InputNumber
        controls={false}
        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
        step={0.01}
        style={styleInput}
        {...rest}
      />
    );
  } else if (isNumber) {
    return <InputNumber style={styleInput} {...rest} placeholder={placeholder} />;
  } else {
    return <Input placeholder={placeholder} allowClear style={styleInput} {...rest} />;
  }
};
