import { DatePicker } from "antd";

export const CustomDateTimePicker = ({ ...rest }) => {
  const styleDatePicker = {
    height: "40px",
    borderRadius: "10px",
    width: "100%",
  };

  return <DatePicker style={styleDatePicker} {...rest} />;
};
