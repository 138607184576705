import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SvgIcon } from "../../assets/icons";
import { getToken } from "../../services/authService";
import { isAllRoles } from "../../utils/boolean";
import { getDefaultSelectedKey } from "../../utils/router-link";
import "./index.scss";

export const MenuMobile = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const [activePage, setActivePage] = useState();
  const token = getToken();
  const decodedToken = jwtDecode(token);

  function getItem(link, label, key, icon) {
    return {
      key,
      icon,
      label,
      link,
    };
  }

  const items = [
    getItem("/products", "Products", "2", <SvgIcon.IconMenuProduct className="w-5" />),
    getItem("/descriptions", "Descriptions", "3", <SvgIcon.IconMenuCategories className="w-5" />),
    getItem("/categories", "Categories", "4", <SvgIcon.IconMenuDescription className="w-5" />),
    getItem("/sales-channel", "Sales Channel", "5", <SvgIcon.IconMenuSalesChannel className="w-5" />),
    getItem("/sync-task", "Sync Task", "6", <SvgIcon.IconMenuSyncTask className="w-[19px]" />),
    isAllRoles(decodedToken.role) && getItem("/users", "Users", "7", <SvgIcon.IconMenuUser className="w-5" />),
  ];

  useEffect(() => {
    setActivePage(getDefaultSelectedKey(location.pathname));
  }, [location]);

  const listItemsMenu = items?.filter((item) => item !== false);

  return (
    <div className={`menu-mobile-layout ${collapsed ? "collapsed" : ""}`}>
      <div className={`menu-list ${collapsed ? "hidden" : ""}`}>
        {listItemsMenu?.map((item) => (
          <Link
            to={item.link}
            onClick={() => setActivePage(item?.key)}
            key={item?.key}
            className={`menu-item w-1/${listItemsMenu.length} ${activePage === item?.key ? "active" : ""}`}
          >
            {item?.icon}
            {item?.label}
          </Link>
        ))}
      </div>
    </div>
  );
};
