/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { notifyError, notifySuccess, notifyWarning } from "../../../components/custom-notify/CustomNotify";
import { deleteBulkUsers, deleteUsers, getUsers } from "../../../services/usersService";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { buildQueryString, getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useList = () => {
  const GET_QUERY = getQuery();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [valuesSearch, setValueSearch] = useState(GET_QUERY?.query ?? "");
  const [paramsUsers, setParamsUsers] = useState({
    ...PARAMS_DEFAULT,
    page: GET_QUERY.page ?? PARAMS_DEFAULT.page,
    per_page: GET_QUERY.per_page ?? PARAMS_DEFAULT.per_page,
    sort_order: GET_QUERY.sort_order ?? PARAMS_DEFAULT.sort_order,
    sort_by: GET_QUERY.sort_by ?? PARAMS_DEFAULT.sort_by,
    query: GET_QUERY?.query,
  });

  const debouncedQuery = useDebounce(valuesSearch);

  const {
    data: dataUsers,
    isFetching,
    refetch,
  } = useQuery(["getUsers", paramsUsers], ({ queryKey }) => getUsers(queryKey[1]), optionUseQuery);

  const _deleteUsers = useMutation(deleteUsers, {});

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleDeleteUsers = async (id) => {
    setLoadingDelete(true);
    try {
      await _deleteUsers.mutateAsync(id).then((res) => {
        refetch();
        setSelectedRowKeys();
        notifySuccess(res?.message);
      });
    } catch (error) {
      notifyError(error?.toString());
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleDeleteSelectedUsers = async () => {
    if (selectedRowKeys?.length > 0) {
      setLoadingDelete(true);
      await deleteBulkUsers({ user_ids: selectedRowKeys })
        .then((res) => {
          refetch();
          setSelectedRowKeys();
          notifySuccess(res?.message);
        })
        .catch((error) => {
          notifyError(error?.toString());
        })
        .finally(() => {
          setLoadingDelete(false);
        });
    } else {
      notifyWarning("Please select at least one users.");
    }
  };

  const handleSearchUsers = (event) => {
    setValueSearch(event?.target?.value);
  };

  const handleSortOrder = (value) => {
    setParamsUsers({ ...paramsUsers, sort_order: value, page: PARAMS_DEFAULT.page });
  };

  const handleChangePerPage = (pageSize) => {
    setParamsUsers({ ...paramsUsers, per_page: pageSize, page: PARAMS_DEFAULT.page });
  };

  const handleChangePage = (pageNumber) => {
    setParamsUsers({ ...paramsUsers, page: pageNumber });
  };

  useEffect(() => {
    setParamsUsers({ ...paramsUsers, query: valuesSearch, page: PARAMS_DEFAULT.page });
  }, [debouncedQuery]);

  useEffect(() => {
    buildQueryString({
      params: paramsUsers,
    });
  }, [paramsUsers]);
  return {
    users: dataUsers?.users,
    paginationData: dataUsers?.paginationData,
    loading: loadingDelete || isFetching,
    paramsUsers,
    selectedRowKeys,
    valuesSearch,
    setParamsUsers,
    handleDeleteUsers,
    handleDeleteSelectedUsers,
    onSelectChange,
    handleSearchUsers,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
  };
};
