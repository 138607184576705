import { Form, Row } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomSelectTag } from "../../../components/custom-select-tag/CustomSelectTag";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useDetail } from "../../../hooks/sales-channel/detail-sales-channel/useDetail";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { PARAMS_DEFAULT, ROLE_USERS } from "../../../utils/constant";
import { optionsPlatform, optionsSalesChannelStatus } from "../../../utils/options";
import { checkRulesNotNull } from "../../../utils/validator";

export const DetailSalesChannelSection = () => {
  const { form, optionsUsers, loadingUsers, handleSearchUsers, setParamsUser, handleUpdateSalesChanel } = useDetail();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Sales Channel Detail"
        isMoreColumn
        actionButton={
          <>
            <Link to={"/sales-channel"}>
              <CustomButton title="Back" type="default" />
            </Link>
            <CustomButton
              title="Save"
              type="primary"
              icon={<SvgIcon.IconImportFile />}
              onClick={() => handleUpdateSalesChanel()}
            />
          </>
        }
        contentLeft={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Domain" required />
                  <Form.Item name="domain" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter Domain" />
                  </Form.Item>
                </Row>
                <Row style={{ display: "flex", gap: "2%" }}>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Api key" required />
                    <Form.Item name="api_key" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomInput placeholder="Enter Api key" />
                    </Form.Item>
                  </div>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Api Token" required />
                    <Form.Item name="api_token" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomInput placeholder="Enter Api Token" />
                    </Form.Item>
                  </div>
                </Row>
              </>
            }
          />
        }
        contentRight={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Status" required />
                  <Form.Item name="status" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelect placeholder="Enter Status" options={optionsSalesChannelStatus} width="100%" />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Platform" required />
                  <Form.Item name="platform" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelect placeholder="Enter Platform" options={optionsPlatform} width="100%" />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Assign User" required />
                  <Form.Item name="assign_user_ids" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelectTag
                      placeholder="Enter Assign User"
                      width="100%"
                      options={optionsUsers}
                      loading={loadingUsers}
                      onSearch={(value) => {
                        handleSearchUsers(value);
                      }}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          setParamsUser({ ...PARAMS_DEFAULT, roles: ROLE_USERS.STAFF });
                        }
                      }}
                      filterOption={false}
                    />
                  </Form.Item>
                </Row>
              </>
            }
          />
        }
      />
    </Form>
  );
};
