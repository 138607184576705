import { Form, Row } from "antd";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomDateTimePicker } from "../../../../components/custom-date-time-picker/CustomDateTimePicker";
import { CustomPopover } from "../../../../components/custom-popover/CustomPopover";
import { CustomSelect } from "../../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useIsWidthMobile } from "../../../../hooks/apps/useIsWidthMobile";
import { useFilter } from "../../../../hooks/descriptions/list-description/useFilter";
import { PARAMS_GET_MAX } from "../../../../utils/constant";

export const FilterWidget = ({ paramsDescription, setParamsDescription }) => {
  const {
    form,
    visiblePopover,
    optionSalesChannel,
    loadingSalesChannel,
    initialValues,
    setParamsSalesChannel,
    setVisiblePopover,
    handleSearchSalesChannel,
    handleClearFilter,
    handleFilter,
  } = useFilter({
    paramsDescription,
    setParamsDescription,
  });
  const isWidthMobile = useIsWidthMobile();

  return (
    <Form form={form} initialValues={initialValues}>
      <CustomPopover
        width={isWidthMobile ? 300 : 400}
        title={
          <>
            <div>Filter</div>
            <div className="flex gap-2">
              <CustomButton title="Clear" height={32} onClick={() => handleClearFilter()} />
              <CustomButton title="Save" type="primary" height={32} onClick={() => handleFilter()} />
            </div>{" "}
          </>
        }
        open={visiblePopover}
        onClose={() => setVisiblePopover(false)}
        component={
          <CustomButton
            title="Filter"
            type="default"
            onClick={() => {
              setVisiblePopover(true);
            }}
            icon={<SvgIcon.IconFilter className="w-5 text-[#4361ee]"></SvgIcon.IconFilter>}
          />
        }
        content={
          <>
            <Row>
              <CustomNameFormItem title="Creation Date" />
              <div className="flex-center-between gap-2">
                <Form.Item name="created_at_from" className="w-full">
                  <CustomDateTimePicker placeholder="Select form date" />
                </Form.Item>
                <Form.Item name="created_at_to" className="w-full">
                  <CustomDateTimePicker placeholder="Select to date" />
                </Form.Item>
              </div>
            </Row>
            <Row>
              <CustomNameFormItem title="Sales Channel" />
              <Form.Item name="sales_channel_ids" className="w-full">
                <CustomSelect
                  placeholder="Select sales channel"
                  options={optionSalesChannel}
                  showSearch
                  loading={loadingSalesChannel}
                  onSearch={(value) => {
                    handleSearchSalesChannel(value);
                  }}
                  onDropdownVisibleChange={(open) => {
                    if (!open) {
                      setParamsSalesChannel(PARAMS_GET_MAX);
                    }
                  }}
                  defaultValue={paramsDescription?.sales_channel_ids}
                  filterOption={false}
                />
              </Form.Item>
            </Row>
          </>
        }
      />
    </Form>
  );
};
