import { useEffect, useState } from "react";
import { useWindowWidth } from "./useWindowWidth";

export const useApp = () => {
  const windowWidth = useWindowWidth();

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (windowWidth < 991) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [windowWidth]);
  return { collapsed, setCollapsed };
};
