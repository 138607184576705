/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Typography } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getProducts } from "../../../services/productService";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useTableProductCategory = ({ id }) => {
  const [paramsProduct, setParamsProduct] = useState({ ...PARAMS_DEFAULT, category_ids: [id] });
  const [valueSearchProduct, setValueSearchProduct] = useState("");
  const { data: dataProducts, isFetching: loading } = useQuery(
    ["getProducts", paramsProduct],
    ({ queryKey }) => getProducts(queryKey[1]),
    optionUseQuery
  );

  useEffect(() => {
    setParamsProduct({ ...PARAMS_DEFAULT, category_ids: id });
  }, [id]);

  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      align: "center",
      width: "10%",
      render: (text, record, index) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Thumb</Typography.Text>,
      key: "thumb",
      align: "center",
      width: "15%",
      render: (_, record) => {
        return <Avatar src={record?.thumb?.url} size={40} />;
      },
    },
    {
      title: <Typography.Text>Title</Typography.Text>,
      key: "name",
      render: (_, record) => {
        return <Typography.Text>{record?.name || "- -"}</Typography.Text>;
      },
    },
  ];

  const debouncedQueryProduct = useDebounce(valueSearchProduct);

  const handleChangePageProduct = (pageNumber) => {
    setParamsProduct({ ...paramsProduct, page: pageNumber });
  };

  const handleChangePerPageProduct = (pageSize) => {
    setParamsProduct({ ...paramsProduct, per_page: pageSize });
  };

  const handleSearchProduct = (event) => {
    setValueSearchProduct(event?.target?.value?.trim());
  };

  useEffect(() => {
    setParamsProduct({ ...paramsProduct, query: valueSearchProduct });
  }, [debouncedQueryProduct]);

  return {
    columns,
    products: dataProducts?.products,
    loading,
    paginationData: dataProducts?.paginationData,
    handleSearchProduct,
    handleChangePageProduct,
    handleChangePerPageProduct,
  };
};
