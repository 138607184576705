// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list-page .content-more-page {
  display: flex;
  width: 100%;
  gap: 24px;
  max-width: 100%;
  align-items: stretch;
  flex-wrap: wrap;
}
.custom-list-page .content-more-page .content-left-page {
  display: block;
}
.custom-list-page .content-more-page .content-right-page {
  flex: 3 1;
}`, "",{"version":3,"sources":["webpack://./src/layouts/custom-detail-page/index.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,oBAAA;EACA,eAAA;AAAJ;AACI;EACE,cAAA;AACN;AACI;EACE,SAAA;AACN","sourcesContent":[".custom-list-page {\n  .content-more-page {\n    display: flex;\n    width: 100%;\n    gap: 24px;\n    max-width: 100%;\n    align-items: stretch;\n    flex-wrap: wrap;\n    .content-left-page {\n      display: block;\n    }\n    .content-right-page {\n      flex: 3 1 0%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
