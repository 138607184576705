/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { createCategory, getCategories } from "../../../services/categoryService";
import { PARAMS_GET_MAX } from "../../../utils/constant";
import { useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useCreateCategory = ({ refetch }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paramsCategory, setParamsCategory] = useState(PARAMS_GET_MAX);
  const [valueSearchCategory, setValueSearchCategory] = useState("");

  const { data: dataCategory, isFetching: loadingCategory } = useQuery(
    ["getCategories", paramsCategory],
    ({ queryKey }) => getCategories(queryKey[1]),
    optionUseQuery
  );

  const debouncedQueryCategory = useDebounce(valueSearchCategory);

  const optionsParentCategory = dataCategory?.categories?.map((item) => ({ value: item?.id, label: item?.name }));

  const handleSearchCategory = (value) => {
    setValueSearchCategory(value);
  };

  const handleCreateCategory = async () => {
    await form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const values = form.getFieldsValue();
        const createData = {
          name: values?.name,
          parent_id: values?.parent_id,
        };
        await createCategory(createData)
          .then((res) => {
            form.resetFields();
            setVisible(false);
            refetch();
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error?.toString());
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    setParamsCategory({ ...paramsCategory, query: valueSearchCategory });
  }, [debouncedQueryCategory]);

  return {
    form,
    visible,
    loading,
    loadingCategory,
    optionsParentCategory,
    setVisible,
    handleSearchCategory,
    handleCreateCategory,
  };
};
