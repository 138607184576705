/* eslint-disable react-hooks/exhaustive-deps */

import { Form } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import {
  getUrlForWatermark,
  postFileSyncTask,
  updateQuantityScProduct,
  updateStatusSyncTask,
  updateSyncTask,
} from "../../../services/syncTaskService";
import { PARAMS_DEFAULT, PARAMS_LIMIT, TIME_PUBLISH } from "../../../utils/constant";
import { dateFormatYMDHm } from "../../../utils/date-time";
import { convertToTimeString, parseTimeSplitUnit, readFileAsBinary, useDebounce } from "../../../utils/helperFuncs";
import { useFetchData } from "./useFetchData";

export const useDetail = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const salesChannelId = searchParams.get("sales_channel_id");
  const [typeTimePublish, setTypeTimePublish] = useState();
  const [valueSearchProduct, setValueSearchProduct] = useState("");
  const [paramsProduct, setParamsProduct] = useState(PARAMS_DEFAULT);
  const [valueSearchDescription, setValueSearchDescription] = useState("");
  const [paramsDescription, setParamsDescription] = useState({ ...PARAMS_DEFAULT, sales_channel_ids: salesChannelId });
  const [paramsScProduct, setParamsScProduct] = useState(PARAMS_DEFAULT);
  const [valueSearchCategory, setValueSearchCategory] = useState("");
  const [paramsCategory, setParamsCategory] = useState({ id: salesChannelId, ...PARAMS_LIMIT });
  const [valueSearchTag, setValueSearchTag] = useState("");
  const [paramsTag, setParamsTag] = useState({ id: salesChannelId, ...PARAMS_LIMIT });
  const [selectCategory, setSelectedCategory] = useState([]);
  const [selectTag, setSelectedTag] = useState([]);

  const {
    salesChannelCategories,
    salesChannelTags,
    syncTaskDetail,
    salesChannels,
    descriptions,
    salesChannelProductSyncTasks,
    paginationDataScProduct,
    products,
    loadingSyncTags,
    loadingSyncCate,
    loadingProduct,
    loadingDescription,
    loadingSyncTaskDetail,
    loadingScProduct,
    refetchScProduct,
    refetchCategory,
    refetchTag,
    refetchSyncTask,
  } = useFetchData({
    id,
    salesChannelId,
    paramsProduct,
    paramsDescription,
    paramsScProduct,
    paramsCategory,
    paramsTag,
  });

  const optionsSalesChannels = salesChannels?.map((item) => ({
    value: item?.id,
    label: item?.domain,
  }));

  const optionsDescriptions = descriptions?.map((item) => ({
    value: item?.id,
    label: item?.title,
    item: item,
  }));

  const optionsSalesChanelSyncTags = (syncTaskDetail?.sales_channel_tag ?? [])
    ?.concat(salesChannelTags)
    ?.filter((item, index, self) => index === self?.findIndex((t) => t?.id === item?.id))
    ?.map((item) => ({
      value: item?.id,
      label: item?.name,
      tags: item,
    }));

  const optionsSalesChanelSyncCategory = (syncTaskDetail?.sales_channel_category ?? [])
    ?.concat(salesChannelCategories)
    ?.filter((item, index, self) => index === self?.findIndex((t) => t?.id === item?.id))
    ?.map((item) => ({
      value: item?.id,
      label: item?.name,
      categories: item,
    }));

  const optionsDataProduct = products?.map((item) => ({
    value: item?.id,
    label: item?.name,
    item: item,
  }));

  const debouncedQueryProduct = useDebounce(valueSearchProduct);

  const debouncedQueryDescription = useDebounce(valueSearchDescription);

  const debouncedQueryCategory = useDebounce(valueSearchCategory);

  const debouncedQueryTag = useDebounce(valueSearchTag);

  const handleChangePageScProduct = (pageNumber) => {
    setParamsScProduct({ ...paramsScProduct, page: pageNumber });
  };

  const handleChangePerPageScProduct = (pageSize) => {
    setParamsScProduct({ ...paramsScProduct, per_page: pageSize });
  };

  const handleSearchProduct = (value) => {
    setValueSearchProduct(value?.trim());
  };

  const handleSearchCategory = (value) => {
    setValueSearchCategory(value?.trim());
  };

  const handleSearchTag = (value) => {
    setValueSearchTag(value?.trim());
  };

  const handleSearchDescription = (value) => {
    setValueSearchDescription(value?.trim());
  };

  const handleChangeTypeTimePublish = (event) => {
    setTypeTimePublish(event?.target?.value);
  };

  const handleChangeDescription = (value, option) => {
    const formData = form.getFieldsValue();

    form.setFieldsValue({
      ...formData,
      content: option?.item?.content,
      price: option?.item?.price,
      sales_price: option?.item?.sales_price,
      sku_format: option?.item?.sku_format,
    });
  };

  const handleSelectProduct = async (value, option) => {
    await updateQuantityScProduct(Number(id), {
      sales_channel_sync_task_id: Number(salesChannelId),
      add_product_ids: [value],
    })
      .then((res) => {
        refetchScProduct();
        notifySuccess(res?.message);
      })
      .catch((error) => {
        refetchScProduct();
        notifyError(error?.toString());
      });
  };

  const handleUpdateStatus = async (status) => {
    await updateStatusSyncTask(id, { id: Number(id), status })
      .then((res) => {
        notifySuccess(res?.message);
        refetchSyncTask();
      })
      .catch((error) => {
        notifyError(error.toString());
      });
  };

  const handleDeleteScProduct = async (idItem) => {
    if (idItem) {
      await updateQuantityScProduct(Number(id), {
        sales_channel_sync_task_id: Number(id),
        remove_sc_product_ids: [idItem],
      })
        .then((res) => {
          refetchScProduct();
          notifySuccess(res?.message);
        })
        .catch((error) => {
          notifyError(error?.toString());
        });
    }
  };

  const handleFilterStatusScProduct = (value) => {
    setParamsScProduct({ ...paramsScProduct, status: value });
  };

  const handleUpdateSyncTask = async () => {
    form
      .validateFields()
      .then(async () => {
        const values = form?.getFieldsValue();
        const now = new Date();
        if (values?.watermark_url) {
          const binaryFiles = await Promise.all(
            values?.watermark_url?.fileList?.map((file) => readFileAsBinary(file.originFileObj))
          );
          await getUrlForWatermark({
            file_name: values?.watermark_url?.file?.name,
          })
            .then(async (res) => {
              const post_url = res?.data?.post_url;
              const file_url = res?.data?.file_url;
              await postFileSyncTask({ baseURL: post_url, binaryFiles: binaryFiles }).then(async () => {
                const updateData = {
                  id: Number(id),
                  sales_channel_id: Number(salesChannelId),
                  title: values?.title,
                  content: values?.content,
                  sku_format: values?.sku_format,
                  price: Number(values?.price),
                  sales_price: Number(values?.sales_price),
                  time_publish: typeTimePublish === TIME_PUBLISH.SELECT_TIME ? values?.time_publish : now.toISOString(),
                  delay_per_cost:
                    values?.delay_per_cost && convertToTimeString(values?.delay_per_cost, values?.unit_time),
                  sales_channel_category: selectCategory,
                  sales_channel_tag: selectTag,
                  status: values?.status,
                  description_id: values?.description_id,
                  watermark_url: file_url,
                };

                await updateSyncTask(id, updateData)
                  .then((res) => {
                    navigate("/sync-task");
                    notifySuccess(res?.message);
                  })
                  .catch((error) => {
                    notifyError(error.toString());
                  });
              });
            })
            .catch((error) => {
              notifyError(error.toString());
            });
        } else {
          const updateDataNoWaterMark = {
            id: Number(id),
            sales_channel_id: Number(salesChannelId),
            title: values?.title,
            content: values?.content,
            sku_format: values?.sku_format,
            price: Number(values?.price),
            sales_price: Number(values?.sales_price),
            time_publish: typeTimePublish === TIME_PUBLISH.SELECT_TIME ? values?.time_publish : now.toISOString(),
            delay_per_cost: values?.delay_per_cost && convertToTimeString(values?.delay_per_cost, values?.unit_time),
            sales_channel_category: selectCategory,
            sales_channel_tag: selectTag,
            status: values?.status,
            description_id: values?.description_id,
          };
          await updateSyncTask(id, updateDataNoWaterMark)
            .then((res) => {
              navigate("/sync-task");
              notifySuccess(res?.message);
            })
            .catch((error) => {
              notifyError(error.toString());
            });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setParamsProduct({ ...paramsProduct, query: valueSearchProduct });
  }, [debouncedQueryProduct]);

  useEffect(() => {
    setParamsDescription({ ...paramsDescription, query: valueSearchDescription });
  }, [debouncedQueryDescription]);

  useEffect(() => {
    setParamsCategory({ ...paramsCategory, query: valueSearchCategory });
  }, [debouncedQueryCategory]);

  useEffect(() => {
    setParamsTag({ ...paramsTag, query: valueSearchTag });
  }, [debouncedQueryTag]);

  useEffect(() => {
    if (syncTaskDetail) {
      form.setFieldsValue({
        content: syncTaskDetail?.content,
        price: syncTaskDetail?.price,
        description_id: syncTaskDetail?.description_id,
        sales_channel_id: syncTaskDetail?.sales_channel_id,
        sales_channel_tag: syncTaskDetail?.sales_channel_tag?.map((item) => item?.id),
        sales_channel_category: syncTaskDetail?.sales_channel_category?.map((item) => item?.id),
        sku_format: syncTaskDetail?.sku_format,
        title: syncTaskDetail?.title,
        time_publish: syncTaskDetail?.time_publish ? dayjs(syncTaskDetail?.time_publish, dateFormatYMDHm) : undefined,
        sales_price: syncTaskDetail?.sales_price,
        status: syncTaskDetail?.status,
        type_time_publish: syncTaskDetail?.time_publish ? TIME_PUBLISH.SELECT_TIME : TIME_PUBLISH.NOW,
        delay_per_cost: syncTaskDetail?.delay_per_cost
          ? parseTimeSplitUnit(syncTaskDetail?.delay_per_cost)?.time
          : undefined,
        unit_time: syncTaskDetail?.delay_per_cost
          ? parseTimeSplitUnit(syncTaskDetail?.delay_per_cost)?.unit
          : undefined,
      });
      setTypeTimePublish(syncTaskDetail?.time_publish ? TIME_PUBLISH.SELECT_TIME : TIME_PUBLISH.NOW);
    }
  }, [syncTaskDetail]);

  return {
    id,
    form,
    loading: loadingSyncTaskDetail || loadingSyncCate,
    loadingSyncTags,
    loadingSyncCate,
    loadingProduct,
    loadingDescription,
    syncTaskDetail,
    typeTimePublish,
    optionsSalesChannels,
    optionsDescriptions,
    optionsSalesChanelSyncTags,
    optionsSalesChanelSyncCategory,
    optionsDataProduct,
    paginationDataScProduct,
    salesChannelProductSyncTasks,
    loadingScProduct,
    salesChannelId,
    refetchCategory,
    setSelectedCategory,
    setSelectedTag,
    refetchTag,
    refetchScProduct,
    setParamsCategory,
    handleUpdateStatus,
    setParamsDescription,
    setParamsTag,
    handleSelectProduct,
    handleSearchDescription,
    handleSearchProduct,
    handleSearchCategory,
    handleSearchTag,
    handleChangeTypeTimePublish,
    handleChangeDescription,
    handleUpdateSyncTask,
    handleDeleteScProduct,
    handleChangePerPageScProduct,
    handleChangePageScProduct,
    handleFilterStatusScProduct,
  };
};
