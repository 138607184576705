// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .ant-modal-content {
  padding: 0;
}
.custom-modal .ant-modal-content .ant-modal-header {
  background-color: #fbfbfb;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.custom-modal .ant-modal-content .ant-modal-body {
  padding: 0 24px;
}
.custom-modal .ant-modal-content .ant-modal-footer {
  display: flex;
  justify-content: end;
  gap: 8px;
  padding: 0 24px 20px 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-modal/index.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;AAEI;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAAN;AAEI;EACE,eAAA;AAAN;AAGI;EACE,aAAA;EACA,oBAAA;EACA,QAAA;EACA,yBAAA;AADN","sourcesContent":[".custom-modal {\n  .ant-modal-content {\n    padding: 0;\n\n    .ant-modal-header {\n      background-color: #fbfbfb;\n      height: 60px;\n      display: flex;\n      align-items: center;\n      padding: 0 24px;\n    }\n    .ant-modal-body {\n      padding: 0 24px;\n    }\n\n    .ant-modal-footer {\n      display: flex;\n      justify-content: end;\n      gap: 8px;\n      padding: 0 24px 20px 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
