import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Pagination, Typography } from "antd";
import { useWindowWidth } from "../../hooks/apps/useWindowWidth";
import { CustomSelect } from "../custom-select/CustomSelect";
import "./index.scss";

export const CustomPagination = ({ total, limit, page, showSizeChanger = true, onChangePerPage, handleChangePage }) => {
  const windowWidth = useWindowWidth();

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };

  const handleChange = (page, pageSize) => {
    onChangePerPage && onChangePerPage(pageSize);
  };

  const startItem = (page - 1) * limit + 1;
  const endItem = Math.min(page * limit, total);

  return (
    <div className={`pagination-wrapper ${windowWidth > 576 ? "justify-between" : "justify-center"}`}>
      {showSizeChanger && windowWidth > 576 && (
        <div className="pagination-total">
          <Typography.Text>{`Showing ${startItem} to ${endItem} of ${total} entries`}</Typography.Text>
          <CustomSelect
            options={[
              { value: 25, label: "25 / page" },
              { value: 50, label: "50 / page" },
              { value: 100, label: "100 / page" },
              { value: 200, label: "200 / page" },
              { value: 500, label: "500 / page" },
            ]}
            defaultValue={limit}
            onChange={(value) => handleChange(page, value)}
            height={32}
          />
        </div>
      )}

      <div>
        <Pagination
          className="pagination-custom"
          total={total ?? 0}
          pageSize={limit ?? 0}
          current={page ?? 0}
          showQuickJumper={false}
          showSizeChanger={false}
          onChange={(pageNumber) => handleChangePage(pageNumber)}
          itemRender={itemRender}
        />
      </div>
    </div>
  );
};
