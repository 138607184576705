import { Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { CustomCard } from "../../../../../components/custom-card/CustomCard";
import { CustomCheckbox } from "../../../../../components/custom-checkbox/CustomCheckbox";
import { CustomInput } from "../../../../../components/custom-input/CustomInput";
import { CustomRadio } from "../../../../../components/custom-radio/CustomRadio";
import {
  CHECKBOX_DELETE_RENAME_RULE,
  DELETE_FROM_RENAME_RULE,
  DELETE_UNTIL_RENAME_RULE,
} from "../../../../../utils/constant";
import {
  optionsCheckboxDeleteRenameRule,
  optionsDeleteFromRenameRule,
  optionsDeleteUntilRenameRule,
} from "../../../../../utils/options";

export const DeleteRuleWidget = ({
  activeTab,
  valueOptionCheckedFrom,
  valueOptionCheckedUntil,
  handleChangeCheckedFrom,
  handleChangeCheckedUntil,
}) => {
  const [valueDisabledFrom, setValueDisabledFrom] = useState([]);
  const [valueDisabledUntil, setValueDisabledUntil] = useState([]);
  const [valueDisabledOptions, setValueDisabledOptions] = useState([]);

  const listPrefixOptionFrom = [
    {
      value: DELETE_FROM_RENAME_RULE.POSITION,
      children: (
        <Row>
          <Form.Item name="position" className="w-full">
            <CustomInput
              placeholder="Enter position"
              isNumber
              min={1}
              defaultValue={1}
              disabled={valueDisabledFrom.includes(DELETE_FROM_RENAME_RULE.POSITION)}
            />
          </Form.Item>
        </Row>
      ),
    },
    {
      value: DELETE_FROM_RENAME_RULE.DELIMITER,
      children: (
        <Row>
          <Form.Item name="delimiter_from" className="w-full">
            <CustomInput
              placeholder="Enter delimiter"
              disabled={valueDisabledFrom.includes(DELETE_FROM_RENAME_RULE.DELIMITER)}
            />
          </Form.Item>
        </Row>
      ),
    },
  ];

  const listPrefixOptionUntil = [
    {
      value: DELETE_UNTIL_RENAME_RULE.COUNT,
      children: (
        <Row>
          <Form.Item name="count" className="w-full">
            <CustomInput
              placeholder="Enter count"
              isNumber
              min={1}
              defaultValue={1}
              disabled={valueDisabledUntil.includes(DELETE_UNTIL_RENAME_RULE.COUNT)}
            />
          </Form.Item>
        </Row>
      ),
    },
    {
      value: DELETE_UNTIL_RENAME_RULE.DELIMITER,
      children: (
        <Row>
          <Form.Item name="delimiter_until" className="w-full">
            <CustomInput
              placeholder="Enter delimiter"
              disabled={valueDisabledUntil.includes(DELETE_UNTIL_RENAME_RULE.DELIMITER)}
            />
          </Form.Item>
        </Row>
      ),
    },
  ];

  const handleChangeDeleteCurrentName = (value) => {
    if (value?.includes(CHECKBOX_DELETE_RENAME_RULE.DELETE_CURRENT_NAME)) {
      setValueDisabledFrom([DELETE_FROM_RENAME_RULE.DELIMITER, DELETE_FROM_RENAME_RULE.POSITION]);
      setValueDisabledUntil([
        DELETE_UNTIL_RENAME_RULE.COUNT,
        DELETE_UNTIL_RENAME_RULE.DELIMITER,
        DELETE_UNTIL_RENAME_RULE.TILL_THE_END,
      ]);
      setValueDisabledOptions([
        CHECKBOX_DELETE_RENAME_RULE.DO_NOT_REMOVE_DELIMITERS,
        CHECKBOX_DELETE_RENAME_RULE.RIGHT_TO_LEFT,
      ]);
    } else {
      setValueDisabledFrom([]);
      setValueDisabledUntil([]);
      setValueDisabledOptions([]);
    }
  };

  useEffect(() => {
    setValueDisabledFrom([]);
    setValueDisabledUntil([]);
    setValueDisabledOptions([]);
  }, [activeTab]);

  return (
    <div className="flex flex-col gap-4">
      <Row gutter={[16]} align="stretch">
        <Col span={12}>
          <CustomCard
            title="From"
            content={
              <Form.Item name="from" className="w-full">
                <CustomRadio
                  options={optionsDeleteFromRenameRule}
                  listPrefixOption={listPrefixOptionFrom}
                  className="w-full"
                  checked={valueOptionCheckedFrom}
                  valueDisabled={valueDisabledFrom}
                  onChange={(value) => handleChangeCheckedFrom(value)}
                />
              </Form.Item>
            }
          />
        </Col>
        <Col span={12}>
          <CustomCard
            title="Until"
            content={
              <Form.Item name="until" className="w-full">
                <CustomRadio
                  options={optionsDeleteUntilRenameRule}
                  listPrefixOption={listPrefixOptionUntil}
                  className="w-full"
                  checked={valueOptionCheckedUntil}
                  valueDisabled={valueDisabledUntil}
                  onChange={(value) => handleChangeCheckedUntil(value)}
                />
              </Form.Item>
            }
          />
        </Col>
      </Row>
      <Row>
        <Form.Item name="options" className="w-full">
          <CustomCheckbox
            options={optionsCheckboxDeleteRenameRule}
            valueDisabled={valueDisabledOptions}
            onChange={(value) => handleChangeDeleteCurrentName(value)}
          />
        </Form.Item>
      </Row>
    </div>
  );
};
