import { useIsWidthMobile } from "../../hooks/apps/useIsWidthMobile";
import { REACT_APP_VERSION_APP } from "../../utils/constant";
import "./index.scss";

export const VersionManager = () => {
  const isWidthMobile = useIsWidthMobile();
  const version = REACT_APP_VERSION_APP;

  return !isWidthMobile && <div className="version-manager">Ver {version?.toString()}</div>;
};
