import api from "./api";
import { headersRequest } from "./authService";

// fetch categories
export const getCategories = async (paramsCategory) => {
  try {
    const response = await api.get(`/categories`, {
      params: paramsCategory,
      headers: headersRequest(),
    });
    const data = response?.data?.data;
    return {
      categories: data?.categories,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching categories");
  }
};

// fetch detail category
export const getDetailCategory = async (id) => {
  try {
    const response = await api.get(`/categories/${id}`, {
      headers: headersRequest(),
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching category");
  }
};

// delete category
export const deleteCategory = async (productId) => {
  try {
    const response = await api.delete(`/categories/${productId}`, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching products");
  }
};

// update categories
export const updateCategory = async (id, updateData) => {
  try {
    const response = await api.put(`/categories/${id}`, updateData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating category");
  }
};

// create categories
export const createCategory = async (createData) => {
  try {
    const response = await api.post(`/categories`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error create category");
  }
};

// fetch children categories
export const getChildrenCategories = async (id) => {
  try {
    const response = await api.get(`/categories/${id}/children`, {
      headers: headersRequest(),
    });
    const data = response?.data?.data;
    return {
      children: data?.categories,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching categories");
  }
};

// delete bulk categories
export const deleteBulkCategories = async (requestDelete) => {
  try {
    const response = await api.delete(`/categories/bulk-delete`, {
      headers: headersRequest(),
      data: requestDelete,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete bulk categories");
  }
};
