import { Col, Popover } from "antd";
import { useState } from "react";

export const CustomPopover = ({ component, title, content, width = 400, onClose, ...rest }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
    if (!newOpen && onClose) {
      onClose();
    }
  };

  return (
    <Popover
      content={<div className={`w-[${width}px] flex flex-col px-2 pb-2 gap-4`}>{content}</div>}
      title={<Col className="flex items-center justify-between px-2 py-1">{title}</Col>}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      {...rest}
    >
      {component}
    </Popover>
  );
};
