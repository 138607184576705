// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list-page .content-page {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.custom-list-page .content-page .header-card .flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.custom-list-page .content-page .content-card {
  margin-top: 18px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/custom-list-page/index.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,mBAAA;EACA,2CAAA;AAAJ;AAEM;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAR;AAGI;EACE,gBAAA;AADN","sourcesContent":[".custom-list-page {\n  .content-page {\n    background-color: #fff;\n    border-radius: 12px;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n    .header-card {\n      .flex-column {\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n      }\n    }\n    .content-card {\n      margin-top: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
