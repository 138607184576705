import api from "./api";
import { headersRequest } from "./authService";

export const getSyncTasksRule = async (paramsSyncTasksRule) => {
  try {
    const response = await api.get("/sync-task-rules", {
      params: paramsSyncTasksRule,
      headers: headersRequest(),
    });
    const data = response?.data?.data;

    return {
      syncTaskRules: data?.sync_task_rules,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching sales channel sync tasks");
  }
};

// delete sync task rule
export const deleteSyncTaskRule = async (syncTaskRuleId) => {
  try {
    const response = await api.delete(`/sync-task-rules/${syncTaskRuleId}`, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete sync task rule");
  }
};

// add rename rule
export const addRenameRule = async (createData) => {
  try {
    const response = await api.post(`/sync-task-rules`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error add rename rule");
  }
};
