import { useQuery } from "react-query";
import { getDescriptions } from "../../../services/descriptionService";
import { getProducts } from "../../../services/productService";
import {
  getSalesChannelSyncCategories,
  getSalesChannelSyncTags,
  getSalesChannels,
} from "../../../services/salesChannelService";
import { getDetailSyncTask, getScProductSyncTask } from "../../../services/syncTaskService";
import { PARAMS_GET_MAX } from "../../../utils/constant";
import { optionUseQuery } from "../../../utils/options";

export const useFetchData = ({
  id,
  salesChannelId,
  paramsProduct,
  paramsDescription,
  paramsScProduct,
  paramsCategory,
  paramsTag,
}) => {
  const {
    data: syncTaskDetail,
    isFetching: loadingSyncTaskDetail,
    refetch: refetchSyncTask,
  } = useQuery(["getDetailSyncTask", id], ({ queryKey }) => getDetailSyncTask(queryKey[1]), optionUseQuery);

  const { data: salesChanelData } = useQuery(
    ["getSalesChannels", PARAMS_GET_MAX],
    ({ queryKey }) => getSalesChannels(queryKey[1]),
    optionUseQuery
  );

  const { data: descriptionData, isFetching: loadingDescription } = useQuery(
    ["getDescriptions", paramsDescription],
    ({ queryKey }) => getDescriptions(queryKey[1]),
    optionUseQuery
  );

  const {
    data: salesChanelSyncTagsData,
    isFetching: loadingSyncTags,
    refetch: refetchTag,
  } = useQuery(
    ["getSalesChannelSyncTags", { id: salesChannelId, params: paramsTag }],
    ({ queryKey }) => getSalesChannelSyncTags(queryKey[1]),
    optionUseQuery
  );

  const {
    data: salesChanelSyncCategoriesData,
    isFetching: loadingSyncCate,
    refetch: refetchCategory,
  } = useQuery(
    ["getSalesChannelSyncCategories", { id: salesChannelId, params: paramsCategory }],
    ({ queryKey }) => getSalesChannelSyncCategories(queryKey[1]),
    optionUseQuery
  );

  const {
    data: scProductData,
    refetch: refetchScProduct,
    isFetching: loadingScProduct,
  } = useQuery(
    ["getScProductSyncTask", { id: id, params: paramsScProduct }],
    ({ queryKey }) => getScProductSyncTask(queryKey[1]),
    optionUseQuery
  );

  const { data: dataProducts, isFetching: loadingProduct } = useQuery(
    ["getProducts", paramsProduct],
    ({ queryKey }) => getProducts(queryKey[1]),
    optionUseQuery
  );

  return {
    salesChannelCategories: salesChanelSyncCategoriesData?.sales_channel_categories,
    salesChannelTags: salesChanelSyncTagsData?.sales_channel_tags,
    syncTaskDetail,
    salesChannels: salesChanelData?.salesChannels,
    descriptions: descriptionData?.descriptions,
    salesChannelProductSyncTasks: scProductData?.salesChannelProductSyncTasks,
    paginationDataScProduct: scProductData?.paginationData,
    products: dataProducts?.products,
    loadingScProduct,
    loadingSyncTags,
    loadingSyncCate,
    loadingProduct,
    loadingDescription,
    loadingSyncTaskDetail,
    refetchCategory,
    refetchScProduct,
    refetchTag,
    refetchSyncTask,
  };
};
