import { Avatar, Space, Tag, Typography } from "antd";
import { useQuery } from "react-query";
import { SvgIcon } from "../../../assets/icons";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";
import { getCountScProductFailed, retryScProductsFailed } from "../../../services/syncTaskService";
import { STATUS_COLORS } from "../../../utils/constant";
import { formatDateTime } from "../../../utils/date-time";
import { optionUseQuery } from "../../../utils/options";

export const useTableScProduct = ({ id, handleDeleteScProduct, refetchScProduct }) => {
  const { data: countScProductFailed } = useQuery(
    ["getCountScProductFailed", id],
    ({ queryKey }) => getCountScProductFailed(queryKey[1]),
    optionUseQuery
  );

  const handleRetryScProductsFailed = async () => {
    await retryScProductsFailed({
      sales_channel_sync_task_ids: [Number(id)],
    })
      .then((res) => {
        refetchScProduct();
        notifySuccess(res?.message);
      })
      .catch((error) => {
        refetchScProduct();
        notifyError(error?.toString());
      });
  };

  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      width: 75,
      align: "center",
      render: (text, record, index) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Thumb</Typography.Text>,
      key: "thumb",
      align: "center",
      width: 100,
      render: (_, record) => {
        return <Avatar src={record?.thumb?.url} size={40} />;
      },
    },
    {
      title: <Typography.Text>Name</Typography.Text>,
      key: "name",
      render: (_, record) => {
        return <Typography.Text>{record?.name || "- -"}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Status</Typography.Text>,
      key: "status",
      align: "center",
      render: (_, record) => {
        return record?.status ? (
          <Tag color={STATUS_COLORS[record?.status]}>{record?.status?.toUpperCase()}</Tag>
        ) : (
          "- -"
        );
      },
    },
    {
      title: <Typography.Text>Publish Schedule</Typography.Text>,
      key: "status",
      align: "center",
      width: 120,
      render: (_, record) => {
        return (
          <Typography.Text>
            {record?.publish_schedule ? formatDateTime(record?.publish_schedule) : "- -"}
          </Typography.Text>
        );
      },
    },
    {
      key: "action",
      align: "center",
      render: (_, record) => {
        return (
          <Space size="middle">
            <CustomPopconfirm
              title="Delete"
              component={<SvgIcon.IconDelete />}
              description="Are you sure to delete this?"
              onConfirm={() => handleDeleteScProduct(record?.id)}
            />
          </Space>
        );
      },
    },
  ];
  return { countScProductFailed: countScProductFailed || 0, columns, handleRetryScProductsFailed };
};
