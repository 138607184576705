import { Space, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";
import { STATUS_COLORS } from "../../../utils/constant";
import { getHostName } from "../../../utils/helperFuncs";

export const useTable = ({ handleDeleteSyncTask }) => {
  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      width: 100,
      align: "center",
      render: (_, record) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Title</Typography.Text>,
      key: "title",
      render: (_, record) => {
        return <Typography.Text>{record?.title || "- -"}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Sales Channel</Typography.Text>,
      key: "domain",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record?.sales_channel?.domain ? getHostName(record?.sales_channel?.domain) : "- - "}
          </Typography.Text>
        );
      },
    },
    {
      title: <Typography.Text>Status</Typography.Text>,
      key: "status",
      align: "center",
      render: (_, record) => {
        return <Tag color={STATUS_COLORS[record?.status]}>{record?.status?.toUpperCase()}</Tag>;
      },
    },
    {
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => {
        return (
          <Space size="middle">
            <Link to={`/sync-task/${record?.id}?sales_channel_id=${record?.sales_channel_id}`}>
              <SvgIcon.IconEdit />
            </Link>
            <CustomPopconfirm
              title="Delete"
              component={<SvgIcon.IconDelete />}
              description="Are you sure to delete this?"
              onConfirm={() => handleDeleteSyncTask(record?.id)}
            />
          </Space>
        );
      },
    },
  ];
  return { columns };
};
