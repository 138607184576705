import { Form } from "antd";
import { useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { changePassword } from "../../../services/usersService";

export const useChangePassword = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const handleChangePassword = async () => {
    await form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue();
        const changeData = {
          old_password: values.old_password,
          new_password: values.new_password,
        };
        await changePassword(id, changeData)
          .then((res) => {
            notifySuccess(res?.message);
            form.resetFields();
          })
          .catch((error) => {
            notifyError(error.toString());
          });
      })
      .catch(() => {});
  };

  return { form, handleChangePassword };
};
