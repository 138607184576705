import { Layout, Menu } from "antd";
import { jwtDecode } from "jwt-decode";
import { Link, useLocation } from "react-router-dom";
import { SvgIcon } from "../../assets/icons";
import logo from "../../assets/images/logo.png";
import { useIsWidthMobile } from "../../hooks/apps/useIsWidthMobile";
import { getToken } from "../../services/authService";
import { isAllRoles } from "../../utils/boolean";
import { LABEL_WEBSITE_APP } from "../../utils/constant";
import { getDefaultSelectedKey } from "../../utils/router-link";

const { Sider } = Layout;

export const SideBar = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const token = getToken();
  const decodedToken = jwtDecode(token);
  const isWidthMobile = useIsWidthMobile();

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const items = [
    getItem(
      <span className="text-[17px] font-semibold text-[#4361ee]">Catalogs</span>,
      "1",
      <SvgIcon.IconCatalogs className="w-5 " />,
      [
        getItem(<Link to="/products">Products</Link>, "2", <SvgIcon.IconMenuProduct className="w-5" />),
        getItem(<Link to="/descriptions">Descriptions</Link>, "3", <SvgIcon.IconMenuCategories className="w-5" />),
        getItem(<Link to="/categories">Categories</Link>, "4", <SvgIcon.IconMenuDescription className="w-5" />),
        getItem(<Link to="/sales-channel">Sales Channel</Link>, "5", <SvgIcon.IconMenuSalesChannel className="w-5" />),
        getItem(<Link to="/sync-task">Sync Task</Link>, "6", <SvgIcon.IconMenuSyncTask className="w-[19px]" />),
        isAllRoles(decodedToken.role) &&
          getItem(<Link to="/users">Users</Link>, "7", <SvgIcon.IconMenuUser className="w-5" />),
      ]
    ),
  ];

  return (
    !isWidthMobile && (
      <Sider theme="light" width="300" collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo-sidebar">
          {!collapsed ? (
            <div className="flex gap-2 items-center">
              <div className="w-10">
                <img src={logo} alt="" />
              </div>
              <span className="text-xl font-semibold">{LABEL_WEBSITE_APP}</span>
            </div>
          ) : (
            <div className="w-10">
              <img src={logo} alt="" />
            </div>
          )}
        </div>
        <Menu
          defaultSelectedKeys={[getDefaultSelectedKey(location.pathname)]}
          defaultOpenKeys={["1"]}
          mode="inline"
          items={items}
        />
      </Sider>
    )
  );
};
