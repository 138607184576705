import { Checkbox, Form, Typography } from "antd";
import logo from "../../assets/images/logo.png";
import { CustomButton } from "../../components/custom-button/CustomButton";
import { CustomInput } from "../../components/custom-input/CustomInput";
import { CustomNameFormItem } from "../../components/custom-title/CustomNameFormItem";
import { useLogin } from "../../hooks/auths/useLogin";
import { checkRulesNotNull } from "../../utils/validator";

export const LoginMobileSection = ({ setIsAuthenticated }) => {
  const { form, handleKeyDown, handleLogin } = useLogin({ setIsAuthenticated });

  return (
    <div className="p-[24px] pt-[25%]">
      <Form form={form} initialValues={{ remember: true }} autoComplete="off" onKeyDown={handleKeyDown}>
        <div className="flex">
          <img src={logo} alt="" height={30} width={64} />
          <div className="flex flex-col">
            <Typography.Text className="text-[28px] font-bold">Login</Typography.Text>
            <Typography.Text className="text-[18px] font-semibold">Welcome to SPG Manager</Typography.Text>
          </div>
        </div>
        <div className="w-full mt-10">
          <CustomNameFormItem title="Username" required />
          <Form.Item name="username" className="form-item" rules={[checkRulesNotNull()]}>
            <CustomInput placeholder="Enter Username" height={48} className="" />
          </Form.Item>
          <div className="mt-3">
            <CustomNameFormItem title="Password" required />
            <Form.Item name="password" className="form-item" rules={[checkRulesNotNull()]}>
              <CustomInput placeholder="Enter Password" height={48} type="password" isPassword />
            </Form.Item>
          </div>
          <Form.Item name="remember_me" valuePropName="checked">
            <Checkbox>
              <Typography.Text className="cursor-pointer">Remember Me</Typography.Text>
            </Checkbox>
          </Form.Item>

          <CustomButton
            type="primary"
            title="Login"
            className="mt-10"
            onClick={() => handleLogin()}
            width="100%"
            height={48}
          />
        </div>
      </Form>
    </div>
  );
};
