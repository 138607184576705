// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-collapse-header {
  display: flex;
  align-items: center !important;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-collapse/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF","sourcesContent":[".ant-collapse-header {\n  display: flex;\n  align-items: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
