import { Checkbox, Col, Form, Typography } from "antd";
import ImageBgLogin from "../../assets/images/image-bg-login.webp";
import { CustomButton } from "../../components/custom-button/CustomButton";
import { CustomInput } from "../../components/custom-input/CustomInput";
import { CustomNameFormItem } from "../../components/custom-title/CustomNameFormItem";
import { useLogin } from "../../hooks/auths/useLogin";
import { checkRulesNotNull } from "../../utils/validator";
import "./index.scss";

export const LoginSection = ({ setIsAuthenticated }) => {
  const { form, handleKeyDown, handleLogin } = useLogin({ setIsAuthenticated });

  return (
    <Col className="login-page">
      <Form form={form} initialValues={{ remember: true }} autoComplete="off" onKeyDown={handleKeyDown}>
        <div className="card-login">
          <div className="left-section">
            <img src={ImageBgLogin} alt="Login Illustration" />
          </div>
          <div className="right-section">
            <div className="flex flex-col">
              <Typography.Text className="title">Login</Typography.Text>
              <Typography.Text className="sub-title">Welcome to SPG Manager</Typography.Text>
            </div>
            <div className="w-full">
              <CustomNameFormItem title="Username" required />
              <Form.Item name="username" className="form-item" rules={[checkRulesNotNull()]}>
                <CustomInput placeholder="Enter Username" height={48} className="" />
              </Form.Item>
              <div className="mt-3">
                <CustomNameFormItem title="Password" required />
                <Form.Item name="password" className="form-item" rules={[checkRulesNotNull()]}>
                  <CustomInput placeholder="Enter Password" height={48} type="password" isPassword />
                </Form.Item>
              </div>
              <Form.Item name="remember_me" valuePropName="checked">
                <Checkbox>
                  <Typography.Text className="cursor-pointer">Remember Me</Typography.Text>
                </Checkbox>
              </Form.Item>

              <CustomButton
                type="primary"
                title="Login"
                className="mt-10"
                onClick={() => handleLogin()}
                width="100%"
                height={48}
              />
            </div>
            <div></div>
          </div>
        </div>
      </Form>
    </Col>
  );
};
