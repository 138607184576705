import api from "./api";
import { headersRequest } from "./authService";

// fetch descriptions
export const getDescriptions = async (paramsDescription) => {
  try {
    const response = await api.get(`/descriptions`, {
      params: paramsDescription,
      headers: headersRequest(),
    });
    const data = response?.data?.data;
    return {
      descriptions: data?.descriptions,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching descriptions");
  }
};

// fetch sales description
export const getDetailDescription = async (id) => {
  try {
    const response = await api.get(`/descriptions/${id}`, {
      headers: headersRequest(),
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching description");
  }
};

// update description
export const updateDescription = async (id, updateData) => {
  try {
    const response = await api.put(`/descriptions/${id}`, updateData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating description");
  }
};

// create description
export const createDescription = async (createData) => {
  try {
    const response = await api.post(`/descriptions`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating description");
  }
};

// delete descriptions
export const deleteDescription = async (productId) => {
  try {
    const response = await api.delete(`/descriptions/${productId}`, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching descriptions");
  }
};

// delete bulk description
export const deleteBulkDescription = async (requestDelete) => {
  try {
    const response = await api.delete(`/descriptions/bulk-delete`, {
      headers: headersRequest(),
      data: requestDelete,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete bulk description");
  }
};
