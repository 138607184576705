// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: #ffffff;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 900;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/layouts/navbar/index.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,YAAA;EACA,6CAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF","sourcesContent":[".navbar {\n  background-color: #ffffff;\n  height: 60px;\n  width: 100%;\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  z-index: 900;\n  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
