import { Form, Spin } from "antd";
import { CustomButton } from "../../../../../components/custom-button/CustomButton";
import CustomModal from "../../../../../components/custom-modal/CustomModal";
import { CustomTab } from "../../../../../components/custom-tabs/CustomTabs";
import { useAddRenameRule } from "../../../../../hooks/sync-task-rule/useAddRenameRule";
import { TAB_RENAME_RULE } from "../../../../../utils/constant";
import { DeleteRuleWidget } from "./DeleteRuleWidget";
import { InsertRuleWidget } from "./InsertRuleWidget";
import { RemoveRuleWidget } from "./RemoveRuleWidget";
import { ReplaceRuleWidget } from "./ReplaceRuleWidget";

export const AddRenameRule = ({ refetch }) => {
  const {
    form,
    visible,
    loading,
    activeTab,
    initialValues,
    valueOptionChecked,
    valueOptionCheckedFrom,
    valueOptionCheckedUntil,
    handleChangeCheckedOptions,
    handleChangeCheckedFrom,
    handleChangeCheckedUntil,
    setVisible,
    handleOnChangeTab,
    handleAddRenameRule,
  } = useAddRenameRule({ refetch });

  const listItemTabs = [
    {
      key: TAB_RENAME_RULE.INSERT,
      label: "Insert",
      children: (
        <InsertRuleWidget
          valueOptionChecked={valueOptionChecked}
          handleChangeCheckedOptions={handleChangeCheckedOptions}
        />
      ),
    },
    {
      key: TAB_RENAME_RULE.DELETE,
      label: "Delete",
      children: (
        <DeleteRuleWidget
          activeTab={activeTab}
          valueOptionCheckedFrom={valueOptionCheckedFrom}
          valueOptionCheckedUntil={valueOptionCheckedUntil}
          handleChangeCheckedFrom={handleChangeCheckedFrom}
          handleChangeCheckedUntil={handleChangeCheckedUntil}
        />
      ),
    },
    {
      key: TAB_RENAME_RULE.REMOVE,
      label: "Remove",
      children: <RemoveRuleWidget />,
    },
    {
      key: TAB_RENAME_RULE.REPLACE,
      label: "Replace",
      children: <ReplaceRuleWidget />,
    },
  ];

  return (
    <Form form={form} initialValues={initialValues}>
      <CustomModal
        component={<CustomButton title="Add rule" onClick={() => setVisible(true)} />}
        loading={loading}
        title="Add Rename Rule"
        width="660px"
        open={visible}
        onClose={() => {
          setVisible(false);
          form.resetFields();
        }}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => handleAddRenameRule()}
        labelConfirm="Add Rule"
        content={
          <Spin spinning={loading}>
            <CustomTab
              type="card"
              items={listItemTabs}
              onChange={(value) => handleOnChangeTab(value)}
              activeKey={activeTab}
            />
          </Spin>
        }
      />
    </Form>
  );
};
