import { Typography } from "antd";

export const CustomNameFormItem = ({ title, required = false }) => {
  return (
    <Typography.Text className="font-[500] text-[14px] mb-1 mr-2 leading-5 block">
      {title}
      {required && <span className="text-[#e7515a] ml-0.5 font-bold">*</span>}
    </Typography.Text>
  );
};
