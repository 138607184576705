/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getToken } from "../../../services/authService";
import { getCategories } from "../../../services/categoryService";
import { getUsers } from "../../../services/usersService";
import { PARAMS_DEFAULT, PARAMS_GET_MAX } from "../../../utils/constant";
import { getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useFilter = ({ paramsProduct, setParamsProduct }) => {
  const GET_QUERY = getQuery();
  const token = getToken();
  const [form] = Form.useForm();
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [valueSearchCategory, setValueSearchCategory] = useState("");
  const [paramsCategory, setParamsCategory] = useState(PARAMS_GET_MAX);
  const [valueSearchUsers, setValueSearchUsers] = useState("");
  const [paramsUsers, setParamsUser] = useState(PARAMS_GET_MAX);

  const decodedToken = jwtDecode(token);

  const initialValues = {
    category_ids: paramsProduct?.category_ids,
    created_at_from: paramsProduct?.created_at_from
      ? dayjs(paramsProduct?.created_at_from).tz(dayjs.tz.guess(), true)
      : undefined,
    created_at_to: paramsProduct?.created_at_to
      ? dayjs(paramsProduct?.created_at_to).tz(dayjs.tz.guess(), true)
      : undefined,
    user_ids: paramsProduct?.user_ids,
  };

  const debouncedQueryCategory = useDebounce(valueSearchCategory);
  const debouncedQueryUsers = useDebounce(valueSearchUsers);

  const { data: dataCategories, isFetching: loadingCategory } = useQuery(
    ["getCategories", paramsCategory],
    ({ queryKey }) => getCategories(queryKey[1]),
    optionUseQuery
  );

  const { data: dataUsers, isFetching: loadingUsers } = useQuery(
    ["getUsers", paramsUsers],
    ({ queryKey }) => getUsers(queryKey[1]),
    optionUseQuery
  );

  const optionsCategories = dataCategories?.categories?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const optionsUsers = dataUsers?.users?.map((item) => ({ value: item?.id, label: item?.name }));

  const handleSearchCategory = (valueSearch) => {
    setValueSearchCategory(valueSearch);
  };

  const handleSearchUsers = (valueSearch) => {
    setValueSearchUsers(valueSearch);
  };

  const handleClearFilter = () => {
    setVisiblePopover(false);
    setParamsProduct({
      ...PARAMS_DEFAULT,
      query: paramsProduct?.query,
      sort_order: paramsProduct?.sort_order,
      sort_by: paramsProduct?.sort_by,
      page: PARAMS_DEFAULT.page,
    });

    form.setFieldsValue({
      category_ids: undefined,
      created_at_from: undefined,
      created_at_to: undefined,
      user_ids: undefined,
    });
  };

  const handleFilter = () => {
    const formValue = form.getFieldsValue();
    setParamsProduct({
      ...paramsProduct,
      category_ids: formValue?.category_ids ?? GET_QUERY.category_ids,
      created_at_from: formValue?.created_at_from?.toISOString() ?? GET_QUERY.created_at_from,
      created_at_to: formValue?.created_at_to?.toISOString() ?? GET_QUERY.created_at_to,
      user_ids: formValue?.user_ids ?? GET_QUERY.user_ids,
    });
    setVisiblePopover(false);
  };

  useEffect(() => {
    setParamsCategory({ ...paramsCategory, query: valueSearchCategory });
  }, [debouncedQueryCategory]);

  useEffect(() => {
    setParamsUser({ ...paramsUsers, query: valueSearchUsers });
  }, [debouncedQueryUsers]);

  return {
    form,
    visiblePopover,
    optionsCategories,
    optionsUsers,
    loadingCategory,
    loadingUsers,
    decodedToken,
    initialValues,
    setParamsUser,
    setParamsCategory,
    handleSearchUsers,
    handleClearFilter,
    handleFilter,
    setVisiblePopover,
    handleSearchCategory,
  };
};
