// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-mobile-layout {
  background-color: white;
  height: 68px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 20px;
}
.menu-mobile-layout .menu-toggle {
  display: block;
  padding: 10px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.menu-mobile-layout .menu-list {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-mobile-layout .menu-list .menu-item {
  height: 68px;
  cursor: pointer;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #4361ee !important;
}
.menu-mobile-layout .menu-list .active {
  background-color: rgba(67, 97, 238, 0.0823529412);
}
.menu-mobile-layout.collapsed .menu-list {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/layouts/menu-mobile/index.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,4CAAA;AACF;AACE;EACE,cAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AACJ;AAEE;EACE,UAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAEI;EACE,YAAA;EACA,eAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,yBAAA;AAAN;AAEI;EACE,iDAAA;AAAN;AAIE;EACE,aAAA;AAFJ","sourcesContent":[".menu-mobile-layout {\n  background-color: white;\n  height: 68px;\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 20px;\n\n  .menu-toggle {\n    display: block;\n    padding: 10px;\n    border: none;\n    cursor: pointer;\n    text-align: center;\n  }\n\n  .menu-list {\n    padding: 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    .menu-item {\n      height: 68px;\n      cursor: pointer;\n      font-size: 11px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-direction: column;\n      color: #4361ee !important;\n    }\n    .active {\n      background-color: #4361ee15;\n    }\n  }\n\n  &.collapsed .menu-list {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
