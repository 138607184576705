import { Form, Row } from "antd";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useChangePassword } from "../../../hooks/users/change-password/useChangePassword";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { checkPasswordMatch, checkRulesNotNull } from "../../../utils/validator";

export const ChangePasswordSection = () => {
  const { form, handleChangePassword } = useChangePassword();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Change Password"
        actionButton={
          <>
            <CustomButton
              title="Change"
              type="primary"
              icon={<SvgIcon.IconImportFile />}
              onClick={() => handleChangePassword()}
            />
          </>
        }
        contentCard={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Old password" required />
                  <Form.Item name="old_password" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter name" isPassword />
                  </Form.Item>
                </Row>
                <Row style={{ display: "flex", gap: "2%" }}>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="New Password" required />
                    <Form.Item name="new_password" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomInput placeholder="Enter new password" width="100%" isPassword />
                    </Form.Item>
                  </div>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Confirm password" required />
                    <Form.Item
                      name="re_new_password"
                      className="w-full"
                      rules={[checkRulesNotNull(), ({ getFieldValue }) => checkPasswordMatch({ getFieldValue })]}
                    >
                      <CustomInput placeholder="Enter confirm password" width="100%" isPassword />
                    </Form.Item>
                  </div>
                </Row>
              </>
            }
          />
        }
      />
    </Form>
  );
};
