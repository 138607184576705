/* eslint-disable no-useless-escape */

export const passwordValidator = (_, value) => {
  if (!value) {
    return Promise.reject(new Error("Please input your password!"));
  }
  if (value?.length < 8) {
    return Promise.reject(new Error("Password must be at least 8 characters long!"));
  }
  if (!/[A-Z]/.test(value)) {
    return Promise.reject(new Error("Password must contain at least one uppercase letter!"));
  }
  if (!/[@!#\$%\^\&*\)\(+=._-]/.test(value)) {
    return Promise.reject(new Error("Password must contain at least one special character!"));
  }
  return Promise.resolve();
};

export const validateEmailFormat = () => ({
  validator(_, value) {
    if (!value || /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("This is not valid E-mail!"));
  },
});

export const checkRulesNotNull = () => {
  return {
    required: true,
    message: `This field is required!`,
  };
};

export const checkPasswordMatch = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue("new_password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("The two passwords do not match!"));
  },
});

export const validateNumber = (rule, value) => {
  if (!value) {
    return Promise.reject("Please enter a valid number");
  }
  const numberPattern = /^\d+(\.\d+)?$/;
  if (!numberPattern.test(value.replace(/,/g, ""))) {
    return Promise.reject("Please enter a valid number");
  }
  return Promise.resolve();
};
