import { Table } from "antd";

export const CustomTable = ({ columns, data, isScrollTable = true, ...rest }) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      size="large"
      scroll={{
        x: 767,
        y: isScrollTable ? 500 : null,
      }}
      {...rest}
    />
  );
};
