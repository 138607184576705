// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor-pointer {
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.flex-center-between {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  width: 100%;
}

.tag-default {
  color: #4361ee;
  background: rgba(67, 97, 238, 0.0823529412);
  border-color: #5a78ff;
}`, "",{"version":3,"sources":["webpack://./src/styles/shorten.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,WAAA;AACF;;AAEA;EACE,cAAA;EACA,2CAAA;EACA,qBAAA;AACF","sourcesContent":[".cursor-pointer {\n  cursor: pointer;\n}\n\n.flex-column {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.flex-center-between {\n  display: flex;\n  align-items: center !important;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.tag-default {\n  color: #4361ee;\n  background: #4361ee15;\n  border-color: #5a78ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
