/* eslint-disable no-useless-escape */

export const getDefaultSelectedKey = (pathname) => {
  const routes = [
    { pattern: /^\/products$/, key: "2" },
    { pattern: /^\/products\/[^\/]+$/, key: "2" },
    { pattern: /^\/descriptions$/, key: "3" },
    { pattern: /^\/descriptions\/[^\/]+$/, key: "3" },
    { pattern: /^\/categories$/, key: "4" },
    { pattern: /^\/categories\/[^\/]+$/, key: "4" },
    { pattern: /^\/sales-channel$/, key: "5" },
    { pattern: /^\/sales-channel\/[^\/]+$/, key: "5" },
    { pattern: /^\/sync-task$/, key: "6" },
    { pattern: /^\/sync-task\/[^\/]+$/, key: "6" },
    { pattern: /^\/users$/, key: "7" },
    { pattern: /^\/users\/[^\/]+$/, key: "7" },
  ];

  for (const route of routes) {
    if (route.pattern.test(pathname)) {
      return route.key;
    }
  }

  return "1";
};
