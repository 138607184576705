import { Col, Form, Row } from "antd";
import { useState } from "react";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../../components/custom-input/CustomInput";
import CustomModal from "../../../../components/custom-modal/CustomModal";
import { CustomSelect } from "../../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { PLATFORM } from "../../../../utils/constant";
import { ensureHttps } from "../../../../utils/helperFuncs";
import { optionsPlatform } from "../../../../utils/options";
import { checkRulesNotNull } from "../../../../utils/validator";

export const ImportSalesChannel = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleImportSalesChannel = () => {
    form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue();
        const domain = ensureHttps(formData?.domain);
        if (formData?.platform === PLATFORM.WOO_COMMERCE) {
          window.location.href = `${domain}/wc-auth/v1/authorize/?app_name=xxxx&user_id=1&return_url=${window.location.href}l&callback_url=https://api.omni.tuntran.com/v0/sales-channels/sync&scope=read_write`;
        } else {
          setVisible(false);
        }
      })
      .catch((error) => {});
  };
  return (
    <Form form={form}>
      <CustomModal
        component={
          <CustomButton
            title="Import Sales Channel"
            icon={<SvgIcon.IconImport className="w-5" />}
            type="primary"
            onClick={() => setVisible(true)}
          />
        }
        title="Import Sales Channel"
        width={550}
        open={visible}
        onClose={() => {
          form.resetFields();
          setVisible(false);
        }}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        onOk={() => handleImportSalesChannel()}
        labelConfirm="Import"
        content={
          <Col span={24} className="flex-column">
            <Row>
              <CustomNameFormItem title="Domain" required />
              <Form.Item name="domain" className="w-full" rules={[checkRulesNotNull()]}>
                <CustomInput placeholder="Enter domain" />
              </Form.Item>
            </Row>
            <Row>
              <CustomNameFormItem title="Platform" required />
              <Form.Item name="platform" className="w-full" rules={[checkRulesNotNull()]}>
                <CustomSelect options={optionsPlatform} placeholder="Select platform" />
              </Form.Item>
            </Row>
          </Col>
        }
      />
    </Form>
  );
};
