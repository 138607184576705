import { Form, Row } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomEditor } from "../../../components/custom-editor/CustomEditor";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useCreate } from "../../../hooks/descriptions/create-description/useCreate";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { PARAMS_GET_MAX } from "../../../utils/constant";
import { checkRulesNotNull } from "../../../utils/validator";

export const CreateDescriptionSection = () => {
  const {
    form,
    loadingSalesChannel,
    salesChannelOptions,
    setParamsSalesChannel,
    handleCreateDescription,
    handleSearchSalesChannel,
  } = useCreate();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Description Create"
        actionButton={
          <>
            <Link to={"/descriptions"}>
              <CustomButton title="Back" type="default" />
            </Link>
            <CustomButton
              title="Create"
              type="primary"
              icon={<SvgIcon.IconImportFile />}
              onClick={() => handleCreateDescription()}
            />
          </>
        }
        contentCard={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Domain" required />
                  <Form.Item name="salesChannel_id" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelect
                      placeholder="Enter domain"
                      options={salesChannelOptions}
                      width="100%"
                      showSearch
                      loading={loadingSalesChannel}
                      onSearch={(value) => {
                        handleSearchSalesChannel(value);
                      }}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          setParamsSalesChannel(PARAMS_GET_MAX);
                        }
                      }}
                      filterOption={false}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Title" required />
                  <Form.Item name="title" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter title" options={salesChannelOptions} width="100%" />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Content" required />
                  <Form.Item name="content" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomEditor />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="SKU Format" required />
                  <Form.Item name="sku_format" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter sku format" />
                  </Form.Item>
                </Row>
                <Row style={{ display: "flex", gap: "2%" }}>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Price" />
                    <Form.Item name="price" className="w-full">
                      <CustomInput placeholder="Enter price" isCurrency />
                    </Form.Item>
                  </div>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Sales Price" />
                    <Form.Item name="sales_price" className="w-full">
                      <CustomInput placeholder="Enter sales price" isCurrency />
                    </Form.Item>
                  </div>
                </Row>
              </>
            }
          />
        }
      />
    </Form>
  );
};
