import { Space, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";
import { ROLE_COLOR } from "../../../utils/constant";

export const useTable = ({ handleDeleteUsers }) => {
  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Name</Typography.Text>,
      key: "name",
      render: (_, record) => {
        return <Typography.Text>{record?.name || "- -"}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Email</Typography.Text>,
      key: "email",
      render: (_, record) => {
        return <Typography.Text>{record?.email || "- -"}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Username</Typography.Text>,
      key: "email",
      render: (_, record) => {
        return <Typography.Text>{record?.username || "- -"}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Roles</Typography.Text>,
      align: "center",
      key: "role",
      render: (_, record) => {
        return (
          <Typography.Text>
            {<Tag color={ROLE_COLOR[record?.role]}>{record?.role?.replace("_", " ").toUpperCase()}</Tag>}
          </Typography.Text>
        );
      },
    },
    {
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/users/${record?.id}`}>
            <SvgIcon.IconEdit />
          </Link>
          <CustomPopconfirm
            title="Delete"
            component={<SvgIcon.IconDelete />}
            description="Are you sure to delete this?"
            onConfirm={() => handleDeleteUsers(record?.id)}
          />
        </Space>
      ),
    },
  ];
  return { columns };
};
