import { Form } from "antd";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../components/custom-notify/CustomNotify";
import apiServices from "../../services/api";
import { setToken } from "../../services/authService";

export const useLogin = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleLogin = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        await apiServices
          .post("/users/login", {
            username: values?.username?.trim(),
            password: values?.password?.trim(),
          })
          .then((res) => {
            const token = res?.data?.data?.token;
            setToken(token);
            setIsAuthenticated(true);
            navigate("/");
            notifySuccess(res?.data?.message);
            if (values.remember_me) {
              const decodedToken = jwtDecode(token);
              localStorage.setItem("userID", decodedToken.id);
            } else {
              localStorage.removeItem("userID");
            }
          })
          .catch((err) => {
            notifyError(err?.response?.data?.message, err?.response?.data?.error);
          });
      })
      .catch((error) => {});
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
  return { form, handleKeyDown, handleLogin };
};
