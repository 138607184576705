import { Radio, Space } from "antd";

export const CustomRadio = ({
  options,
  direction = "vertical",
  isColumn = false,
  checked,
  listPrefixOption = [],
  valueDisabled = [],
  ...rest
}) => {
  const prefixChildren = listPrefixOption?.find((item) => item?.value === checked)?.children;

  return (
    <Radio.Group {...rest} checked={checked}>
      <Space direction={direction} className={`grid grid-cols-${isColumn ? 2 : 1} gap-4 w-full`}>
        {options?.map((item) => (
          <Radio key={item?.value} value={item?.value} disabled={valueDisabled?.includes(item?.value)}>
            {item?.label}
            {item?.value === checked && prefixChildren}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};
