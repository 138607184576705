import axios from "axios";
import api from "./api";
import { headersRequest } from "./authService";

// fetch list product
export const getProducts = async (paramsProduct) => {
  try {
    const response = await api.get(`/products`, {
      params: paramsProduct,
      headers: headersRequest(),
    });

    const data = response?.data?.data;
    return {
      products: data?.products,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching products");
  }
};

// delete product
export const deleteProduct = async (productId) => {
  try {
    const response = await api.delete(`/products/${productId}`, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete product");
  }
};

// delete bulk product
export const deleteBulkProduct = async (requestDelete) => {
  try {
    const response = await api.delete(`/products/bulk-delete`, {
      headers: headersRequest(),
      data: requestDelete,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete bulk product");
  }
};

// post file
export const postFileProduct = async (requestPostFile) => {
  try {
    const response = await axios.put(requestPostFile?.baseURL, requestPostFile?.binaryFiles[0], {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error post file product");
  }
};

// get url
export const getURLProduct = async (requestGetPostURL) => {
  try {
    const response = await api.post(`/products/get-url`, requestGetPostURL, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error get url product");
  }
};

// uploads
export const uploadsProduct = async (requestUpload) => {
  try {
    const response = await api.post(`/products/uploads`, requestUpload, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error uploads product");
  }
};

// uploads
export const updateBulkProduct = async (requestUpdate) => {
  try {
    const response = await api.put(`/products/bulk-update`, requestUpdate, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error update bulk product");
  }
};

// update title product
export const updateTitleProduct = async (requestUpdate) => {
  try {
    const response = await api.put(`/products/${requestUpdate?.id}`, requestUpdate, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error update bulk product");
  }
};
