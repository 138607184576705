import { Form, Row } from "antd";
import { CustomCheckbox } from "../../../../../components/custom-checkbox/CustomCheckbox";
import { CustomInput } from "../../../../../components/custom-input/CustomInput";
import { CustomRadio } from "../../../../../components/custom-radio/CustomRadio";
import { CustomNameFormItem } from "../../../../../components/custom-title/CustomNameFormItem";
import { WHERE_INSERT_RENAME_RULE } from "../../../../../utils/constant";
import { optionsRightToLeftRenameRule, optionsWhereInsertRenameRule } from "../../../../../utils/options";

export const InsertRuleWidget = ({ valueOptionChecked, handleChangeCheckedOptions }) => {
  const listPrefixOption = [
    {
      value: WHERE_INSERT_RENAME_RULE.POSITION,
      children: (
        <Row>
          <Form.Item name="position" className="w-full">
            <CustomInput placeholder="Enter position" isNumber min={1} defaultValue={1} />
          </Form.Item>
          <Form.Item name="rightToLeft" className="w-full">
            <CustomCheckbox options={optionsRightToLeftRenameRule} />
          </Form.Item>
        </Row>
      ),
    },
    {
      value: WHERE_INSERT_RENAME_RULE.AFTER_TEXT,
      children: (
        <Row>
          <Form.Item name="afterText" className="w-full">
            <CustomInput placeholder="Enter after text" />
          </Form.Item>
        </Row>
      ),
    },
    {
      value: WHERE_INSERT_RENAME_RULE.BEFORE_TEXT,
      children: (
        <Row>
          <Form.Item name="beforeText" className="w-full">
            <CustomInput placeholder="Enter before text" />
          </Form.Item>
        </Row>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <Row>
        <CustomNameFormItem title="Insert" />
        <Form.Item name="insert" className="w-full">
          <CustomInput placeholder="Enter Title" />
        </Form.Item>
      </Row>
      <Row>
        <CustomNameFormItem title="Where" />
        <Form.Item name="options" className="w-full">
          <CustomRadio
            options={optionsWhereInsertRenameRule}
            isColumn
            className="w-full"
            checked={valueOptionChecked}
            listPrefixOption={listPrefixOption}
            onChange={(value) => handleChangeCheckedOptions(value)}
          />
        </Form.Item>
      </Row>
    </div>
  );
};
