import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomCountSelected } from "../../../components/custom-count-selected/CustomCountSelected";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomPagination } from "../../../components/custom-pagination/CustomPagination";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../components/custom-table/CustomTable";
import { useIsWidthMobile } from "../../../hooks/apps/useIsWidthMobile";
import { useList } from "../../../hooks/users/list-users/useList";
import { useTable } from "../../../hooks/users/list-users/useTable";
import { CustomListPage } from "../../../layouts/custom-list-page/CustomListPage";
import { optionsOrderby } from "../../../utils/options";

export const ListUsersSection = () => {
  const {
    users,
    loading,
    paramsUsers,
    selectedRowKeys,
    paginationData,
    valuesSearch,
    handleDeleteUsers,
    handleDeleteSelectedUsers,
    onSelectChange,
    handleSearchUsers,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
  } = useList();

  const { columns } = useTable({ handleDeleteUsers });
  const isWidthMobile = useIsWidthMobile();

  return (
    <CustomListPage
      title="Users"
      headerCard={
        <div className="flex-column">
          <div className="flex justify-between gap-2 flex-wrap">
            <Link to="/users/create">
              <CustomButton title="Create Users" type="primary" icon={<SvgIcon.IconCreate className="w-5" />} />
            </Link>
            <CustomButton
              title="Delete Selected"
              type="cancel"
              onClick={() => handleDeleteSelectedUsers()}
              icon={<SvgIcon.IconDelete className="w-[18px] text-[#e7515a]" />}
            />
          </div>
          <Row className="flex-center-between">
            <Col span={isWidthMobile ? 24 : 16} className={`${isWidthMobile ? "mb-2" : ""}`}>
              <CustomInput
                placeholder="Search users"
                prefix={<SearchOutlined />}
                value={valuesSearch}
                onChange={(value) => handleSearchUsers(value)}
              />
            </Col>
            <Col span={isWidthMobile ? 24 : 4} className="flex justify-end gap-2">
              <CustomSelect
                options={optionsOrderby}
                defaultValue={paramsUsers.sort_order}
                width="100%"
                onChange={(value) => handleSortOrder(value)}
              />
            </Col>
          </Row>
          {selectedRowKeys?.length > 0 && <CustomCountSelected count={selectedRowKeys?.length} />}
        </div>
      }
      contentCard={
        <>
          <CustomTable
            columns={columns}
            data={users}
            rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }}
            loading={loading}
            rowKey={(record) => record.id}
          />
          {users?.length > 0 && (
            <CustomPagination
              total={paginationData?.total ?? 0}
              limit={paginationData?.limit ?? 0}
              page={paginationData?.page ?? 0}
              handleChangePage={(pageNumber) => handleChangePage(pageNumber)}
              onChangePerPage={(pageSize) => handleChangePerPage(pageSize)}
            />
          )}
        </>
      }
    />
  );
};
