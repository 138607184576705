import { Form } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../components/custom-notify/CustomNotify";
import { addRenameRule } from "../../services/syncTaskRuleService";
import {
  CHECKBOX_DELETE_RENAME_RULE,
  DELETE_FROM_RENAME_RULE,
  DELETE_UNTIL_RENAME_RULE,
  RADIO_REPLACE_RENAME_RULE,
  TAB_RENAME_RULE,
  WHERE_INSERT_RENAME_RULE,
} from "../../utils/constant";

export const useAddRenameRule = ({ refetch }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(TAB_RENAME_RULE.INSERT);
  const [valueOptionChecked, setValueOptionChecked] = useState();
  const [valueOptionCheckedFrom, setValueOptionCheckedFrom] = useState();
  const [valueOptionCheckedUntil, setValueOptionCheckedUntil] = useState();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    options: WHERE_INSERT_RENAME_RULE.PREFIX,
    from: DELETE_FROM_RENAME_RULE.POSITION,
    until: DELETE_UNTIL_RENAME_RULE.COUNT,
    occurrences_left: RADIO_REPLACE_RENAME_RULE.ALL,
  };

  const handleChangeCheckedOptions = (value) => {
    setValueOptionChecked(value.target.value);
  };

  const handleChangeCheckedFrom = (value) => {
    setValueOptionCheckedFrom(value.target.value);
  };

  const handleChangeCheckedUntil = (value) => {
    setValueOptionCheckedUntil(value.target.value);
  };

  const handleOnChangeTab = (value) => {
    setActiveTab(value);
    setValueOptionChecked();
    setValueOptionCheckedFrom();
    setValueOptionCheckedUntil();
    form.resetFields();
  };

  const handleCheckExist = (valueCheck, value, res) => {
    if (valueCheck === value) {
      return res;
    }
    return undefined;
  };

  const handleAddRenameRule = async () => {
    await form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const formData = form.getFieldsValue();

        let createData = { sync_task_id: Number(id), type: activeTab };
        if (activeTab === TAB_RENAME_RULE.INSERT) {
          createData = {
            ...createData,
            config: {
              Insert: formData.insert || "",
              Position: handleCheckExist(formData?.options, WHERE_INSERT_RENAME_RULE.POSITION, formData.position || 1),
              "After text": handleCheckExist(
                formData?.options,
                WHERE_INSERT_RENAME_RULE.AFTER_TEXT,
                formData.afterText || ""
              ),
              Prefix: handleCheckExist(formData?.options, WHERE_INSERT_RENAME_RULE.PREFIX, true),
              "Before text": handleCheckExist(
                formData?.options,
                WHERE_INSERT_RENAME_RULE.BEFORE_TEXT,
                formData.beforeText || ""
              ),
              Suffix: handleCheckExist(formData?.options, WHERE_INSERT_RENAME_RULE.SUFFIX, true),
              "Replace current name": handleCheckExist(
                formData?.options,
                WHERE_INSERT_RENAME_RULE.REPLACE_CURRENT_NAME,
                true
              ),
              "Right-to-left": handleCheckExist(
                formData?.options,
                WHERE_INSERT_RENAME_RULE.POSITION,
                Array.isArray(formData?.rightToLeft) ? formData?.rightToLeft[0] : formData?.rightToLeft || false
              ),
            },
          };
        }

        if (activeTab === TAB_RENAME_RULE.DELETE) {
          if (formData?.options?.includes(CHECKBOX_DELETE_RENAME_RULE.DELETE_CURRENT_NAME)) {
            delete formData.from;
            delete formData.until;
          }

          createData = {
            ...createData,
            config: {
              From: {
                Position: handleCheckExist(formData?.from, DELETE_FROM_RENAME_RULE.POSITION, formData?.position || 1),
                Delimiter: handleCheckExist(
                  formData?.from,
                  DELETE_FROM_RENAME_RULE.DELIMITER,
                  formData.delimiter_from || ""
                ),
              },
              Until: {
                Count: handleCheckExist(formData?.until, DELETE_UNTIL_RENAME_RULE.COUNT, formData.count || 1),
                Delimiter: handleCheckExist(
                  formData?.until,
                  DELETE_UNTIL_RENAME_RULE.DELIMITER,
                  formData.delimiter_until || ""
                ),
                "Till the end": handleCheckExist(formData?.until, DELETE_UNTIL_RENAME_RULE.TILL_THE_END, true),
              },
              ...(Array.isArray(formData?.options)
                ? formData?.options?.reduce((acc, item) => {
                    acc[item] = true;
                    return acc;
                  }, {})
                : {}),
            },
          };
        }

        if (activeTab === TAB_RENAME_RULE.REMOVE) {
          createData = {
            ...createData,
            config: {
              Remove: formData.remove || "",
              ...(formData?.occurrences_left ? { [formData.occurrences_left]: true } : {}),
              ...(Array.isArray(formData?.occurrences_right)
                ? formData?.occurrences_right?.reduce((acc, item) => {
                    acc[item] = true;
                    return acc;
                  }, {})
                : {}),
            },
          };
        }

        if (activeTab === TAB_RENAME_RULE.REPLACE) {
          createData = {
            ...createData,
            config: {
              Find: formData.find || "",
              Replace: formData.replace || "",
              ...(formData?.occurrences_left ? { [formData.occurrences_left]: true } : {}),
              ...(Array.isArray(formData?.occurrences_right)
                ? formData?.occurrences_right?.reduce((acc, item) => {
                    acc[item] = true;
                    return acc;
                  }, {})
                : {}),
            },
          };
        }

        await addRenameRule(createData)
          .then((res) => {
            form.resetFields();
            setVisible(false);
            refetch();
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error?.toString());
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (visible) {
      setActiveTab(TAB_RENAME_RULE.INSERT);
    }
  }, [visible]);

  return {
    form,
    visible,
    loading,
    activeTab,
    valueOptionChecked,
    valueOptionCheckedFrom,
    valueOptionCheckedUntil,
    initialValues,
    setVisible,
    handleChangeCheckedOptions,
    handleChangeCheckedFrom,
    handleChangeCheckedUntil,
    handleOnChangeTab,
    handleAddRenameRule,
  };
};
