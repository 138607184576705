import { Form } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { getSalesChannelDetail, updateSalesChannel } from "../../../services/salesChannelService";
import { getUsers } from "../../../services/usersService";
import { PARAMS_DEFAULT, ROLE_USERS } from "../../../utils/constant";
import { optionUseQuery } from "../../../utils/options";

export const useDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [paramsUsers, setParamsUser] = useState({ ...PARAMS_DEFAULT, roles: ROLE_USERS.STAFF });

  const { data: salesReturnDetail } = useQuery(
    ["getSalesChannelDetail", { id }],
    ({ queryKey }) => getSalesChannelDetail(queryKey[1].id),
    optionUseQuery
  );

  const { data: dataUsers, isFetching: loadingUsers } = useQuery(
    ["getUsers", paramsUsers],
    ({ queryKey }) => getUsers(queryKey[1]),
    optionUseQuery
  );

  const optionsUsers = dataUsers?.users?.map((item) => ({ value: item?.id, label: item?.name }));

  const handleSearchUsers = (value) => {
    setParamsUser({ ...paramsUsers, query: value });
  };

  const handleUpdateSalesChanel = async () => {
    await form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue();
        const updateData = {
          id: Number(id),
          domain: values?.domain,
          api_key: values?.api_key,
          api_token: values?.api_token,
          status: values?.status,
          platform: values?.platform,
          assign_user_ids: values?.assign_user_ids,
        };
        await updateSalesChannel(id, updateData)
          .then((res) => {
            navigate("/sales-channel");
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error.toString());
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (salesReturnDetail) {
      form.setFieldsValue({
        domain: salesReturnDetail.domain,
        api_key: salesReturnDetail.api_key,
        api_token: salesReturnDetail.api_token,
        status: salesReturnDetail.status,
        platform: salesReturnDetail.platform,
        assign_user_ids: salesReturnDetail.assign_users?.map((item) => item?.id),
      });
    }
  }, [salesReturnDetail, form]);

  return { form, optionsUsers, loadingUsers, setParamsUser, handleSearchUsers, handleUpdateSalesChanel };
};
