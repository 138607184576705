/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSalesChannels } from "../../../services/salesChannelService";
import { PARAMS_GET_MAX } from "../../../utils/constant";
import { getHostName, getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useFilter = ({ paramsDescription, setParamsDescription }) => {
  const [form] = Form.useForm();
  const GET_QUERY = getQuery();

  const [visiblePopover, setVisiblePopover] = useState(false);
  const [valueSearchSalesChannel, setValueSearchSalesChannel] = useState("");
  const [paramsSalesChannel, setParamsSalesChannel] = useState(PARAMS_GET_MAX);

  const initialValues = {
    created_at_from: paramsDescription?.created_at_from
      ? dayjs(paramsDescription?.created_at_from).tz(dayjs.tz.guess(), true)
      : undefined,
    created_at_to: paramsDescription?.created_at_to
      ? dayjs(paramsDescription?.created_at_to).tz(dayjs.tz.guess(), true)
      : undefined,
    sales_channel_ids: paramsDescription?.sales_channel_ids,
  };

  const debouncedQuerySalesChannel = useDebounce(valueSearchSalesChannel);

  const { data: dataSalesChannels, isFetching: loadingSalesChannel } = useQuery(
    ["getSalesChannels", paramsSalesChannel],
    ({ queryKey }) => getSalesChannels(queryKey[1]),
    optionUseQuery
  );

  const optionSalesChannel = dataSalesChannels?.salesChannels?.map((item) => ({
    value: item?.id,
    label: getHostName(item?.domain),
  }));

  const handleSearchSalesChannel = (value) => {
    setValueSearchSalesChannel(value);
  };

  const handleClearFilter = () => {
    form.resetFields();
    setVisiblePopover(false);
    setParamsDescription({
      ...PARAMS_GET_MAX,
      query: paramsDescription?.query,
      sort_order: paramsDescription?.sort_order,
      sort_by: paramsDescription?.sort_by,
      page: PARAMS_GET_MAX.page,
    });

    form.setFieldsValue({
      created_at_from: undefined,
      created_at_to: undefined,
      sales_channel_ids: undefined,
    });
  };

  const handleFilter = () => {
    const formValue = form.getFieldsValue();
    setParamsDescription({
      ...paramsDescription,
      created_at_from: formValue?.created_at_from?.toISOString() ?? GET_QUERY.created_at_from,
      created_at_to: formValue?.created_at_to?.toISOString() ?? GET_QUERY.created_at_to,
      sales_channel_ids: formValue?.sales_channel_ids ?? GET_QUERY.sales_channel_ids,
    });
    setVisiblePopover(false);
  };

  useEffect(() => {
    setParamsSalesChannel({ ...paramsSalesChannel, query: valueSearchSalesChannel });
  }, [debouncedQuerySalesChannel]);

  return {
    form,
    visiblePopover,
    optionSalesChannel,
    loadingSalesChannel,
    initialValues,
    setParamsSalesChannel,
    handleFilter,
    handleSearchSalesChannel,
    handleClearFilter,
    setVisiblePopover,
  };
};
