import { Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";
import { getHostName } from "../../../utils/helperFuncs";

export const useTable = ({ handleDeleteDescription }) => {
  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Title</Typography.Text>,
      key: "title",
      render: (_, record) => {
        return <Typography.Text>{record?.title || "- -"}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Sales Channel</Typography.Text>,
      key: "salesChannel",
      align: "center",
      width: "20%",
      render: (_, record) => {
        return <Typography.Text>{record?.domain ? getHostName(record?.domain) : "- -"}</Typography.Text>;
      },
    },
    {
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/descriptions/${record?.id}`}>
            <SvgIcon.IconEdit />
          </Link>
          <CustomPopconfirm
            title="Delete"
            component={<SvgIcon.IconDelete />}
            description="Are you sure to delete this?"
            onConfirm={() => handleDeleteDescription(record?.id)}
          />
        </Space>
      ),
    },
  ];
  return {
    columns,
  };
};
