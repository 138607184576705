/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { getCategories } from "../../../services/categoryService";
import { updateBulkProduct } from "../../../services/productService";
import { getUsers } from "../../../services/usersService";
import { PARAMS_DEFAULT, TYPE_SELECTION } from "../../../utils/constant";
import { optionUseQuery } from "../../../utils/options";

export const useBulkEdit = ({ paramsProduct, selectedRowKeys, refetch, setSelectedRowKeys }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paramsNewOwner, setParamsNewOwner] = useState(PARAMS_DEFAULT);
  const [paramsRemoveOwner, setParamsRemoveOwner] = useState(PARAMS_DEFAULT);
  const [paramsNewCategory, setParamsNewCategory] = useState(PARAMS_DEFAULT);
  const [paramsRemoveCategory, setParamsRemoveCategory] = useState(PARAMS_DEFAULT);

  const { data: dataNewCategory, isFetching: loadingNewCategory } = useQuery(
    ["getCategories", paramsNewCategory],
    ({ queryKey }) => getCategories(queryKey[1]),
    optionUseQuery
  );

  const { data: dataRemoveCategory, isFetching: loadingRemoveCategory } = useQuery(
    ["getCategories", paramsRemoveCategory],
    ({ queryKey }) => getCategories(queryKey[1]),
    optionUseQuery
  );

  const { data: dataNewOwner, isFetching: loadingNewOwner } = useQuery(
    ["getUsers", paramsNewOwner],
    ({ queryKey }) => getUsers(queryKey[1]),
    optionUseQuery
  );

  const { data: dateRemoveOwner, isFetching: loadingRemoveOwner } = useQuery(
    ["getUsers", paramsRemoveOwner],
    ({ queryKey }) => getUsers(queryKey[1]),
    optionUseQuery
  );

  const optionsNewOwner = dataNewOwner?.users?.map((i) => ({
    value: i?.id,
    label: i?.name,
  }));

  const optionsRemoveOwner = dateRemoveOwner?.users?.map((i) => ({
    value: i?.id,
    label: i?.name,
  }));

  const optionsNewCategory = dataNewCategory?.categories?.map((i) => ({
    value: i?.id,
    label: i?.name,
  }));

  const optionsRemoveCategory = dataRemoveCategory?.categories?.map((i) => ({
    value: i?.id,
    label: i?.name,
  }));

  const handleSearchRemoveOwner = (value) => {
    setParamsRemoveOwner({ ...paramsRemoveOwner, query: value });
  };

  const handleSearchNewOwner = (value) => {
    setParamsNewOwner({ ...paramsNewOwner, query: value });
  };

  const handleSearchRemoveCategory = (value) => {
    setParamsRemoveCategory({ ...paramsRemoveOwner, query: value });
  };

  const handleSearchNewCategory = (value) => {
    setParamsNewCategory({ ...paramsNewOwner, query: value });
  };

  const handleEditBulkProduct = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const values = form.getFieldsValue();
        const { add_category_ids, remove_category_ids, add_user_ids, remove_user_ids } = values || {};

        const commonData = {
          add_category_ids,
          remove_category_ids,
          add_user_ids,
          remove_user_ids,
        };

        const requestPayload =
          values?.apply === TYPE_SELECTION.ALL
            ? { filter: paramsProduct, ...commonData }
            : { product_ids: selectedRowKeys, ...commonData };

        await updateBulkProduct(requestPayload)
          .then((res) => {
            refetch();
            setVisible(false);
            form.resetFields();
            notifySuccess(res?.message);
            setSelectedRowKeys([]);
          })
          .catch((error) => {
            notifyError(error?.toString());
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    form.setFieldsValue({
      apply: selectedRowKeys?.length === 0 ? TYPE_SELECTION.ALL : TYPE_SELECTION.SELECTED,
    });
  }, [selectedRowKeys]);

  return {
    form,
    loading,
    visible,
    optionsNewCategory,
    optionsRemoveCategory,
    optionsNewOwner,
    optionsRemoveOwner,
    loadingRemoveOwner,
    loadingNewOwner,
    loadingRemoveCategory,
    loadingNewCategory,
    setParamsNewOwner,
    setParamsNewCategory,
    setParamsRemoveCategory,
    setParamsRemoveOwner,
    setVisible,
    handleSearchRemoveOwner,
    handleSearchNewOwner,
    handleSearchNewCategory,
    handleSearchRemoveCategory,
    handleEditBulkProduct,
  };
};
