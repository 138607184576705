import { Col, Form, Row, Spin } from "antd";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import CustomModal from "../../../../components/custom-modal/CustomModal";
import { CustomRadio } from "../../../../components/custom-radio/CustomRadio";
import { CustomSelectTag } from "../../../../components/custom-select-tag/CustomSelectTag";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useBulkEdit } from "../../../../hooks/products/list-product/useBulkEdit";
import { PARAMS_DEFAULT, TYPE_SELECTION } from "../../../../utils/constant";
import { optionsSelection } from "../../../../utils/options";

export const EditSelected = ({ paramsProduct, selectedRowKeys, refetch, setSelectedRowKeys }) => {
  const {
    form,
    loading,
    visible,
    optionsNewCategory,
    optionsRemoveCategory,
    optionsRemoveOwner,
    loadingRemoveOwner,
    optionsNewOwner,
    loadingNewOwner,
    loadingNewCategory,
    loadingRemoveCategory,
    setParamsNewOwner,
    setParamsNewCategory,
    setParamsRemoveCategory,
    setParamsRemoveOwner,
    setVisible,
    handleSearchNewOwner,
    handleSearchRemoveOwner,
    handleEditBulkProduct,
    handleSearchNewCategory,
    handleSearchRemoveCategory,
  } = useBulkEdit({
    paramsProduct,
    selectedRowKeys,
    refetch,
    setSelectedRowKeys,
  });

  return (
    <Form form={form}>
      <CustomModal
        component={
          <CustomButton
            title="Edit Selected"
            type="secondary"
            onClick={() => setVisible(true)}
            icon={<SvgIcon.IconEdit className="w-4 text-[#fff]" />}
          />
        }
        title={"Bulk Edit Product"}
        width={550}
        open={visible}
        onClose={() => {
          form.resetFields();
          setVisible(false);
        }}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        buttonOk={
          <CustomButton key="submit" type="primary" loading={loading} onClick={handleEditBulkProduct} title="Update" />
        }
        content={
          <Spin spinning={loading}>
            <Col span={24} className="flex-column">
              <Row className="flex-center-between">
                <Col span={8}>
                  <CustomNameFormItem title="Apply" />
                </Col>
                <Col span={16}>
                  <Form.Item name="apply" className="w-full">
                    <CustomRadio
                      options={optionsSelection}
                      valueDisabled={[selectedRowKeys?.length === 0 && TYPE_SELECTION.SELECTED]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-center-between">
                <Col span={8}>
                  <CustomNameFormItem title="Add Categories" />
                </Col>
                <Col span={16}>
                  <Form.Item name="add_category_ids" className="w-full">
                    <CustomSelectTag
                      placeholder="Search Categories"
                      width="100%"
                      options={optionsNewCategory}
                      showSearch
                      loading={loadingNewCategory}
                      onSearch={(value) => {
                        handleSearchNewCategory(value);
                      }}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          setParamsNewCategory(PARAMS_DEFAULT);
                        }
                      }}
                      filterOption={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-center-between">
                <Col span={8}>
                  <CustomNameFormItem title="Remove Categories" />
                </Col>
                <Col span={16}>
                  <Form.Item name="remove_category_ids" className="w-full">
                    <CustomSelectTag
                      placeholder="Select remove category"
                      width="100%"
                      options={optionsRemoveCategory}
                      type="error"
                      showSearch
                      loading={loadingRemoveCategory}
                      onSearch={(value) => {
                        handleSearchRemoveCategory(value);
                      }}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          setParamsRemoveCategory(PARAMS_DEFAULT);
                        }
                      }}
                      filterOption={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-center-between">
                <Col span={8}>
                  <CustomNameFormItem title="New owner" />
                </Col>
                <Col span={16}>
                  <Form.Item name="add_user_ids" className="w-full">
                    <CustomSelectTag
                      placeholder="Select new owner"
                      options={optionsNewOwner}
                      width="100%"
                      showSearch
                      loading={loadingNewOwner}
                      onSearch={(value) => {
                        handleSearchNewOwner(value);
                      }}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          setParamsNewOwner(PARAMS_DEFAULT);
                        }
                      }}
                      filterOption={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-center-between">
                <Col span={8}>
                  <CustomNameFormItem title="Remove owner" />
                </Col>
                <Col span={16}>
                  <Form.Item name="remove_user_ids" className="w-full">
                    <CustomSelectTag
                      placeholder="Select remove owner"
                      options={optionsRemoveOwner}
                      width="100%"
                      showSearch
                      type="error"
                      loading={loadingRemoveOwner}
                      onSearch={(value) => {
                        handleSearchRemoveOwner(value);
                      }}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          setParamsRemoveOwner(PARAMS_DEFAULT);
                        }
                      }}
                      filterOption={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Spin>
        }
      />
    </Form>
  );
};
