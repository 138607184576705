import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment";

export const dateFormatMDY = "MM/DD/YYYY";

export const dateFormatYMDHm = "YYYY/MM/DD HH:mm";

export const dateFormatUTC = "YYYY-MM-DDTHH:mm:ss[Z]";

export const formatDateUTC = (date) => {
  return moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export const formatDateTime = (isoString) => {
  return new Date(formatDateUTC(isoString))
    .toLocaleString("vi-VN", {
      hour12: false,
      timeZone: "UTC",
    })
    .replace(",", "");
};

dayjs.extend(utc);
dayjs.extend(timezone);
