import { Dropdown, Menu } from "antd";

export const CustomDropDown = ({ component, items }) => {
  const menu = (
    <Menu>
      {items.map(item => (
        <Menu.Item key={item.key} icon={item.icon} onClick={item?.onClick}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      {component}
    </Dropdown>
  );
};
