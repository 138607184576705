import { Form, Row } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomDateTimePicker } from "../../../components/custom-date-time-picker/CustomDateTimePicker";
import { CustomEditor } from "../../../components/custom-editor/CustomEditor";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomRadio } from "../../../components/custom-radio/CustomRadio";
import { CustomSelectTag } from "../../../components/custom-select-tag/CustomSelectTag";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useDetail } from "../../../hooks/sync-task/detail-sync-task/useDetail";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { PARAMS_DEFAULT, PARAMS_LIMIT, SYNC_TASK_STATUS, TIME_PUBLISH } from "../../../utils/constant";
import { optionsSyncTaskStatus, optionsTimePublish, optionsUnitTime } from "../../../utils/options";
import { checkRulesNotNull } from "../../../utils/validator";
import { CreateCategory } from "./widget/CreateCategory";
import { CreateTag } from "./widget/CreateTag";
import { SyncOptions } from "./widget/SyncOptions";
import { SyncProduct } from "./widget/SyncProduct";

export const DetailSyncTaskSection = () => {
  const {
    id,
    form,
    loadingSyncTags,
    loadingSyncCate,
    loadingProduct,
    loadingDescription,
    syncTaskDetail,
    typeTimePublish,
    optionsSalesChannels,
    optionsDescriptions,
    optionsSalesChanelSyncTags,
    optionsSalesChanelSyncCategory,
    optionsDataProduct,
    paginationDataScProduct,
    salesChannelProductSyncTasks,
    loadingScProduct,
    salesChannelId,
    refetchCategory,
    refetchTag,
    refetchScProduct,
    setParamsCategory,
    setParamsDescription,
    setParamsTag,
    handleSelectProduct,
    handleSearchDescription,
    handleSearchProduct,
    handleSearchCategory,
    handleSearchTag,
    handleChangeTypeTimePublish,
    handleChangeDescription,
    handleUpdateSyncTask,
    handleDeleteScProduct,
    handleUpdateStatus,
    handleChangePerPageScProduct,
    handleChangePageScProduct,
    handleFilterStatusScProduct,
    setSelectedCategory,
    setSelectedTag,
  } = useDetail();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Sync Task Detail"
        isMoreColumn
        actionButton={
          <div className="flex gap-2">
            <Link to={"/sync-task"}>
              <CustomButton title="Back" />
            </Link>

            {syncTaskDetail?.status === SYNC_TASK_STATUS.PROCESSING && (
              <CustomButton
                title="Pause Sync"
                type="stop"
                onClick={() => handleUpdateStatus(SYNC_TASK_STATUS.PAUSED)}
              />
            )}
            {syncTaskDetail?.status === SYNC_TASK_STATUS.PAUSED && (
              <CustomButton
                title="Cancel Sync"
                type="cancel"
                onClick={() => handleUpdateStatus(SYNC_TASK_STATUS.CANCELED)}
              />
            )}
            {syncTaskDetail?.status === SYNC_TASK_STATUS.PAUSED && (
              <CustomButton
                title="Processing Sync"
                type="primary"
                onClick={() => handleUpdateStatus(SYNC_TASK_STATUS.PROCESSING)}
              />
            )}
            {syncTaskDetail?.status === SYNC_TASK_STATUS.DRAFT && (
              <CustomButton
                title="Publish"
                type="primary"
                icon={<SvgIcon.IconImportFile />}
                onClick={() => handleUpdateSyncTask()}
              />
            )}
            {syncTaskDetail?.status === SYNC_TASK_STATUS.PROCESSING && (
              <CustomButton
                title="Publish"
                type="primary"
                icon={<SvgIcon.IconImportFile />}
                onClick={() => handleUpdateSyncTask()}
              />
            )}
          </div>
        }
        contentLeft={
          <div className="flex-column">
            <BlockCard
              content={
                <>
                  <Row>
                    <CustomNameFormItem title="Task title" required />
                    <Form.Item name="title" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomInput placeholder="Enter Title" />
                    </Form.Item>
                  </Row>
                  <Row style={{ display: "flex", gap: "2%" }}>
                    <div style={{ width: "49%" }}>
                      <CustomNameFormItem title="Sales channel" required />
                      <Form.Item name="sales_channel_id" className="w-full" rules={[checkRulesNotNull()]}>
                        <CustomSelect
                          placeholder="Enter Sales Channel"
                          width="100%"
                          options={optionsSalesChannels}
                          disabled
                        />
                      </Form.Item>
                    </div>
                    <div style={{ width: "49%" }}>
                      <CustomNameFormItem title="Description template" />
                      <Form.Item name="description_id" className="w-full">
                        <CustomSelect
                          placeholder="Enter description template"
                          width="100%"
                          options={optionsDescriptions}
                          onChange={(value, option) => handleChangeDescription(value, option)}
                          showSearch
                          loading={loadingDescription}
                          onSearch={(value) => {
                            handleSearchDescription(value);
                          }}
                          onDropdownVisibleChange={(open) => {
                            if (!open) {
                              setParamsDescription({ ...PARAMS_DEFAULT, sales_channel_ids: salesChannelId });
                            }
                          }}
                          filterOption={false}
                        />
                      </Form.Item>
                    </div>
                  </Row>
                  <Row>
                    <CustomNameFormItem title="Content" required />
                    <Form.Item name="content" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomEditor />
                    </Form.Item>
                  </Row>
                  <Row>
                    <CustomNameFormItem title="SKU format" required />
                    <Form.Item name="sku_format" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomInput placeholder="Enter sku format" />
                    </Form.Item>
                  </Row>
                  <Row style={{ display: "flex", gap: "2%" }}>
                    <div style={{ width: "49%" }}>
                      <CustomNameFormItem title="Price" required />
                      <Form.Item name="price" className="w-full" rules={[checkRulesNotNull()]}>
                        <CustomInput placeholder="Enter price" isCurrency />
                      </Form.Item>
                    </div>
                    <div style={{ width: "49%" }}>
                      <CustomNameFormItem title="Sales price" />
                      <Form.Item name="sales_price" className="w-full">
                        <CustomInput placeholder="Enter sales price" isCurrency />
                      </Form.Item>
                    </div>
                  </Row>
                </>
              }
            />
            <SyncOptions syncTaskDetail={syncTaskDetail} id={id} />
            <SyncProduct
              id={id}
              refetchScProduct={refetchScProduct}
              loadingScProduct={loadingScProduct}
              loadingProduct={loadingProduct}
              optionsDataProduct={optionsDataProduct}
              handleDeleteScProduct={handleDeleteScProduct}
              handleSelectProduct={handleSelectProduct}
              handleSearchProduct={handleSearchProduct}
              paginationDataScProduct={paginationDataScProduct}
              salesChannelProductSyncTasks={salesChannelProductSyncTasks}
              handleChangePerPageScProduct={handleChangePerPageScProduct}
              handleChangePageScProduct={handleChangePageScProduct}
              handleFilterStatusScProduct={handleFilterStatusScProduct}
            />
          </div>
        }
        contentRight={
          <div className="flex-column">
            <BlockCard
              content={
                <Row>
                  <CustomNameFormItem title="Status" required />
                  <Form.Item name="status" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomSelect placeholder="Select status" width="100%" options={optionsSyncTaskStatus} disabled />
                  </Form.Item>
                </Row>
              }
            />
            <BlockCard
              content={
                <>
                  <Row>
                    <Row className="flex-center-between mb-2">
                      <CustomNameFormItem title="Sales channel categories" />
                      <CreateCategory refetchCategory={refetchCategory} salesChannelId={salesChannelId} />
                    </Row>
                    <Form.Item name="sales_channel_category" className="w-full">
                      <CustomSelectTag
                        showSearch
                        placeholder="Enter sales channel categories"
                        width="100%"
                        options={optionsSalesChanelSyncCategory}
                        loading={loadingSyncCate}
                        onSearch={(value) => {
                          handleSearchCategory(value);
                        }}
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            setParamsCategory({ id: salesChannelId, ...PARAMS_LIMIT });
                          }
                        }}
                        onChange={(value, option) => {
                          setSelectedCategory(option?.map((item) => item?.categories));
                        }}
                        filterOption={false}
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Row className="flex-center-between mb-2">
                      <CustomNameFormItem title="Sales channel tags" />
                      <CreateTag refetchTag={refetchTag} salesChannelId={salesChannelId} />
                    </Row>
                    <Form.Item name="sales_channel_tag" className="w-full">
                      <CustomSelectTag
                        showSearch
                        placeholder="Enter sales channel tags"
                        width="100%"
                        options={optionsSalesChanelSyncTags}
                        loading={loadingSyncTags}
                        onSearch={(value) => {
                          handleSearchTag(value);
                        }}
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            setParamsTag({ id: salesChannelId, ...PARAMS_LIMIT });
                          }
                        }}
                        onChange={(value, option) => {
                          setSelectedTag(option?.map((item) => item?.tags));
                        }}
                        filterOption={false}
                      />
                    </Form.Item>
                  </Row>
                </>
              }
            />
            <BlockCard
              content={
                <>
                  <Row>
                    <CustomNameFormItem title="Time publish" required />
                    {!syncTaskDetail?.time_publish && (
                      <Form.Item name="type_time_publish" className="w-full" rules={[checkRulesNotNull()]}>
                        <CustomRadio
                          options={optionsTimePublish}
                          onChange={(value) => {
                            handleChangeTypeTimePublish(value);
                          }}
                          defaultValue={TIME_PUBLISH.NOW}
                        />
                      </Form.Item>
                    )}
                    {typeTimePublish === TIME_PUBLISH.SELECT_TIME && (
                      <Form.Item name="time_publish" className="w-full" rules={[checkRulesNotNull()]}>
                        <CustomDateTimePicker showTime={{ format: "HH:mm" }} className="mt-3" />
                      </Form.Item>
                    )}
                  </Row>
                </>
              }
            />
            <BlockCard
              content={
                <>
                  <div>
                    <CustomNameFormItem title="Delay per post" />
                    <Row className="flex gap-[2%]">
                      <div style={{ width: "49%" }}>
                        <Form.Item name="delay_per_cost" className="w-full">
                          <CustomInput placeholder="Enter Sales Channel" />
                        </Form.Item>
                      </div>
                      <div style={{ width: "49%" }}>
                        <Form.Item name="unit_time" className="w-full">
                          <CustomSelect placeholder="Enter unit time" width="100%" options={optionsUnitTime} />
                        </Form.Item>
                      </div>
                    </Row>
                  </div>
                </>
              }
            />
          </div>
        }
      />
    </Form>
  );
};
