// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-card {
  height: 100%;
}
.ant-card .ant-card-head {
  padding: 0px 16px !important;
  min-height: 40px !important;
}
.ant-card .ant-card-head .ant-card-head-title {
  font-size: 13px;
  font-weight: 500;
}
.ant-card .ant-card-body {
  padding: 12px 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-card/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,4BAAA;EACA,2BAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;AAGN;AAAE;EACE,6BAAA;AAEJ","sourcesContent":[".ant-card {\n  height: 100%;\n  .ant-card-head {\n    padding: 0px 16px !important;\n    min-height: 40px !important;\n    .ant-card-head-title {\n      font-size: 13px;\n      font-weight: 500;\n    }\n  }\n  .ant-card-body {\n    padding: 12px 16px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
