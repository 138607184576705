// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
  width: 100%;
  background: rgba(67, 97, 238, 0.0823529412);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.login-page .card-login {
  height: 70vh;
  width: 800px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.login-page .card-login .left-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(67, 97, 238, 0.6);
  padding: 20px;
  width: 50%;
}
.login-page .card-login .left-section img {
  max-width: 100%;
  object-fit: cover;
}
.login-page .card-login .right-section {
  padding: 50px 24px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}
.login-page .card-login .right-section .title {
  font-size: 28px;
  font-weight: 700;
  color: #333;
}
.login-page .card-login .right-section .sub-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.login-page .card-login .right-section .form-item {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/sections/login/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,2CAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,uFAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kCAAA;EACA,aAAA;EACA,UAAA;AACN;AAAM;EACE,eAAA;EACA,iBAAA;AAER;AAEI;EACE,kBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,kBAAA;AAAN;AAEM;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AAAR;AAEM;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AAAR;AAEM;EACE,WAAA;AAAR","sourcesContent":[".login-page {\n  width: 100%;\n  background: #4361ee15;\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n\n  .card-login {\n    height: 70vh;\n    width: 800px;\n    background-color: #fff;\n    border-radius: 20px;\n    display: flex;\n    flex-direction: row;\n    overflow: hidden;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;\n\n    .left-section {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      background: #4361ee99;\n      padding: 20px;\n      width: 50%;\n      img {\n        max-width: 100%;\n        object-fit: cover;\n      }\n    }\n\n    .right-section {\n      padding: 50px 24px;\n      width: 50%;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n      align-items: start;\n\n      .title {\n        font-size: 28px;\n        font-weight: 700;\n        color: #333;\n      }\n      .sub-title {\n        font-size: 18px;\n        font-weight: 500;\n        color: #333;\n      }\n      .form-item {\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
