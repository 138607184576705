/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSalesChannels } from "../../../services/salesChannelService";
import { getUsers } from "../../../services/usersService";
import { PARAMS_DEFAULT, PARAMS_GET_MAX } from "../../../utils/constant";
import { getHostName, getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useFilter = ({ paramsSyncTasks, setParamsSyncTasks }) => {
  const [form] = Form.useForm();
  const GET_QUERY = getQuery();

  const [visiblePopover, setVisiblePopover] = useState(false);
  const [valueSearchUser, setValueSearchUser] = useState("");
  const [paramsUser, setParamsUser] = useState(PARAMS_GET_MAX);
  const [paramsSalesChannel, setParamsSalesChannel] = useState(PARAMS_GET_MAX);
  const [valueSearchSalesChannel, setValueSearchSalesChannel] = useState("");

  const initialValues = {
    created_by_ids: paramsSyncTasks?.created_by_ids,
    sales_channel_sync_task_statuses: paramsSyncTasks?.sales_channel_sync_task_statuses,
  };

  const debouncedQueryUsers = useDebounce(valueSearchUser);
  const debouncedQuerySalesChannel = useDebounce(valueSearchSalesChannel);

  const { data: dataUsers, isFetching: loadingUsers } = useQuery(
    ["getUsers", paramsUser],
    ({ queryKey }) => getUsers(queryKey[1]),
    optionUseQuery
  );

  const { data: dataSalesChannel, isFetching: loadingSalesChannel } = useQuery(
    ["getSalesChannels", paramsSalesChannel],
    ({ queryKey }) => getSalesChannels(queryKey[1]),
    optionUseQuery
  );

  const optionUsers = dataUsers?.users?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const optionSalesChannel = dataSalesChannel?.salesChannels?.map((item) => ({
    value: item?.id,
    label: getHostName(item?.domain),
  }));

  const handleSearchUsers = (valueSearch) => {
    setValueSearchUser(valueSearch);
  };

  const handleSearchSalesChannel = (valueSearch) => {
    setValueSearchSalesChannel(valueSearch);
  };

  const handleClearFilter = () => {
    form.resetFields();
    setVisiblePopover(false);
    setParamsSyncTasks({
      ...PARAMS_DEFAULT,
      query: paramsSyncTasks?.query,
      sort_order: paramsSyncTasks?.sort_order,
      sort_by: paramsSyncTasks?.sort_by,
    });

    form.setFieldsValue({
      created_by_ids: undefined,
      sales_channel_sync_task_statuses: undefined,
      sales_channel_ids: undefined,
    });
  };

  const handleFilter = () => {
    const formValue = form.getFieldsValue();
    setParamsSyncTasks({
      ...paramsSyncTasks,
      created_by_ids: formValue?.created_by_ids ?? GET_QUERY.created_by_ids,
      sales_channel_sync_task_statuses:
        formValue?.sales_channel_sync_task_statuses ?? GET_QUERY.sales_channel_sync_task_statuses,
      page: PARAMS_DEFAULT.page,
      sales_channel_ids: formValue.sales_channel_ids,
    });
    setVisiblePopover(false);
  };

  useEffect(() => {
    setParamsUser({ ...paramsUser, query: valueSearchUser });
  }, [debouncedQueryUsers]);

  useEffect(() => {
    setParamsSalesChannel({ ...paramsSalesChannel, query: valueSearchSalesChannel });
  }, [debouncedQuerySalesChannel]);

  return {
    form,
    visiblePopover,
    optionUsers,
    loadingUsers,
    initialValues,
    optionSalesChannel,
    loadingSalesChannel,
    setParamsUser,
    handleClearFilter,
    handleSearchSalesChannel,
    setParamsSalesChannel,
    handleSearchUsers,
    handleFilter,
    setVisiblePopover,
  };
};
