import { Col, Row } from "antd";
import { CustomTitle } from "../../components/custom-title/CustomTitle";
import { useIsWidthMobile } from "../../hooks/apps/useIsWidthMobile";
import "./index.scss";

export const CustomDetailPage = ({
  title,
  actionButton,
  contentCard,
  isMoreColumn = false,
  contentLeft,
  contentRight,
}) => {
  const isWidthMobile = useIsWidthMobile();

  return (
    <Col span={24} className={`custom-list-page ${isWidthMobile ? "mb-[70px]" : "mb-[0px]"}`}>
      <Row>
        <div className="flex-center-between">
          <CustomTitle title={title}></CustomTitle>
          <div className="flex gap-2">{actionButton}</div>
        </div>
      </Row>
      {isMoreColumn ? (
        <div className="content-more-page mt-3">
          <div
            className={`content-left-page ${
              isWidthMobile ? "w-[100%] flex-grow-[7] flex-shrink flex-basis-0" : "w-[70%]"
            }`}
          >
            {contentLeft}
          </div>
          <div className={`content-right-page ${isWidthMobile ? "w-full" : "w-[30%]"} `} style={{ flex: "3 1 0%" }}>
            {contentRight}
          </div>
        </div>
      ) : (
        <div className="content-single-page mt-3">
          <div className="content-card">{contentCard}</div>
        </div>
      )}
    </Col>
  );
};
