// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-select/index.scss"],"names":[],"mappings":"AAAA;;;;EAIE,gCAAA;EACA,2BAAA;AACF","sourcesContent":[".ant-select-focused .ant-select-selector,\n.ant-select-selector:focus,\n.ant-select-selector:active,\n.ant-select-open .ant-select-selector {\n  border-color: #d9d9d9 !important;\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
