import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const CustomEditor = ({ ...rest }) => {
  const [value, setValue] = useState("");

  return (
    <div className="w-full">
      <ReactQuill theme="snow" value={value} onChange={setValue} className="w-full" span={4} {...rest} />
    </div>
  );
};
