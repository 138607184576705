import { Form } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../components/custom-notify/CustomNotify";
import { createDescription } from "../../../services/descriptionService";
import { getSalesChannels } from "../../../services/salesChannelService";
import { PARAMS_GET_MAX } from "../../../utils/constant";
import { getHostName } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [paramsSalesChannel, setParamsSalesChannel] = useState(PARAMS_GET_MAX);

  const { data: dataSalesChannels, isFetching: loadingSalesChannel } = useQuery(
    ["getSalesChannels", paramsSalesChannel],
    ({ queryKey }) => getSalesChannels(queryKey[1]),
    optionUseQuery
  );

  const salesChannelOptions = dataSalesChannels?.salesChannels?.map((item) => ({
    value: item?.id,
    label: getHostName(item?.domain),
  }));

  const handleSearchSalesChannel = (value) => {
    setParamsSalesChannel({ ...paramsSalesChannel, query: value });
  };

  const handleCreateDescription = async () => {
    await form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue();
        const createData = {
          salesChannel_id: values?.salesChannel_id,
          title: values?.title,
          content: values?.content,
          price: Number(values?.price),
          sales_price: Number(values?.sales_price),
          sku_format: values?.sku_format,
        };
        await createDescription(createData)
          .then((res) => {
            navigate("/descriptions");
            notifySuccess(res?.message);
          })
          .catch((error) => {
            notifyError(error.toString());
          });
      })
      .catch(() => {});
  };

  return {
    form,
    loadingSalesChannel,
    salesChannelOptions,
    setParamsSalesChannel,
    handleSearchSalesChannel,
    handleCreateDescription,
  };
};
