import { UploadOutlined } from "@ant-design/icons";
import { Form, Row, Spin, Upload } from "antd";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomSelectTag } from "../../../components/custom-select-tag/CustomSelectTag";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { useUpload } from "../../../hooks/products/upload-product/useUpload";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { ACCEPT_FILE_TYPES_ZIP, PARAMS_GET_MAX } from "../../../utils/constant";
import { checkRulesNotNull } from "../../../utils/validator";
import { CreateCategory } from "./widget/CreateCategory";

export const UploadProductSection = () => {
  const {
    form,
    fileList,
    loading,
    isFetching,
    optionsCategory,
    refetch,
    handleUploadFile,
    handleSearchCategory,
    setParamsCategory,
    handleUploadProduct,
  } = useUpload();

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <CustomDetailPage
          title="Upload Product"
          actionButton={
            <>
              <Link to={"/products"}>
                <CustomButton title="Back" type="default" />
              </Link>
              <CustomButton
                title="Save"
                type="primary"
                icon={<SvgIcon.IconImportFile />}
                onClick={() => handleUploadProduct()}
              />
            </>
          }
          contentCard={
            <BlockCard
              content={
                <>
                  <Row>
                    <CustomNameFormItem title="Images" required />
                    <div className="w-full">
                      <Form.Item name="upload" className="w-full" rules={[checkRulesNotNull()]}>
                        <Upload
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          accept={ACCEPT_FILE_TYPES_ZIP.join(",")}
                          fileList={fileList}
                          onChange={(value) => handleUploadFile(value)}
                          maxCount={1}
                        >
                          <CustomButton icon={<UploadOutlined />} title="Choose file..." />
                        </Upload>
                      </Form.Item>
                    </div>
                  </Row>
                  <div>
                    <CustomNameFormItem title="Select Category" required />
                    <div className="flex-center-between w-full gap-2">
                      <Form.Item name="category_ids" className="w-full" rules={[checkRulesNotNull()]}>
                        <CustomSelectTag
                          showSearch
                          placeholder="Enter sales channel tags"
                          width="100%"
                          options={optionsCategory}
                          loading={isFetching}
                          onSearch={(value) => {
                            handleSearchCategory(value);
                          }}
                          onDropdownVisibleChange={(open) => {
                            if (!open) {
                              setParamsCategory(PARAMS_GET_MAX);
                            }
                          }}
                          filterOption={false}
                        />
                      </Form.Item>
                      <CreateCategory refetch={refetch} />
                    </div>
                  </div>
                </>
              }
            />
          }
        />
      </Form>
    </Spin>
  );
};
