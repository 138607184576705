/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { notifyError, notifySuccess, notifyWarning } from "../../../components/custom-notify/CustomNotify";
import { deleteBulkSyncTask, deleteSyncTask, getSyncTasks } from "../../../services/syncTaskService";
import { PARAMS_DEFAULT } from "../../../utils/constant";
import { buildQueryString, getQuery, useDebounce } from "../../../utils/helperFuncs";
import { optionUseQuery } from "../../../utils/options";

export const useList = () => {
  const GET_QUERY = getQuery();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [valuesSearch, setValueSearch] = useState(GET_QUERY?.query ?? "");
  const [paramsSyncTasks, setParamsSyncTasks] = useState({
    ...PARAMS_DEFAULT,
    page: GET_QUERY.page ?? PARAMS_DEFAULT.page,
    per_page: GET_QUERY.per_page ?? PARAMS_DEFAULT.per_page,
    sort_order: GET_QUERY.sort_order ?? PARAMS_DEFAULT.sort_order,
    sort_by: GET_QUERY.sort_by ?? PARAMS_DEFAULT.sort_by,
    query: GET_QUERY?.query,
    created_by_ids: GET_QUERY?.created_by_ids,
    sales_channel_sync_task_statuses: GET_QUERY?.sales_channel_sync_task_statuses,
    sales_channel_ids: GET_QUERY?.sales_channel_ids,
  });

  const debouncedQuery = useDebounce(valuesSearch);

  const {
    data: dataSyncTasks,
    isFetching,
    refetch,
  } = useQuery(["getSyncTask", paramsSyncTasks], ({ queryKey }) => getSyncTasks(queryKey[1]), optionUseQuery);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleDeleteSyncTasks = async (id) => {
    setLoadingDelete(true);
    try {
      await deleteSyncTask(id).then((res) => {
        refetch();
        setSelectedRowKeys();
        notifySuccess(res?.message);
      });
    } catch (error) {
      notifyError(error?.toString());
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleDeleteSelectedSyncTask = async () => {
    if (selectedRowKeys?.length > 0) {
      setLoadingDelete(true);
      await deleteBulkSyncTask({ sales_channel_sync_task_ids: selectedRowKeys })
        .then((res) => {
          refetch();
          setSelectedRowKeys();
          notifySuccess(res?.message);
        })
        .catch((error) => {
          notifyError(error?.toString());
        })
        .finally(() => {
          setLoadingDelete(false);
        });
    } else {
      notifyWarning("Please select at least one sync task.");
    }
  };

  const handleDeleteSyncTask = async (id) => {
    setLoadingDelete(true);
    try {
      await deleteSyncTask(id).then((res) => {
        refetch();
        notifySuccess(res?.message);
      });
    } catch (error) {
      notifyError(error?.toString());
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleSearchSyncTask = (event) => {
    setValueSearch(event?.target?.value.trim());
  };

  const handleSortOrder = (value) => {
    setParamsSyncTasks({ ...paramsSyncTasks, sort_order: value, page: PARAMS_DEFAULT.page });
  };

  const handleChangePerPage = (pageSize) => {
    setParamsSyncTasks({ ...paramsSyncTasks, per_page: pageSize, page: PARAMS_DEFAULT.page });
  };

  const handleChangePage = (pageNumber) => {
    setParamsSyncTasks({ ...paramsSyncTasks, page: pageNumber });
  };

  useEffect(() => {
    setParamsSyncTasks({ ...paramsSyncTasks, query: valuesSearch, page: PARAMS_DEFAULT.page });
  }, [debouncedQuery]);

  useEffect(() => {
    buildQueryString({
      params: paramsSyncTasks,
    });
  }, [paramsSyncTasks]);

  return {
    syncTasks: dataSyncTasks?.syncTasks,
    paginationData: dataSyncTasks?.paginationData,
    loading: loadingDelete || isFetching,
    paramsSyncTasks,
    selectedRowKeys,
    valuesSearch,
    setParamsSyncTasks,
    handleDeleteSyncTasks,
    handleDeleteSelectedSyncTask,
    onSelectChange,
    handleDeleteSyncTask,
    handleSortOrder,
    handleChangePerPage,
    handleChangePage,
    handleSearchSyncTask,
  };
};
