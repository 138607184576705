import { Row } from "antd";
import { CustomPagination } from "../../../../components/custom-pagination/CustomPagination";
import { CustomSelectTag } from "../../../../components/custom-select-tag/CustomSelectTag";
import { CustomSelect } from "../../../../components/custom-select/CustomSelect";
import { CustomTable } from "../../../../components/custom-table/CustomTable";
import { useTableScProduct } from "../../../../hooks/sync-task/list-sync-task/useTableScProduct";
import { BlockCard } from "../../../../themes/block-card/BlockCard";
import { optionsStatusScProduct } from "../../../../utils/options";

export const SyncProduct = ({
  id,
  loadingProduct,
  refetchScProduct,
  loadingScProduct,
  handleSelectProduct,
  optionsDataProduct,
  handleDeleteScProduct,
  handleSearchProduct,
  paginationDataScProduct,
  salesChannelProductSyncTasks,
  handleChangePerPageScProduct,
  handleChangePageScProduct,
  handleFilterStatusScProduct,
}) => {
  const { countScProductFailed, columns, handleRetryScProductsFailed } = useTableScProduct({
    id,
    handleDeleteScProduct,
    refetchScProduct,
  });

  return (
    <BlockCard
      title="Product selected list"
      content={
        <>
          <Row style={{ display: "flex", gap: "2%" }}>
            <CustomSelect
              placeholder="Select sc product"
              options={optionsDataProduct}
              onChange={(value, option) => handleSelectProduct(value, option)}
              showSearch
              width="79%"
              loading={loadingProduct}
              onSearch={(value) => {
                handleSearchProduct(value);
              }}
              filterOption={false}
            />
            <CustomSelectTag
              placeholder="Filter status sync products"
              options={optionsStatusScProduct}
              onChange={(value, option) => handleFilterStatusScProduct(value, option)}
              showSearch
              width="19%"
            />
          </Row>
          {countScProductFailed > 0 && (
            <Row className="flex justify-end">
              <span className="text-[#e7515a] text-right">
                {countScProductFailed} products failed!{" "}
                <u className="cursor-pointer" onClick={handleRetryScProductsFailed}>
                  You can Retry?
                </u>
              </span>
            </Row>
          )}

          <Row>
            {salesChannelProductSyncTasks?.length > 0 && (
              <div className="w-full">
                <CustomTable columns={columns} data={salesChannelProductSyncTasks} loading={loadingScProduct} />
                {salesChannelProductSyncTasks?.length > 0 && (
                  <CustomPagination
                    total={paginationDataScProduct?.total ?? 0}
                    limit={paginationDataScProduct?.limit ?? 0}
                    page={paginationDataScProduct?.page ?? 0}
                    handleChangePage={(pageNumber) => handleChangePageScProduct(pageNumber)}
                    onChangePerPage={(pageSize) => handleChangePerPageScProduct(pageSize)}
                  />
                )}
              </div>
            )}
          </Row>
        </>
      }
    />
  );
};
