import { Select, Spin } from "antd";
import "./index.scss";

export const CustomSelect = ({ placeholder, options, height = 40, width, loading = false, ...rest }) => {
  const styleSelect = {
    height: `${height}px`,
    width: width,
  };

  return (
    <Select
      placeholder={placeholder}
      style={styleSelect}
      options={options}
      notFoundContent={
        loading ? (
          <Spin>
            <div className="h-20"></div>
          </Spin>
        ) : null
      }
      {...rest}
    />
  );
};
