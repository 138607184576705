import { Select, Spin } from "antd";
import classNames from "classnames";

export const CustomSelectTag = ({
  placeholder,
  options,
  height = 40,
  width,
  type = "default",
  loading = false,
  ...rest
}) => {
  const styleSelect = {
    width: width,
  };

  const selectClass = classNames("custom-select-tag", {
    [`custom-select-tag-${type}`]: type,
  });

  return (
    <Select
      className={selectClass}
      placeholder={placeholder}
      style={styleSelect}
      options={options}
      mode="multiple"
      optionFilterProp="label"
      notFoundContent={
        loading ? (
          <Spin>
            <div className="h-10"></div>
          </Spin>
        ) : null
      }
      {...rest}
    />
  );
};
