import { Form, Row } from "antd";
import { SvgIcon } from "../../../assets/icons";
import { CustomButton } from "../../../components/custom-button/CustomButton";
import { CustomInput } from "../../../components/custom-input/CustomInput";
import { CustomSelect } from "../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../components/custom-title/CustomNameFormItem";
import { usePersonal } from "../../../hooks/users/personal-info/usePersonal";
import { CustomDetailPage } from "../../../layouts/custom-detail-page/CustomDetailPage";
import { BlockCard } from "../../../themes/block-card/BlockCard";
import { optionsActiveUsers, optionsRolesUsers } from "../../../utils/options";
import { checkRulesNotNull, validateEmailFormat } from "../../../utils/validator";

export const PersonalInfoSection = () => {
  const { form, handleUpdateUsers } = usePersonal();

  return (
    <Form form={form}>
      <CustomDetailPage
        title="Personal Information"
        actionButton={
          <>
            <CustomButton
              title="Save"
              type="primary"
              icon={<SvgIcon.IconImportFile />}
              onClick={() => handleUpdateUsers()}
            />
          </>
        }
        contentCard={
          <BlockCard
            content={
              <>
                <Row>
                  <CustomNameFormItem title="Name" required />
                  <Form.Item name="name" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter name" />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Username" />
                  <Form.Item name="username" className="w-full" rules={[checkRulesNotNull()]}>
                    <CustomInput placeholder="Enter username" disabled />
                  </Form.Item>
                </Row>
                <Row>
                  <CustomNameFormItem title="Email" required />
                  <Form.Item name="email" className="w-full" rules={[checkRulesNotNull(), validateEmailFormat()]}>
                    <CustomInput placeholder="Enter email" />
                  </Form.Item>
                </Row>
                <Row style={{ display: "flex", gap: "2%" }}>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Roles" />
                    <Form.Item name="role" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomSelect placeholder="Select roles" width="100%" options={optionsRolesUsers} disabled />
                    </Form.Item>
                  </div>
                  <div style={{ width: "49%" }}>
                    <CustomNameFormItem title="Active" />
                    <Form.Item name="is_active" className="w-full" rules={[checkRulesNotNull()]}>
                      <CustomSelect placeholder="Select active" width="100%" options={optionsActiveUsers} disabled />
                    </Form.Item>
                  </div>
                </Row>
              </>
            }
          />
        }
      />
    </Form>
  );
};
