import { Avatar, Space, Typography } from "antd";
import { SvgIcon } from "../../../assets/icons";
import { CustomPopconfirm } from "../../../components/custom-popconfirm/CustomPopconfirm";

export const useTable = ({ handleDeleteProduct, handleOpenPopupGetByID }) => {
  const columns = [
    {
      title: <Typography.Text>ID</Typography.Text>,
      key: "id",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: <Typography.Text>Thumb</Typography.Text>,
      key: "thumb",
      align: "center",
      width: 175,
      render: (_, record) => {
        return <Avatar src={record?.thumb?.url} size={40} />;
      },
    },
    {
      title: <Typography.Text>Title</Typography.Text>,
      key: "name",
      render: (_, record) => {
        return <Typography.Text>{record?.name || "- -"}</Typography.Text>;
      },
    },
    {
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <SvgIcon.IconEdit onClick={() => handleOpenPopupGetByID(record)} className="cursor-pointer" />
          <CustomPopconfirm
            title="Delete"
            component={<SvgIcon.IconDelete />}
            description="Are you sure to delete this?"
            onConfirm={() => handleDeleteProduct(record?.id)}
          />
        </Space>
      ),
    },
  ];
  return { columns };
};
