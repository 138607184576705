import { Col, Form, Row } from "antd";
import { CustomCheckbox } from "../../../../../components/custom-checkbox/CustomCheckbox";
import { CustomInput } from "../../../../../components/custom-input/CustomInput";
import { CustomRadio } from "../../../../../components/custom-radio/CustomRadio";
import { CustomNameFormItem } from "../../../../../components/custom-title/CustomNameFormItem";
import { optionsCheckboxReplaceRenameRule, optionsRadioReplaceRenameRule } from "../../../../../utils/options";

export const RemoveRuleWidget = () => {
  return (
    <div className="flex flex-col gap-4">
      <Row>
        <CustomNameFormItem title="Remove" />
        <Form.Item name="remove" className="w-full">
          <CustomInput placeholder="Enter Title" />
        </Form.Item>
      </Row>
      <Row>
        <div className="w-full">
          <CustomNameFormItem title="Occurrences" />
        </div>
        <div className="w-full flex">
          <Col span={12}>
            <Form.Item name="occurrences_left" className="w-full">
              <CustomRadio options={optionsRadioReplaceRenameRule} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="occurrences_right" className="w-full">
              <CustomCheckbox options={optionsCheckboxReplaceRenameRule} />
            </Form.Item>
          </Col>
        </div>
      </Row>
    </div>
  );
};
