import { Form, Row } from "antd";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomPopover } from "../../../../components/custom-popover/CustomPopover";
import { CustomSelectTag } from "../../../../components/custom-select-tag/CustomSelectTag";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useIsWidthMobile } from "../../../../hooks/apps/useIsWidthMobile";
import { useFilter } from "../../../../hooks/sync-task/list-sync-task/useFilter";
import { PARAMS_GET_MAX } from "../../../../utils/constant";
import { optionsSyncTaskStatus } from "../../../../utils/options";

export const FilterWidget = ({ paramsSyncTasks, setParamsSyncTasks }) => {
  const {
    form,
    visiblePopover,
    optionUsers,
    loadingUsers,
    initialValues,
    optionSalesChannel,
    loadingSalesChannel,
    setParamsUser,
    setVisiblePopover,
    handleSearchSalesChannel,
    handleClearFilter,
    setParamsSalesChannel,
    handleSearchUsers,
    handleFilter,
  } = useFilter({
    paramsSyncTasks,
    setParamsSyncTasks,
  });
  const isWidthMobile = useIsWidthMobile();

  return (
    <Form form={form} initialValues={initialValues}>
      <CustomPopover
        width={isWidthMobile ? 300 : 400}
        title={
          <>
            <div>Filter</div>
            <div className="flex gap-2">
              <CustomButton title="Clear" height={32} onClick={() => handleClearFilter()} />
              <CustomButton title="Save" type="primary" height={32} onClick={() => handleFilter()} />
            </div>
          </>
        }
        open={visiblePopover}
        onClose={() => setVisiblePopover(false)}
        component={
          <CustomButton
            title="Filter"
            type="default"
            onClick={() => {
              setVisiblePopover(true);
            }}
            icon={<SvgIcon.IconFilter className="w-5 text-[#4361ee]"></SvgIcon.IconFilter>}
          />
        }
        content={
          <>
            <Row>
              <CustomNameFormItem title="Created by" />
              <Form.Item name="created_by_ids" className="w-full">
                <CustomSelectTag
                  placeholder="Select create by"
                  options={optionUsers}
                  showSearch
                  loading={loadingUsers}
                  onSearch={(value) => {
                    handleSearchUsers(value);
                  }}
                  onDropdownVisibleChange={(open) => {
                    if (!open) {
                      setParamsUser(PARAMS_GET_MAX);
                    }
                  }}
                  filterOption={false}
                />
              </Form.Item>
            </Row>
            <Row>
              <CustomNameFormItem title="Status" />
              <Form.Item name="sales_channel_sync_task_statuses" className="w-full">
                <CustomSelectTag placeholder="Select status" options={optionsSyncTaskStatus} />
              </Form.Item>
            </Row>
            <Row>
              <CustomNameFormItem title="Sales Channel" />
              <Form.Item name="sales_channel_ids" className="w-full">
                <CustomSelectTag
                  placeholder="Select status"
                  options={optionSalesChannel}
                  showSearch
                  loading={loadingSalesChannel}
                  onSearch={(value) => {
                    handleSearchSalesChannel(value);
                  }}
                  onDropdownVisibleChange={(open) => {
                    if (!open) {
                      setParamsSalesChannel(PARAMS_GET_MAX);
                    }
                  }}
                  filterOption={false}
                />
              </Form.Item>
            </Row>
          </>
        }
      />
    </Form>
  );
};
