import api from "./api";
import { headersRequest } from "./authService";

// fetch list users
export const getUsers = async (paramsUsers) => {
  try {
    const response = await api.get(`/users`, {
      params: paramsUsers,
      headers: headersRequest(),
    });
    const data = response?.data?.data;
    return {
      users: data?.users,
      paginationData: { total: data?.total, limit: data?.limit, page: data?.page },
    };
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching users");
  }
};

// delete users
export const deleteUsers = async (usersId) => {
  try {
    const response = await api.delete(`/users/${usersId}`, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error fetching users");
  }
};

// detail users
export const getDetailUsers = async (id) => {
  try {
    const response = await api.get(`/users/${id}`, {
      headers: headersRequest(),
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error detail users");
  }
};

// update users
export const updateUsers = async (id, updateData) => {
  try {
    const response = await api.put(`/users/${id}`, updateData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating users");
  }
};

// create users
export const createUsers = async (createData) => {
  try {
    const response = await api.post(`/users`, createData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error create users");
  }
};

// update users
export const changePassword = async (id, changeData) => {
  try {
    const response = await api.put(`/users/${id}/password`, changeData, {
      headers: headersRequest(),
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error updating users");
  }
};

// delete bulk users
export const deleteBulkUsers = async (requestDelete) => {
  try {
    const response = await api.delete(`/users/bulk-delete`, {
      headers: headersRequest(),
      data: requestDelete,
    });
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || "Error delete bulk users");
  }
};
