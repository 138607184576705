import { Form, Row } from "antd";
import { SvgIcon } from "../../../../assets/icons";
import { CustomButton } from "../../../../components/custom-button/CustomButton";
import { CustomDateTimePicker } from "../../../../components/custom-date-time-picker/CustomDateTimePicker";
import { CustomPopover } from "../../../../components/custom-popover/CustomPopover";
import { CustomSelectTag } from "../../../../components/custom-select-tag/CustomSelectTag";
import { CustomSelect } from "../../../../components/custom-select/CustomSelect";
import { CustomNameFormItem } from "../../../../components/custom-title/CustomNameFormItem";
import { useIsWidthMobile } from "../../../../hooks/apps/useIsWidthMobile";
import { useFilter } from "../../../../hooks/products/list-product/useFilter";
import { isAllRoles } from "../../../../utils/boolean";
import { PARAMS_GET_MAX } from "../../../../utils/constant";

export const FilterWidget = ({ paramsProduct, setParamsProduct }) => {
  const {
    form,
    visiblePopover,
    optionsCategories,
    optionsUsers,
    loadingCategory,
    loadingUsers,
    initialValues,
    decodedToken,
    setParamsUser,
    setParamsCategory,
    setVisiblePopover,
    handleSearchCategory,
    handleSearchUsers,
    handleClearFilter,
    handleFilter,
  } = useFilter({
    paramsProduct,
    setParamsProduct,
  });
  const isWidthMobile = useIsWidthMobile();

  return (
    <Form form={form} initialValues={initialValues}>
      <CustomPopover
        width={isWidthMobile ? 300 : 400}
        open={visiblePopover}
        onClose={() => setVisiblePopover(false)}
        component={
          <CustomButton
            title="Filter"
            type="default"
            onClick={() => {
              setVisiblePopover(true);
            }}
            icon={<SvgIcon.IconFilter className="w-5 text-[#4361ee]"></SvgIcon.IconFilter>}
          />
        }
        title={
          <>
            <div>Filter</div>
            <div className="flex gap-2">
              <CustomButton title="Clear" height={32} onClick={() => handleClearFilter()} />
              <CustomButton title="Save" type="primary" height={32} onClick={() => handleFilter()} />
            </div>
          </>
        }
        content={
          <>
            <Row>
              <CustomNameFormItem title="Categories" />
              <Form.Item name="category_ids" className="w-full">
                <CustomSelectTag
                  width="100%"
                  placeholder="Select an option"
                  showSearch
                  options={optionsCategories}
                  loading={loadingCategory}
                  onSearch={(value) => {
                    handleSearchCategory(value);
                  }}
                  onDropdownVisibleChange={(open) => {
                    if (!open) {
                      setParamsCategory(PARAMS_GET_MAX);
                    }
                  }}
                  filterOption={false}
                />
              </Form.Item>
            </Row>
            <Row>
              <CustomNameFormItem title="Creation Date" />
              <div className="flex-center-between gap-2">
                <Form.Item name="created_at_from" className="w-full">
                  <CustomDateTimePicker placeholder="Select from date" />
                </Form.Item>
                <Form.Item name="created_at_to" className="w-full">
                  <CustomDateTimePicker placeholder="Select to date" />
                </Form.Item>
              </div>
            </Row>
            {isAllRoles(decodedToken.role) && (
              <Row>
                <CustomNameFormItem title="Owner" />
                <Form.Item name="user_ids" className="w-full">
                  <CustomSelect
                    placeholder="Enter Owner"
                    options={optionsUsers}
                    showSearch
                    loading={loadingUsers}
                    onSearch={(value) => {
                      handleSearchUsers(value);
                    }}
                    onDropdownVisibleChange={(open) => {
                      if (!open) {
                        setParamsUser(PARAMS_GET_MAX);
                      }
                    }}
                    filterOption={false}
                  />
                </Form.Item>
              </Row>
            )}
          </>
        }
      />
    </Form>
  );
};
